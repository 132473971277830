import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";
import { method } from "lodash";

/*
 * @private
 * @name getQrFrameData
 * @description API call to fetch QR frame data
 *
 * @param payload: object
 */
const getQrFrameData = (builder) => {
  return builder.query({
    query() {
      return {
        url: `/qr-frames`,
        method: "GET",
      };
    },
    providesTags: [STORE.TAGS.QR_TAG],
  });
};

/*
 * @private
 * @name getQrTemplatesData
 * @description API call to fetch QR templates data
 *
 * @param payload: object
 */
const getQrTemplatesData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-templates`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getOneQrTemplateData
 * @description API call to fetch details of one QR template
 *
 * @param payload: object
 */
const getOneQrTemplateData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-templates-get-by-id`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getQrLogoData
 * @description API call to fetch QR logo data
 *
 * @param payload: object
 */
const getQrLogoData = (builder) => {
  return builder.query({
    query() {
      return {
        url: `/qr-logos`,
        method: "GET",
      };
    },
    serializeQueryArgs: ({ endpointName }) => endpointName, // in progress <-
  });
};

/*
 * @private
 * @name getQrShapeData
 * @description API call to fetch QR shape data
 *
 * @param payload: object
 */
const getQrShapeData = (builder) => {
  return builder.query({
    query() {
      return {
        url: `/qr-styles`,
        method: "GET",
      };
    },
  });
};

/*
 * @private
 * @name getQrLevelData
 * @description API call to fetch QR level data
 *
 * @param payload: object
 */
const getQrLevelData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-levels`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name qrCodeStoreData
 * @description API call to store QR code data
 *
 * @param payload: object
 */
const qrCodeStoreData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-code/store`,
        method: "POST",
        data: payload,
      };
    },
  });
};
const qrCodeStoreDELETE = (builder) => {
  return builder.mutation({
    query({ id }) {
      return {
        url: `/qr-code/delete`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name templateStoreData
 * @description API call to store template data
 *
 * @param payload: object
 */
const templateStoreData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-template-create`,
        method: "POST",
        data: payload,
      };
    },
  });
};

const getTemplateListData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/list?id=${payload.id}`,
        method: "GET",
      };
    },
  });
};
const getSingleTemplateData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/get-by-id?id=${payload.id}`,
        method: "GET",
      };
    },
  });
};

/*
 * @private
 * @name templateEditData
 * @description API call to edit template data
 *
 * @param payload: object
 */
const templateEditData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/update?id=${payload.id}`,
        method: "Patch",
        data: payload.data,
      };
    },
  });
};

/*
 * @private
 * @name templateDeleteData
 * @description API call to delete template data
 *
 * @param payload: object
 */
const templateDeleteData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-template-delete`,
        method: "POST",
        data: payload,
      };
    },
  });
};
const templateUpdateData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/update?id=${payload.id}`,
        method: "Patch",
        data: payload.data,
      };
    },
  });
};

/*
 * @private
 * @name qrCodeUpdateData
 * @description API call to update QR code data
 *
 * @param payload: object
 */
const qrCodeUpdateData = (builder) => {
  return builder.mutation({
    query(payload) {
      console.log("update qr payload", payload);
      return {
        url: `/qr-code/update/${payload.id}`,
        method: "PUT",
        data: payload.data,
      };
    },
  });
};
const deleteQrCode = (builder) => {
  return builder.mutation({
    query({ id }) {
      return {
        url: `/qr-code/delete`,
        method: "DELETE",
        params: { id },
      };
    },
  });
};

/*
 * @private
 * @name getFolderData
 * @description API call to fetch folder data
 *
 * @param payload: object
 */
const getFolderData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-folders`,
        method: "POST",
        data: payload,
      };
    },
  });
};

const createQRCode = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-code/store`,
        method: "POST",
        data: payload,
      };
    },
  });
};

const getQRCodes = (builder) => {
  return builder.mutation({
    query: (params) => {
      console.log("Params in query ==>", params);
      return {
        url: `/qr-code/get-by-pagination`,
        method: "GET",
        params: {
          page: params.page,
          per_page: params.limit, 
          user_id: params.id,
        },
      };
    },
  });
};

// New endpoint added for fetching QR status data
// New endpoint added for fetching/updating QR profile data
const getQrProfileData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/users/get-by-id`,
        method: "GET",
        params: {
          id: payload.userId,
        },
      };
    },  
  });
};

const updateQrProfileData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/users/update?id=${payload.id}`,
        method: "PATCH",
        data: payload.data,
      };
    },
  });
};

const allusersData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/users/list`,
        method: "GET",
      };
    },
  });
};

const contactUs = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/contacts`,
        method: "POST",
        data: payload,
      };
    },
  });
};

const getAllQrAnaliytics = (builder) => {
  return builder.mutation({
    query(payload) {
      console.log("payload", payload);

      return {
        url: `/qr-scan-log/analytics`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// Create API slice
const qrApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.QR_API,
  tagTypes: [STORE.TAGS.QR_TAG],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getQrFrameData: getQrFrameData(builder),
    getQrTemplatesData: getQrTemplatesData(builder),
    getOneQrTemplateData: getOneQrTemplateData(builder),
    getQrLogoData: getQrLogoData(builder),
    getQrShapeData: getQrShapeData(builder),
    getQrLevelData: getQrLevelData(builder),
    qrCodeStoreData: qrCodeStoreData(builder),
    templateStoreData: templateStoreData(builder),
    templateEditData: templateEditData(builder),
    templateDeleteData: templateDeleteData(builder),
    qrCodeUpdateData: qrCodeUpdateData(builder),
    getFolderData: getFolderData(builder),
    createQRCode: createQRCode(builder),
    getQRCodes: getQRCodes(builder),
    getQrProfileData: getQrProfileData(builder), //
    getTemplateListData: getTemplateListData(builder),
    deleteQrCode: deleteQrCode(builder),
    getSingleTemplateData: getSingleTemplateData(builder),
    templateUpdateData: templateUpdateData(builder),
    updateQrProfileData: updateQrProfileData(builder),
    allusersData: allusersData(builder),
    contactUs: contactUs(builder),
    getAllQrAnaliytics: getAllQrAnaliytics(builder),
  }),
});

// Export hooks for using in components
const {
  useGetQrFrameDataQuery,
  useGetQrTemplatesDataQuery,
  useGetOneQrTemplateDataQuery,
  useGetQrLogoDataQuery,
  useGetQrShapeDataQuery,
  useGetQrLevelDataQuery,
  useQrCodeStoreDataMutation,
  useTemplateStoreDataMutation,
  useTemplateEditDataMutation,
  useTemplateDeleteDataMutation,
  useQrCodeUpdateDataMutation,
  useGetFolderDataQuery,
  useCreateQRCodeMutation,
  useGetQRCodesMutation,
  useGetQrProfileDataQuery,
  useGetQrTemplateslistMutation,
  useUpdateQrProfileDataMutation,
  useGetTemplateListDataMutation,
  useDeleteQrCodeMutation,
  useGetSingleTemplateDataMutation,
  useTemplateUpdateDataMutation,
  useAllusersDataMutation,
  useContactUsMutation,
  useGetAllQrAnaliyticsMutation,
} = qrApi;

export {
  qrApi,
  useGetQrFrameDataQuery,
  useGetQrTemplatesDataQuery,
  useGetOneQrTemplateDataQuery,
  useGetQrLogoDataQuery,
  useGetQrShapeDataQuery,
  useGetQrLevelDataQuery,
  useQrCodeStoreDataMutation,
  useTemplateStoreDataMutation,
  useTemplateEditDataMutation,
  useTemplateDeleteDataMutation,
  useQrCodeUpdateDataMutation,
  useGetFolderDataQuery,
  useCreateQRCodeMutation,
  useGetQRCodesMutation,
  useGetQrProfileDataQuery,
  useGetQrTemplateslistMutation,
  useUpdateQrProfileDataMutation,
  useGetTemplateListDataMutation,
  useDeleteQrCodeMutation,
  useGetSingleTemplateDataMutation,
  useTemplateUpdateDataMutation,
  useAllusersDataMutation,
  useContactUsMutation,
  useGetAllQrAnaliyticsMutation,
};
