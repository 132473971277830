import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function MyQrCodeSkeleton({ baseStyle }) {
  return (
    <div className="p-6">
      {/* QR Code List */}
      <div className="space-y-4">
        {Array(4)
          .fill()
          .map((_, i) => (
            <div
              key={i}
              className="bg-[#1B2C37] rounded-lg border border-[#15212b] p-4 flex items-center"
            >
              {/* QR Code Image */}
              <div className="w-28 h-28 p-2 bg-[#1B2C37] rounded-lg border border-[#15212b]">
                <Skeleton
                  height="100%"
                  {...baseStyle}
                  className="!rounded-lg"
                />
              </div>

              {/* Info Section */}
              <div className="flex-1 ml-4">
                {/* Type and Title */}
                <div className="flex items-center gap-2 mb-2">
                  <Skeleton
                    width={60}
                    height={20}
                    className="text-blue-500 !rounded-md"
                    {...baseStyle}
                  />
                  <Skeleton
                    width={120}
                    height={24}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                  <Skeleton
                    width={20}
                    height={20}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                </div>

                {/* User */}
                <div className="flex items-center gap-2 mb-3">
                  <Skeleton circle width={20} height={20} {...baseStyle} />
                  <Skeleton
                    width={50}
                    height={20}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                </div>

                {/* Dates */}
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Skeleton width={20} height={20} {...baseStyle} />
                    <Skeleton
                      width={150}
                      height={16}
                      {...baseStyle}
                      className="!rounded-md"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton width={20} height={20} {...baseStyle} />
                    <Skeleton
                      width={150}
                      height={16}
                      {...baseStyle}
                      className="!rounded-md"
                    />
                  </div>
                </div>
              </div>

              {/* Folder and URL Section */}
              <div className="flex flex-col items-end gap-3 min-w-[200px]">
                {/* Folder */}
                <div className="flex items-center gap-2">
                  <Skeleton width={20} height={20} {...baseStyle} />
                  <Skeleton
                    width={80}
                    height={20}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                </div>
                {/* URL */}
                <div className="flex items-center gap-2">
                  <Skeleton width={20} height={20} {...baseStyle} />
                  <Skeleton
                    width={180}
                    height={20}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                  <Skeleton width={20} height={20} {...baseStyle} />
                </div>
                {/* Stats */}
                <div className="flex gap-3">
                  <div className="flex items-center gap-1">
                    <Skeleton width={20} height={20} {...baseStyle} />
                    <Skeleton
                      width={20}
                      height={20}
                      {...baseStyle}
                      className="!rounded-md"
                    />
                  </div>
                  <div className="flex items-center gap-1">
                    <Skeleton width={20} height={20} {...baseStyle} />
                    <Skeleton
                      width={20}
                      height={20}
                      {...baseStyle}
                      className="!rounded-md"
                    />
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="ml-4 flex items-center gap-2">
                <Skeleton
                  width={100}
                  height={36}
                  {...baseStyle}
                  className="!rounded-md"
                />
                <Skeleton
                  width={36}
                  height={36}
                  {...baseStyle}
                  className="!rounded-md"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default MyQrCodeSkeleton;
