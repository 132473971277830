import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getResourceByIdData
 * @description API call to fetch resource data by ID
 */
const getResourceByIdData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "qr-resource-by-id",
      method: "POST",
      data: payload,
    }),
  });
};

// Create Resources API slice
const resourceByIdApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.RESOURCE_BY_ID_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getResourceByIdData: getResourceByIdData(builder),
  }),
});

// Export hooks for using in components
const {
  useGetResourceByIdDataQuery,
} = resourceByIdApi;

export {
  resourceByIdApi,
  useGetResourceByIdDataQuery,
};
