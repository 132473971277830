import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SkeletonLoader from "../components/global/skeleton/SkeletonLoader";

const TransitionContext = createContext(null);

const TransitionProvider = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false); 
  const [loadingData, setLoadingData] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsTransitioning(true);

    const timeout = setTimeout(() => {
      setIsTransitioning(false); 
    }, 100); 

    const dataTimeout = setTimeout(() => {
      setLoadingData(false); 
    }, 2000); 

    return () => {
      clearTimeout(timeout);
      clearTimeout(dataTimeout);
    };
  }, [location.pathname]); 

  return (
    <TransitionContext.Provider value={{ isTransitioning }}>
      <div className="page-container">
        {isTransitioning || loadingData ? (
          <SkeletonLoader variant="newQr" />
        ) : (
          children 
        )}
      </div>
    </TransitionContext.Provider>
  );
};

export { TransitionContext, TransitionProvider };
