// SLICES
const SLICES = {
  AUTH_SLICE: "AUTH_SLICE",
  MOBILE_PREVIEW_SLICE: "MOBILE_PREVIEW_SLICE",
  APPERANCE_CUSTOMIZE_SLICE: "APPERANCE_CUSTOMIZE_SLICE",
  MY_QR_CODE_SLICE: "MY_QR_CODE_SLICE",
  UPDATE_PROFILE_SLICE: "UPDATE_PROFILE_SLICE",
  SUBSCRIPTION_SLICE: "SUBSCRIPTION_SLICE",
  DASHBOARD_CARD_SLICE: "DASHBOARD_CARD_SLICE",
  MEMBER_ROLES_SLICE: "MEMBER_ROLES_SLICE",
  CARD_FORM_DATA: "CARD_FORM_DATA",
  USER_DATA_SLICE: "USER_DATA_SLICE",
  PLANS_SLICE: "PLANS_SLICE",
  INVOICE_SLICE: "INVOICE_SLICE",
  CHECKOUT_SLICE: "CHECKOUT_SLICE",
  FOLDER_SLICE: "FOLDER_SLICE",
  DOMAIN_SLICE: "DOMAIN_SLICE",
  INVOICE_SLICE: "INVOICE_SLICE",
  QR_SCAN_ANALYTICS: "QR_SCAN_ANALYTICS",
  REGISTER_USER_SLICE: "REGISTER_USER_SLICE",
  SUBSCRIPTION_SLICE: "SUBSCRIPTION_SLICE",
};

// TAGS
const TAGS = {
  AUTH_TAG: "AUTH_TAG",
  CONTACT_TAG: "CONTACT_TAG",
  FAVORITES_TAG: "FAVORITES_TAG",
  UPLOAD_TAG: "UPLOAD_TAG",
  FOLDER_TAG: "FOLDER_TAG",
  CONTACTUS_TAG: "CONTACTUS_TAG",
  QR_TAG: "QR_TAG",
  META_DATA_TAG: "META_DATA_TAG",
  MY_DOMAIN_TAG: "MY_DOMAIN_TAG",
  MY_QR_CODE_TAG: "MY_QR_CODE_TAG",
  NEW_QR_CODE_TAG: "NEW_QR_CODE_TAG",
  PLANS_TAG: "PLANS_TAG",
  PRODUCTS_TAG: "PRODUCTS_TAG",
  PROFILE_TAG: "PROFILE_TAG",
  RESOURCES_TAG: "RESOURCES_TAG",
  RESOURCE_BY_ID_TAG: "RESOURCE_BY_ID_TAG",
  SETTINGS_TAG: "SETTINGS_TAG",
  STATS_TAG: "STATS_TAG",
};
// REDUCERS PATHS
const REDUCER_PATHS = {
  AUTH_API: "AUTH_API",
  CONTACT_API: "CONTACT_API",
  FAVORITES_API: "FAVORITES_API",
  UPLOAD_API: "UPLOAD_API",
  FOLDER_API: "FOLDER_API",
  CONTACTUS_API: "CONTACTUS_API",
  QR_API: "QR_API",
  META_DATA_API: "META_DATA_API",
  MY_DOMAIN_API: "MY_DOMAIN_API",
  MY_QR_CODE_API: "MY_QR_CODE_API",
  NEW_QR_CODE_API: "NEW_QR_CODE_API",
  PLANS_API: "PLANS_API",
  PRODUCTS_API: "PRODUCTS_API",
  PROFILE_API: "PROFILE_API",
  RESOURCES_API: "RESOURCES_API",
  RESOURCE_BY_ID_API: "RESOURCE_BY_ID_API",
  SETTINGS_API: "SETTINGS_API",
  STATS_API: "STATS_API",
  DASHBOARD_API: "DASHBOARD_API",
  QR_DOMAIN_API: "QR_DOMAIN_API",
};

export default { SLICES, TAGS, REDUCER_PATHS };
