import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { qrApi } from "../../services";

const dashboardCards = createSlice({
  name: "dashboardCards",
  initialState: {
    dashboardCards: [],
  },
  reducers: {
    setDashboardCards: (state, action) => {
      const data = action.payload;
      state.dashboardCards = data;
    },
  },
});

const { setDashboardCards } = dashboardCards.actions;

// export const generateQRCodeThunk = (data) => async (dispatch) => {
//   dispatch(setLoading());
//   try {
//     const result = await dispatch(
//       qrApi.endpoints.generateQRCOde?.initiate(data)
//     );
//     dispatch(setSuccess());
//     dispatch(pushQRCode(result?.data));
//     console.log(result?.data);
//   } catch (error) {
//     dispatch(setError(error?.message));
//     console.log(error?.message);
//   }
// };

export { setDashboardCards };
export default dashboardCards.reducer;
