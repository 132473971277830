import React from "react";
import DashboardPage from "./DashboardPage";
import MainLayout from "../../layouts/MainLayout";

const index = () => {
  return (
    <MainLayout>
      <DashboardPage />
    </MainLayout>
  );
};

export default index;
