import { createContext, useContext, useEffect, useRef, useState } from "react";
// Constants
import {
  INITIAL_STATE_QR_CONTENT,
  INITIAL_STATE_QR_UPLOAD_CONTENT,
  NEW_QR_STEPS,
} from "../constants";
// Others

export const QrContext = createContext();
export const QrProvider = ({ children }) => {
  const qrRef = useRef(null);
  const formRef = useRef(null);
  const ScheduleDateInform = useRef(null);
  const scanLimitForm = useRef(null);
  const urlConfigurationForm = useRef(null);
  const [qrValue, setQrValue] = useState("");
  const [frameqrstyle, setframeqrstyle] = useState("");
  const [qrtext, setqrtext] = useState("Scan Me !!");
  const [qrActiveindex, setQrActiveIndex] = useState(-1);
  const [ShowTemplates, setShowTemplates] = useState(false);
  const [qrtextstyle, setqrtextstyle] = useState("");

  const [qrType, setQrType] = useState("");
  const [formData, setFormData] = useState(INITIAL_STATE_QR_CONTENT);
  const [frameType, setFrameType] = useState(null);
  const [frameId, setFrameId] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.id
  );
  const [bgColor, setBgColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.shape.backgroundColor
  );

  const [fgColor, setFgColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.shape.color
  );

  const [level, setLevel] = useState(
    INITIAL_STATE_QR_CONTENT.style.errorCorrectionLevel
  );
  const [shape, setShape] = useState(
    INITIAL_STATE_QR_CONTENT.style.shape.style
  );
  const [logo, setLogo] = useState(null);
  const [logoId, setLogoId] = useState(null);
  const [frameName, setFrameName] = useState(null);
  const [frameTextColor, setFrameTextColor] = useState("#fffffff");
  const [frameTextSize, setFrameTextSize] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.frameTextSize
  );
  const [frameStyle, setFrameStyle] = useState(null);
  const [plainText, setPlainText] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.text
  );
  const [textColor, setTextColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.textColor
  );
  const [color, setColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.frameColor
  );
  const [backgroundColor, setBackgroundColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.backgroundColor
  );
  const [activeKey, setActiveKey] = useState(NEW_QR_STEPS.TYPE_OF_QR);
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [toggleAppereance, setToggleAppereance] = useState(true);
  const [toggleTimeScheduling, setToggleTimeScheduling] = useState(false);
  const [toggleBasicInformation, setToggleBasicInformation] = useState(false);
  const [toggleStats, setToggleStats] = useState(false);
  const [toggleConfiguration, setToggleConfiguration] = useState(false);
  const [toggleAppearance, setToggleAppearance] = useState(false);
  const [isTransparent, setIsTransparent] = useState(false);
  const [toggleContent, setToggleContent] = useState(false);
  const [matchedComponent, setMatchedComponent] = useState(null);
  const [sectionsData, setSectionsData] = useState(
    INITIAL_STATE_QR_UPLOAD_CONTENT
  );
  const [selectedCornerShape, setSelectedCornerShape] = useState(null);
  const [selectedCenterShape, setSelectedCenterShape] = useState(null);
  //.......................................................

  const [cornerColor, setCornerColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.corners.dotColor
  );
  const [Framecolor, setFramecolor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.frameColor
  );
  const [FrameBackgroundColor, setFrameBackgroundColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.backgroundColor
  );
  const [Frametextcolor, setFrametextcolor] = useState(
    INITIAL_STATE_QR_CONTENT.style.frame.color
  );
  const [centerColor, setCenterColor] = useState(
    INITIAL_STATE_QR_CONTENT.style.corners.squareColor
  );
  // const [fgColor2, setFgColor2] = useState(
  //   INITIAL_STATE_QR_CONTENT.style.shape.dotColor
  // );
  // const [bgColor2, setBgColor2] = useState(
  //   INITIAL_STATE_QR_CONTENT.style.shape.squareColor
  // );
  const [editCardInfo, setEditCardInfo] = useState(
    INITIAL_STATE_QR_CONTENT.editCardInfo
  );
  //.......................................................
  const [qrStyle, setQrStyle] = useState(null);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [dynamicColor, setDynamicColor] = useState(null);
  const [isProductsViewOpened, setIsProductsViewOpened] = useState(false);
  const qrRefWrapper = useRef();
  const [selectedPlanId, setSelectedPlanId] = useState(1);
  const [stripePriceId, setStripePriceId] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [listView, setListView] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [togglePreviewClick, setTogglePreviewClick] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(0);

  // Reset Function
  const resetAllStates = () => {
    setQrValue("");
    setFormData(INITIAL_STATE_QR_CONTENT);
    setFrameType(null);
    setFrameId(INITIAL_STATE_QR_CONTENT.style.frame.id);
    setBgColor(INITIAL_STATE_QR_CONTENT.style.shape.backgroundColor);
    setFgColor(INITIAL_STATE_QR_CONTENT.style.shape.color);
    setBgColor2(INITIAL_STATE_QR_CONTENT.style.shape.backgroundColor);
    setFgColor2(INITIAL_STATE_QR_CONTENT.style.shape.color);
    setLevel(INITIAL_STATE_QR_CONTENT.style.errorCorrectionLevel);
    setShape(INITIAL_STATE_QR_CONTENT.style.shape.style);
    setLogo(null);
    setLogoId(null);
    setFrameName(null);
    setFrameTextColor(null);
    setFrameTextSize(INITIAL_STATE_QR_CONTENT.style.frame.frameTextSize);
    setFrameStyle(null);
    setPlainText(INITIAL_STATE_QR_CONTENT.style.frame.text);
    setTextColor(INITIAL_STATE_QR_CONTENT.style.frame.textColor);
    setColor(INITIAL_STATE_QR_CONTENT.style.frame.frameColor);
    setBackgroundColor(INITIAL_STATE_QR_CONTENT.style.frame.backgroundColor);
    setActiveKey(NEW_QR_STEPS.TYPE_OF_QR);
    setErrors({});
    setModalOpen(false);
    setToggleAppereance(true);
    setToggleTimeScheduling(false);
    setToggleBasicInformation(false);
    setToggleStats(false);
    setToggleConfiguration(false);
    setToggleAppearance(false);
    setIsTransparent(false);
    setToggleContent(false);
    setMatchedComponent(null);
    setSectionsData(INITIAL_STATE_QR_UPLOAD_CONTENT);
    setSelectedCornerShape(null);
    setSelectedCenterShape(null);
    setCornerColor(INITIAL_STATE_QR_CONTENT.style.corners.dotColor);
    setCenterColor(INITIAL_STATE_QR_CONTENT.style.corners.squareColor);
    setFramecolor(INITIAL_STATE_QR_CONTENTstyle.frame.frameColor);
    setFrameBackgroundColor(INITIAL_STATE_QR_CONTENT.style.frame.frameColor);
    setFrametextcolor(INITIAL_STATE_QR_CONTENT.style.frame.frameColor);
    setEditCardInfo(null);
    setIsGlobalLoading(false);
    setDynamicColor("#000000");
    formRef?.current?.resetFields();
    setIsProductsViewOpened(false);
    setSelectedPlanId(null);
    setStripePriceId(null);
    setSelectedPlanData(null);
    setSelectedTemplate(null);
    setTogglePreviewClick(false);
    // setDaysRemaining(0);
  };

  const value = {
    qrValue,
    setQrValue,
    formData,
    setFormData,
    frameType,
    setFrameType,
    bgColor,
    setBgColor,
    fgColor,
    setFgColor,
    setFrameId,
    frameId,
    shape,
    setShape,
    setLevel,
    level,
    qrRef,
    setLogo,
    setLogoId,
    logo,
    logoId,
    setFrameName,
    frameName,
    setFrameTextColor,
    frameTextColor,
    setFrameTextSize,
    frameTextSize,
    setFrameStyle,
    frameStyle,
    setActiveKey,
    activeKey,
    errors,
    setErrors,
    formRef,
    ScheduleDateInform,
    scanLimitForm,
    urlConfigurationForm,
    setModalOpen,
    modalOpen,
    toggleTimeScheduling,
    setToggleTimeScheduling,
    toggleAppereance,
    setToggleAppereance,
    toggleBasicInformation,
    setToggleBasicInformation,
    toggleStats,
    setToggleStats,
    toggleConfiguration,
    setToggleConfiguration,
    isTransparent,
    setIsTransparent,
    setToggleAppearance,
    toggleAppearance,
    toggleContent,
    setToggleContent,
    sectionsData,
    setSectionsData,
    setPlainText,
    plainText,
    setTextColor,
    textColor,
    setBackgroundColor,
    backgroundColor,
    setColor,
    color,
    matchedComponent,
    setMatchedComponent,
    setSelectedCornerShape,
    selectedCornerShape,
    setSelectedCenterShape,
    selectedCenterShape,
    setCornerColor,
    cornerColor,
    setCenterColor,
    centerColor,
    resetAllStates,
    setEditCardInfo,
    editCardInfo,
    qrStyle,
    setQrStyle,
    setIsGlobalLoading,
    isGlobalLoading,
    setDynamicColor,
    dynamicColor,
    setIsProductsViewOpened,
    isProductsViewOpened,
    qrRefWrapper,
    setSelectedPlanId,
    selectedPlanId,
    setStripePriceId,
    stripePriceId,
    setSelectedPlanData,
    selectedPlanData,
    setListView,
    listView,
    setActiveTab,
    activeTab,
    setSelectedTemplate,
    selectedTemplate,
    setTogglePreviewClick,
    togglePreviewClick,
    setDaysRemaining,
    daysRemaining,
    FrameBackgroundColor,
    Framecolor,
    Frametextcolor,
    setFrameBackgroundColor,
    setFramecolor,
    setFrametextcolor,
    frameqrstyle,
    setframeqrstyle,
    qrtext,
    setqrtext,
    qrtextstyle,
    setqrtextstyle,
    qrType,
    setQrType,
    ShowTemplates,
    setShowTemplates,
    setQrActiveIndex,
    qrActiveindex,
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue(formData);
    }
  }, [formData]);

  return <QrContext.Provider value={value}>{children}</QrContext.Provider>;
};
export const useQrContext = () => {
  const context = useContext(QrContext);
  if (!context) {
    throw new Error("useQrContext must be used within a QrProvider");
  }
  return context;
};
