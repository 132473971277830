import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getMyQrCodeData
 * @description API call to fetch QR code data
 *
 * @param payload: object
 */
const getMyQrCodeData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/qr-code/get-all",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name addToFavorites
 * @description API call to add a QR code to favorites
 *
 * @param payload: object
 */
const addToFavorites = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `/qr-code/add-to-favorites?id=${payload.id}`,
      method: "POST",
    }),
  });
};
/*
 * @private
 * @name removeFromFavorites
 * @description API call to remove a QR code from favorites
 *
 * @param payload: object
 */
const removeFromFavorites = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `/qr-code/remove-from-favorites?id=${payload.id}`,
      method: "POST",
    }),
  });
};

/*
 * @private
 * @name duplicateQrCodeData
 * @description API call to duplicate QR code data
 *
 * @param payload: object
 */
const duplicateQrCodeData = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/qr-code/duplicate",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name updateTextFiled
 * @description API call to update text field in QR code
 *
 * @param payload: object
 */
const updateTextFiled = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/qr-code/update-text",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getQRCodeByIdData
 * @description API call to get QR code by ID
 *
 * @param payload: object
 */
const getQRCodeByIdData = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `/qr-code/get-by-id?id=${payload.id}`,
      method: "GET",
    }),
  });
};

/*
 * @private
 * @name getQRCodeByURLData
 * @description API call to get QR code by URL
 *
 * @param payload: object
 */
const getQRCodeByURLData = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `/qr-code/get-by-redirect-url?redirect_url=https://stage24.qr-gen.com/${payload.id}`,
      method: "GET",
    }),
  });
};

/*
 * @private
 * @name getQRCodeScanLog
 * @description API call to get QR code scan log
 *
 * @param payload: object
 */
const getQRCodeScanLog = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/qr-scan-log/store",
      method: "POST",
      data: payload,
    }),
  });
};

// Create My QR Code API slice
const myQrCodeApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.MY_QR_CODE_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMyQrCodeData: getMyQrCodeData(builder),
    duplicateQrCodeData: duplicateQrCodeData(builder),
    updateTextFiled: updateTextFiled(builder),
    getQRCodeByIdData: getQRCodeByIdData(builder),
    getQRCodeByURLData: getQRCodeByURLData(builder),
    getQRCodeScanLog: getQRCodeScanLog(builder),
    addToFavorites: addToFavorites(builder), // Add to favorite qr code mutation
    removeFromFavorites: removeFromFavorites(builder), // Remove From Favorite mutation
  }),
});

// Export hooks for using in components
const {
  useGetMyQrCodeDataQuery,
  useDuplicateQrCodeDataMutation,
  useUpdateTextFiledMutation,
  useGetQRCodeByIdDataMutation,
  useLazyGetQRCodeByIdDataQuery,
  useGetQRCodeByURLDataMutation,
  useGetQRCodeScanLogQuery,
  useAddToFavoritesMutation, // Hook of Add to favorite mutation
  useRemoveFromFavoritesMutation, // Hook of Remove from favorite mutation
} = myQrCodeApi;

export {
  myQrCodeApi,
  useGetMyQrCodeDataQuery,
  useLazyGetQRCodeByIdDataQuery,
  useDuplicateQrCodeDataMutation,
  useUpdateTextFiledMutation,
  useGetQRCodeByIdDataMutation,
  useGetQRCodeByURLDataMutation,
  useGetQRCodeScanLogQuery,
  useAddToFavoritesMutation, //
  useRemoveFromFavoritesMutation, //
};
