// import { createContext, useContext, useState } from "react";

import { createContext, useState } from "react";

export const Skeletoncontext = createContext()

export const SkeltonProvider = ({ children }) => {
  const [skeleton, setskeleton] = useState(false);
  console.log(skeleton)

  return (
    <Skeletoncontext.Provider
      value={{
        skeleton,
        setskeleton
      }}
    >
      {children}
    </Skeletoncontext.Provider>
  );
};
