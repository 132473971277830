import { createSlice } from "@reduxjs/toolkit";

const apperanceCustomizerSlice = createSlice({
  name: "appearanceCustomizer",
  initialState: {
    triggeredCard: "",
    triggeredFrame: "0",
    foregroundColor: "",
    backgroundColor: "",
    titleFontFamily: "Lato",
    textFontFamily: "Lato",
  },
  reducers: {
    setCardName: (state, action) => {
      const cardName = action.payload?.cardName;
      state.triggeredCard = cardName;
    },
    setFrame: (state, action) => {
      const frame = action.payload?.frame;
      state.triggeredFrame = frame;
      console.log(state.triggeredFrame);
    },
    setFont: (state, action) => {
      const titleFontFamily = action.payload?.titleFont;
      const textFontFamily = action.payload?.textFont;
      state.titleFontFamily = titleFontFamily;
      if (textFontFamily) {
        state.textFontFamily = textFontFamily;
      }
    },
    setColor: (state, action) => {
      const foregroundColor = action?.payload?.foregroundColor;
      const backgroundColor = action?.payload?.backgroundColor;
      state.backgroundColor = backgroundColor;
      if (foregroundColor) {
        state.foregroundColor = foregroundColor;
      }
    },
  },
});

export const { setCardName, setFrame, setFont, setColor } =
  apperanceCustomizerSlice.actions;
export default apperanceCustomizerSlice.reducer;
