import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function StatsSkeleton({ baseStyle }) {
  return (
    <div className="flex w-full">
      <div className="p-6 w-full">
        {/* Filters Section */}
        <div className="space-y-6 mb-8">
          {/* First Row */}
          <div className="flex gap-4 items-center">
            <div className="flex-1 max-w-xs">
              <Skeleton
                width={50}
                height={20}
                {...baseStyle}
                className="mb-2 !rounded-md"
              />
              <Skeleton
                height={40}
                {...baseStyle}
                className="!rounded-md border border-[#15212b]"
              />
            </div>
            <div className="flex-1">
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="mb-2 !rounded-md"
              />
              <Skeleton
                height={40}
                {...baseStyle}
                className="!rounded-md border border-[#15212b]"
              />
            </div>
            <Skeleton
              width={150}
              height={40}
              {...baseStyle}
              className="mt-6 !rounded-md border border-[#15212b]"
            />
          </div>

          {/* Second Row */}
          <div className="grid grid-cols-4 gap-4">
            {["QR Code", "Folders", "Operating System", "Countries"].map(
              (label, i) => (
                <div key={i}>
                  <Skeleton
                    width={100}
                    height={20}
                    {...baseStyle}
                    className="mb-2 !rounded-md"
                  />
                  <Skeleton
                    height={40}
                    {...baseStyle}
                    className="!rounded-md border border-[#15212b]"
                  />
                </div>
              )
            )}
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-4 gap-6 mb-8">
          {[
            { label: "Total QR codes", value: "12" },
            { label: "Total Scans", value: "0" },
            { label: "Total Unique Scans", value: "0" },
            { label: "Total visits", value: "12" },
          ].map((stat, i) => (
            <div
              key={i}
              className=" rounded-lg border  p-6 flex flex-col items-center bg-[#1B2C37] border-[#15212b]"
            >
              <Skeleton
                width={60}
                height={48}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton
                width={120}
                height={20}
                {...baseStyle}
                className="!rounded-md"
              />
            </div>
          ))}
        </div>

        {/* Scan Activities Section */}
        <div className=" rounded-lg border p-6 mb-4 bg-[#1B2C37] border-[#15212b]">
          <div className="flex justify-between items-center mb-4">
            <div>
              <Skeleton
                width={100}
                height={24}
                {...baseStyle}
                className="!rounded-md mb-3"
              />
              <div className="flex gap-4">
                {["Total", "Unique", "Visits to the Preview"].map((_, i) => (
                  <div key={i} className="flex items-center gap-2">
                    <Skeleton
                      width={16}
                      height={16}
                      {...baseStyle}
                      className="!rounded-sm border border-[#15212b]"
                    />
                    <Skeleton
                      width={100}
                      height={20}
                      {...baseStyle}
                      className="!rounded-md"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex gap-2">
              {["Day", "Month", "Year"].map((_, i) => (
                <Skeleton
                  key={i}
                  width={80}
                  height={36}
                  {...baseStyle}
                  className="!rounded-md border border-[#15212b]"
                />
              ))}
            </div>
          </div>
          <div className="flex items-center justify-center py-20">
            <Skeleton
              width={200}
              height={24}
              {...baseStyle}
              className="!rounded-md"
            />
          </div>
        </div>

        {/* Bottom List Items */}
        {[
          "Scans by operating system",
          "Scans by country",
          "Scans by region/city",
          "Scans by browser",
        ].map((item, i) => (
          <div
            key={i}
            className=" rounded-lg border bg-[#1B2C37] border-[#15212b] p-4 mb-4 flex justify-between items-center"
          >
            <div className="flex items-center gap-2">
              <Skeleton
                width={150}
                height={20}
                {...baseStyle}
                className="!rounded-md"
              />
              {i === 3 && (
                <Skeleton
                  width={40}
                  height={20}
                  {...baseStyle}
                  className="!rounded-md"
                />
              )}
            </div>
            <Skeleton
              width={24}
              height={24}
              {...baseStyle}
              className="!rounded-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatsSkeleton;
