import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const memberRolesSlice = createSlice({
  name: "memberRoles",
  reducerPath: STORE.SLICES.MEMBER_ROLES_SLICE,
  initialState: {
    memberRolesData: [],
    selectedRole: {},
  },
  reducers: {
    setMemberRoles: (state, action) => {
      state.memberRolesData = action.payload;
    },
    setSelectedMember: (state, action) => {
      state.selectedRole = action.payload;
    },
  },
});

export const { setMemberRoles, setSelectedMember } = memberRolesSlice.actions;

export default memberRolesSlice.reducer;
