// Imports
import moment from "moment";

/*
 * @public
 * @name Global Constants
 * @description All this function will be used across the application
 */

// Handle Formate Date for example (2024-02-24 => Feb 24)
export const formatDate = (inputDate) => {
  const formattedDate = moment(inputDate).format("MMM D");
  return formattedDate;
};

// Handle Fix Url Of Image
export function getImage(baseUrl = `${import.meta.env.VITE_APP_API_URL}uploads/`, urlPath) {
  if (urlPath != null && urlPath != undefined && urlPath != "") {
    // if (urlPath.startsWith("http://") || urlPath.startsWith("https://")) {
    //   return `${urlPath}`;
    // } else {
    return `${baseUrl}${urlPath}`;
    // }
  } else {
    return "https://placehold.co/600x600/61cbea/FFF?text=QRGEN";
  }
}

export const MOCK_DELAY_TIMER = 100;


export function updateUrlBaseAndExt(url, newBase, newExtension) {
  try {
      const urlObj = new URL(url);
      
      urlObj.hostname = new URL(newBase).hostname;
      urlObj.protocol = new URL(newBase).protocol;
      
      let pathnameParts = urlObj.pathname.split('.');
      if (pathnameParts.length > 1) {
          pathnameParts[pathnameParts.length - 1] = newExtension;
      }
      urlObj.pathname = pathnameParts.join('.');
      
      return urlObj.toString();
  } catch (error) {
      console.error("Invalid URL:", error);
      return null;
  }
}