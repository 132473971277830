import React from "react";
import { FiPlus } from "react-icons/fi";

const OutlinePlusButton = ({ title, className, type = "button", onClick }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex shrink-0 items-center justify-center gap-2 px-4 py-2 border border-[var(--primary)] cursor-pointer text-[var(--primary)] rounded-lg font-semibold hover:bg-[var(--primary)] hover:text-[var(--darker-bg)] focus:ring-2 focus:ring-[var(--primary)] ${className}`}
    >
      <FiPlus size={18} />
      {title}
    </button>
  );
};

export default OutlinePlusButton;
