import React from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

function HelpCenterSkeleton({ baseStyle }) {
  return (
    <div className="w-full flex flex-col items-center py-10">
      {/* Title Section */}
      <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

      {/* Subtitle Section */}
      <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

      {/* Help Topics Grid Section */}
      <div className="w-full grid grid-cols-1 gap-8">
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className="flex justify-center gap-10 items-center space-y-3"
          >
            <Skeleton {...baseStyle} width={80} height={80} borderRadius={10} />
            <div className="flex flex-col">
              <Skeleton {...baseStyle} width={180} height={20} />
              <Skeleton {...baseStyle} width={220} height={15} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HelpCenterSkeleton;
