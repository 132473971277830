import { createSlice } from "@reduxjs/toolkit";

const allQrAnalytics = createSlice({
    name: "qrScanAnalytics",
    initialState: {
        qrAnalytics: [],
    },
    reducers: {
        setQSA: (state, action) => {
            const data = action.payload;
            state.qrAnalytics = data;
        },
    },
});

export const { setQSA } =
allQrAnalytics.actions;
export default allQrAnalytics.reducer;