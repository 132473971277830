// Import React Icons
import NewQrIcon from "../../../assets/NewQrIcon.webp";
import MyQrCode from "../../../assets/MyQrCode.webp";
import SideBarStatsIcon from "../../../assets/SideBarStatsIcon.webp";
import BulkQrGeneration from "../../../assets/BulkQrGeneration.webp";
import SideBarTemplateIcon from "../../../assets/SideBarTemplateIcon.webp";
import MyDomainIcon from "../../../assets/MyDomainIcon.webp";
import dollar from "../../../assets/dollar 1.webp";

const unAuthSidebar = [
  {
    icon: <img src={NewQrIcon} />, // Icon for New QR
    title: "Features",
    route: "/static-qr-codes",
  },
  {
    icon: <img src={BulkQrGeneration} />, // Icon for New QR
    title: "Resources",
    route: "/qr-codes-for",
  },
  {
    icon: <img src={BulkQrGeneration} />, // Icon for New QR
    title: "Resources",
    route: "/plans-and-prices",
  },
  {
    icon: <img src={MyQrCode} />,
    title: "API",
    route: "/api",
  },
  {
    icon: <img src={SideBarStatsIcon} />, // Icon for Stats
    title: "FAQ",
    route: "/faq",
  },
];

export default unAuthSidebar;
