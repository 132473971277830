import React from "react";
// import mobilePreviewImage from "../../assets/svg/mobilePreviewSvg.webp";

const DashboardMobilePreview = ({ url, className }) => {
  return (
    <div className="flex flex-col sticky top-16 z-10 items-center lg:sticky">
      <h3 className="text-[22px] my-4 font-normal text-white">Example</h3>
      <img src={url} alt="mobilePreview" className={`w-[500px] ${className}`} />
    </div>
  );
};

export default DashboardMobilePreview;
