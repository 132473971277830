import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const NavbarSkeleton = () => {
    const baseStyle = {
        baseColor: "#111b22",
        highlightColor: "#233a49",
        borderRadius: "0.375rem", // Tailwind rounded-md
        duration: 0.5,
    };
    return (
        <nav className="bg-[#0D1B2A] w-full px-6 py-4 flex items-center justify-between">
            {/* Left: Logo */}
            <div className="flex items-center gap-4">
                <Skeleton width={90} height={30} {...baseStyle} />
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex gap-6">
                <Skeleton {...baseStyle} width={80} height={20} />
                <Skeleton width={100} height={20} {...baseStyle} />
                <Skeleton width={90} height={20} {...baseStyle} />
                <Skeleton width={60} height={20} {...baseStyle} />
                <Skeleton width={50} height={20} {...baseStyle} />
            </div>

            {/* Right Side */}
            <div className="flex items-center gap-4">
                <Skeleton width={100} height={35} {...baseStyle} borderRadius={8} />
                <Skeleton width={80} height={35} {...baseStyle} borderRadius={8} />
            </div>

            {/* Mobile Menu */}
            <div className="md:hidden flex items-center gap-4">
                <Skeleton width={30} height={30} {...baseStyle} borderRadius={8} />
                <Skeleton width={30} height={30} {...baseStyle} borderRadius={8} />
            </div>
        </nav>
    );
};

export default NavbarSkeleton;
