import { createSlice } from "@reduxjs/toolkit";
import { useUploadImageDataMutation } from "../../services/upload";

const dataSchema = {
  pdfFiles: [],
  pdfCompany: "",
  pdfButtonText: "",
  pdfDescription: "",
  pdfWebsite: "",
  pdfLogoCoverImage: [],
  pdfFIleCoverImage: [],
  globalTimeSchedulingName: "",
  globalTimeSchedulingDays: "",
  globalGoogleAnalytics4TrackingID: "",
  globalFacebookID: "",
  globalGoogleTagManagerID: "",
  globalUrl: "",
  globalEnableScanLimit: "",
  globalPassword: "",
  vCardName: "",
  vCardSurname: "",
  vCardPlusAboutYouTitle: "",
  vCardPlusprofileImage: [],
  vCardPlusNumberLabel: "",
  vCardPlusPersonalNumber: "",
  vCardEmailLabel: "",
  vCardPersonalEmail: "",
  vCardwebLabel: "",
  vCardPersonalWebsite: "",
  vCardwebLabel: "",
  vCardEmailLabel: "",
  vCardSearchAddress: "",
  vCardStreet: "",
  vCardNumber: "",
  vCardPostalCode: "",
  vCardCity: "",
  vCardState: "",
  vCardCountry: "",
  vCardUrl: "",
  vCardLatitude: "",
  vCardLongitude: "",
  vCardSummary: "",
  vCardCompanyName: "",
  vCardCompanyProfessionName: "",
  vCardCustomizeYourButtonPhone: "",
  vCardCustomizeYourButtonEmail: "",
  vCardCustomizeYourButtonLocation: "",
  vCardCustomizeYourButtonContact: "",
  vCardSocialNetworksUrl: "",
  vCardSocialNetworksText: "",
  vCardSocialNetworksButtonText: "",
  imagesContentImageTitle: "",
  imagesContentDescription: "",
  imagesContentWebsite: "",
  imagePageCard: [],
  videoCropCompany: "",
  videoTech: "",
  videoDescription: "",
  linkTitle: "",
  linkDescription: "",
  linkWebsite: "",
  businessCompanyImage: [],
  Businessbuttontext: "",
  Businessbuttonurl: "",
  linkContentLogo: [],
  linkContentTitle: "",
  linkContentDescription: "",
  linkContentWebsite: "",
  companyImage: [],
  linkLogo: [],
  businessContentCompany: "",
  businessContentTitle: "",
  businessContentSubtitle: "",
  businessAdditionalInfo: "",
  businessContactInfoName: "",
  businessContactInfoWebsite: "",
  businessContactInfoTelephone: "",
  businessContactInfoEmail: "",
  globalAppsInfoTitle: "",
  globalAppsInfoDescription: "",
  appsGooglePlayLink: "",
  appsAppleStoreLink: "",
  mp3InfoTitle: "",
  mp3InfoWebsite: "",
  mp3InfoDescription: "",
  menuNameOfEstablishment: "",
  menuDescription: "",
  menuSection1Name: "",
  menuSection1Description: "",
  CouponFIleCoverImage: [],
  couponCompany: "",
  couponTitle: "",
  couponDescription: "",
  couponSalesBadge: "",
  couponButtonToSee: "",
  couponCode: "",
  couponValidUntil: "",
  couponTermsAndConditions: "",
  couponInfoButton: "",
  couponInfoURL: "",
  feedbackInfoCompany: "",
  feedbackInfoTitle: "",
  feedbackInfoDescription: "",
  feedbackCategoriesName: "",
  playlistInfoTitle: "",
  playlistInfoName: "",
  playlistInfoDescription: "",
  barcodeGTIN2d: "",
  barcodeWebsiteUrl2d: "",
  socialMediatitle: "",
  socialMediaDescription: "",
  videobutton: "",
  video: [],
  socialnetworks: [],
  Bitcoinurl: "",
  Bitcointext: "",
  Bitcoinbuttontext: "",
  Contacturl: "",
  Contacttext: "",
  Contactbuttontext: "",
  Facebookurl: "",
  Facebooktext: "",
  Facebookbuttontext: "",
  Kitchenurl: "",
  Kitchentext: "",
  Kitchenbuttontext: "",
  Linkurl: "",
  Linktext: "",
  Linkbuttontext: "",
  Locationurl: "",
  Locationtext: "",
  Locationbuttontext: "",
  Messageurl: "",
  Messagetext: "",
  Messagebuttontext: "",
  Paypalurl: "",
  Paypaltext: "",
  Paypalbuttontext: "",
  Purpleurl: "",
  Purpletext: "",
  Purplebuttontext: "",
  Whatsappurl: "",
  Whatsapptext: "",
  Whatsappbuttontext: "",
  Wifiurl: "",
  Wifitext: "",
  Wifibuttontext: "",
  socialMediaLogo: [],
  Coverimage: [],
  SocialMediaTitle: "",
  menuCoverImage: [],
  menuCompanyLogo: [],
  videobutton: "",
  videoFiles: [],
  videoUrl: "",

  landingpageImages: [],
  Landingpagetitle: "",
  Landingpagedescription: "",
  Appdescription: "",
  Appwebsite: "",
  Appname: "",
  AllDay: false,
  AppbgImages: [],
  AppDeveloperCompany: "",
  productAppLogo: [],
  basicInfoImage: [],
  mp3Files: [],
  mp3CoverImage: [],
  eventStartDate: "",
  eventEndDate: "",
  appsAmazonStoreLink: "",
  appsAppStoreLink: "",
  appsPlayStoreLink: "",
  menuSections: [
    {
      name: "",
      sectionLogo: [],
      products: [
        {
          productLogo: [],
          productName: "",
          productTranslatedName: "",
          productDescription: "",
          price: "",
        },
      ],
    },
  ],
  eventImgUpload: [],
  contentLinks: [
    {
      name: "",
      website: "",
      logo: [],
    },
  ],
  eventTitle: "",
  eventDescription: "",
  eventDateSince: "",
  eventDateUntil: "",
  globalSearchAddress: "",
  globalUrl: "",
  videoButtonUrl: "",
  allergens: [],
  openingHours: [],
  imagesContentTitle: "",
  imageContentDescription: "",
  contentButtonText: "",
  contentButtonUrl: "",
  playlistItems: [],
  timePicker: "",
  imageGridType: "",
  allergens: [],
  openingHours: [],
  Organic: [],
  Organicpreview: [],
  RecyclingStamps: [],
  RecyclingStampspreview: [],
  Responsibleconsumption: [],
  Responsibleconsumptionpreview: [],
  Certificates: [],
  Certificatespreview: [],
  videoButtonUrl: "",
  productCategories: [],
  Responsibleconsumption: [],
  productIngredients: [],
  nutritionalInformationQualification: "",
  Nutrients: [],
  businessAdditionalInfo: "",
  facilitiesIcon: [],
  organizationName: "",
  organizationWebsite: "",
  organizationtelephone: "",
  organizationEmail: "",
  shareToggle: false,
  autoComplete: false,
  videobuttons: [],
  facilities: [],
  globalStreet: "",
  globalNumber: "",
  globalPostalCode: "",
  globalCity: "",
  globalState: "",
  globalCountry: "",
  manualEntry: false,
  socialInfoWebsite: "",
  socialInfoTelephone: "",
  socialInfoEmail: "",
  url: "",
  Applogo: [],
  qrcheck: false,
  qrid: "",
  templatedata: "",
  templateName: "",
  qrType: "",
  qrName: "",
  qrApiData: null,
  globalPassword: "",
  //   productIngredient0: '',
  //   productIngredient1: '',
  //   productIngredient2: '',
  //   productIngredient3: '',
  //   productIngredient4: '',
  //   productIngredient5: '',
  // productIngredient6: ''
};

const mobilePreviewSlice = createSlice({
  name: "mobilePreview",
  initialState: dataSchema,
  reducers: {
    setFiles: (state, action) => {
      const nameOfFileInput = action.payload?.name;
      const file = action.payload?.file;
      if (!nameOfFileInput?.includes("[") && !nameOfFileInput?.includes(".")) {
        state[nameOfFileInput]?.unshift(file);
        return;
      }
      const path = nameOfFileInput.split(/[\[\].]+/).filter(Boolean);
      for (let i = 0; i < path.length - 1; i++) {
        if (!state[path[i]]) {
          state[path[i]] = path[i + 1].match(/^\d+$/) ? [] : {};
        }
        state = state[path[i]];
      }
      state[path[path?.length - 1]] = file;
    },
    seticons: (state, action) => {
      const nameOfFileInput = action.payload?.name;
      console.log("Name of Input:", nameOfFileInput);

      // Get the full object instead of just file
      const platformObject = action.payload;
      console.log("Object Received:", platformObject);

      // Initialize as array if it doesn't exist
      if (!state[nameOfFileInput]) {
        state[nameOfFileInput] = [];
      }

      // Check if an object with the same 'id' already exists in the array
      const index = state[nameOfFileInput].findIndex(
        (item) => item.id === platformObject.id
      );

      if (index !== -1) {
        // If an object with the same id exists, remove it from the array
        state[nameOfFileInput].splice(index, 1);
      } else {
        // If it doesn't exist, push the new object to the array
        state[nameOfFileInput].push(platformObject);
      }
    },
    setsocial: (state, action) => {
      const nameOfFileInput = action.payload?.name;
      console.log("Name of Input:", nameOfFileInput);

      // Get the full object instead of just file
      const platformObject = action.payload;
      console.log("Object Received:", platformObject);

      // Initialize as array if it doesn't exist
      if (!state[nameOfFileInput]) {
        state[nameOfFileInput] = nameOfFileInput;
      }

      // Push the entire object to the array
      state[nameOfFileInput].push(platformObject);
    },
    setqrchecked: (state, action) => {
      const value = action.payload.value;
      state["qrcheck"] = value;
    },
    setqrid: (state, action) => {
      const value = action.payload;
      state.qrid = value;
    },

    removeFile: (state, action) => {
      const { nameOfFileInput, fileName, index } = action.payload;

      if (state[nameOfFileInput] && Array.isArray(state[nameOfFileInput])) {
        if (fileName) {
          // Remove all files that match the fileName
          state[nameOfFileInput] = state[nameOfFileInput].filter(
            (file, idx) => file?.name !== fileName || idx !== index
          );
          return;
        }

        // If an index is provided, remove the file at that index
        if (index !== undefined && state[nameOfFileInput][index]) {
          state[nameOfFileInput]?.splice(index, 1);
        }
      } else {
        const path = nameOfFileInput?.split(/[\[\].]+/).filter(Boolean);
        for (let i = 0; i < path?.length - 1; i++) {
          state = state[path[i]];
        }
        state[path[path.length - 1]] = null;
      }
    },

    setValues: (state, action) => {
      const nameOfTextInput = action.payload?.name;
      const text = action.payload?.value;

      if (!nameOfTextInput?.includes("[") && !nameOfTextInput?.includes(".")) {
        state[nameOfTextInput] = text;
        return;
      }

      const path = nameOfTextInput.split(/[\[\].]+/).filter(Boolean);

      for (let i = 0; i < path.length - 1; i++) {
        if (!state[path[i]]) {
          state[path[i]] = path[i + 1].match(/^\d+$/) ? [] : {};
        }
        state = state[path[i]];
      }
      state[path[path.length - 1]] = text;
    },
    addSectionsToArrayField: (state, action) => {
      const { nameOfSectionState, object } = action.payload;
      if (
        !nameOfSectionState?.includes("[") &&
        !nameOfSectionState?.includes(".")
      ) {
        state[nameOfSectionState]?.push(object);
        return;
      }
      const path = nameOfSectionState.split(/[\[\].]+/).filter(Boolean);
      for (let i = 0; i < path.length - 1; i++) {
        if (!state[path[i]]) {
          state[path[i]] = path[i + 1].match(/^\d+$/) ? [] : {};
        }
        state = state[path[i]];
      }
      const key = path[path.length - 1];
      state[key]?.push(object);
    },
    removeSectionsFromArrayField: (state, action) => {
      const { nameOfSectionState, index } = action.payload;
      if (
        nameOfSectionState?.includes(".") &&
        !nameOfSectionState?.includes("[")
      ) {
        state[nameOfSectionState]?.splice(index, 1);
      } else {
        const path = nameOfSectionState.split(/[\[\].]+/).filter(Boolean);
        for (let i = 0; i < path.length - 1; i++) {
          if (!state[path[i]]) {
            state[path[i]] = path[i + 1].match(/^\d+$/) ? [] : {};
          }
          state = state[path[i]];
        }
        const key = path[path.length - 1];
        state[key]?.splice(index, 1);
      }
    },
    setOpeningHours: (state, action) => {
      state.openingHours = action.payload;
    },
    setAutoComplete: (state, action) => {
      const { autoCompleteData } = action.payload;
      // console.log("autoCompleteData", autoCompleteData);
      return autoCompleteData;
    },
    setQRApiData: (state, action) => {
      state.qrApiData = action.payload;
    },
  },
});

export const {
  setFiles,
  removeFile,
  setValues,
  setsocial,
  seticons,
  setQRApiData,
  addSectionsToArrayField,
  removeSectionsFromArrayField,
  setOpeningHours,
  setAutoComplete,
  setqrchecked,
  setqrid,
} = mobilePreviewSlice.actions;

export const uploadFile =
  (file, nameOfFileInput, uploadedFilePath) => async (dispatch) => {
    const blob = URL.createObjectURL(file);

    dispatch(
      setFiles({
        name: nameOfFileInput,
        file: {
          name: file?.name,
          file: `https://stage25.qr-gen.com/${uploadedFilePath}`,
          fileObject: file,
          uploadedFilePath: uploadedFilePath,
        },
      })
    );

    // dispatch(
    //   setFiles({
    //     name: nameOfFileInput,
    //     file: blob,
    //   })
    // )
  };

export const deleteFile = (fileName, nameOfFileInput) => async (dispatch) => {
  console.log("deleteFile", fileName, nameOfFileInput);

  dispatch(
    removeFile({
      nameOfFileInput: nameOfFileInput,
      fileName: fileName,
    })
  );
};

export default mobilePreviewSlice.reducer;
