import { Navigate } from "react-router-dom";

const UnauthenticatedRoute = ({ isAuthenticated, children }) => {

  if (isAuthenticated) {
    // Redirect to the dashboard (or any other route) if authenticated
    return <Navigate to="/dashboard" replace />;
  }

  // Render the children (unauthenticated component) if not authenticated
  return children;
};

export default UnauthenticatedRoute;