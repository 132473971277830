import { useState } from "react";
import "./QRDashboardCard.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../store/slices/MobilePreviewSlice";

const QRDashbaordCard = ({
  icon,
  title,
  description,
  onClick,
  setImageUrl,
  Url,
  cardType,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { qrType } = useSelector((state) => state.root?.MOBILE_PREVIEW_SLICE);
  console.log("yeloooooo", qrType);

  return (
    <div
      className="QRCards bg-[var(--darker-bg)] w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] rounded-lg shadow-md hover:shadow-lg flex flex-col items-center cursor-pointer space-y-2  hover:border-[var(--primary)]/80 min-h-[240px] max-h-[240px] transition-all duration-400 pt-10"
      onClick={() => {
        navigate(onClick);
      }}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => {
        setImageUrl(Url);
        setIsHovered(true);
      }}
    >
      <div
        className={`p-7 bg-[var(--dark-bg)] rounded-lg ${
          isHovered && "border-none"
        } transition-all duration-400`}
      >
        {/* <img src={icon} alt="" /> */}
        <img src={icon} alt="icon" width="40px" />
      </div>
      <div className="flex flex-col mt-1">
        <h3
          className={`text-center font-bold  ${
            isHovered ? "text-[var(--white)]" : "text-[var(--white)]"
          } transition-all duration-400 `}
        >
          {title}
        </h3>
        <p
          className={`text-center text-[12px] w-[200] px-1 sm:w-[200] !font-[300] ${
            isHovered ? "text-white" : "text-[var(--white)]"
          } transition-all duration-400`}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default QRDashbaordCard;
