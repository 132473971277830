import React, { useEffect, useState } from "react";
import MdOutlineKeyboardArrowRight from "../../assets/svg/MdOutlineKeyboardArrowRight.webp";
import Button from "../global/Button";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import header_logo from "../../assets/dashboard-header-logo.webp";
import setting from "../../assets/setting.webp";
import useScreenSize from "../../hooks/useScreenSize";
import MobileSidebar from "../Sidebar/MobileSidebar";
import Qrlogo from "../../assets/QR24Logo.webp";
import { Link, useNavigate, useParams } from "react-router";
import { useValidContext, ValidContext } from "../../context/validCtx";
import SubMenuSetting from "./SubMenuSetting";
import { useFormContext } from "react-hook-form";

const DashboardHeader = ({ progress, logo, bulkprogress }) => {
  const [card, setcard] = useState("");
  const { userTrigeredCard, setUserTrigeredCard } =
    useValidContext(ValidContext);
  const webpath = useParams();
  const second_path = location.pathname.split("/")[2];
  const third_path = location.pathname;
  // console.log('third path', third_path);

  // console.log(webpath)
  const path = location.pathname.split("/").pop();

  const data =
    path === "bulkqrgeneration" ||
    second_path === "qr-design" ||
    third_path === "/upload-csv"
      ? bulkprogress
      : progress;
  // const second_path = location.pathname.split("/")[2];

  // console.log( 'new path', second_path) ;

  useEffect(() => {
    setcard(webpath);
    setUserTrigeredCard(webpath?.id);
  }, [webpath]);

  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const [openMobileHamMenu, setOpenMobileHamMenu] = useState(false);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

  return (
    <div className="bg-[var(--darker-bg)] flex items-center gap-10 justify-between px-8 py-4 sticky top-0 z-11">
      <div
        className="block p-2 cursor-pointer lg:hidden "
        onClick={() => setOpenMobileHamMenu(!openMobileHamMenu)}
      >
        <img src={header_logo} alt="" className="min-h-5 min-w-5" />
      </div>
      <div className="w-[100%]  mx-auto  flex items-center ">
        {
          // progress &&

          data?.map((route, index) => (
            <div
              key={index}
              className={`flex items-center ${
                route.isActive ? "" : "opacity-[0.3] "
              }`}
            >
              {index > 0 && (
                <span>
                  <img
                    src={MdOutlineKeyboardArrowRight}
                    alt="arrow"
                    className="me-1"
                    width="23px"
                  />
                </span>
              )}
              <span className="bg-[var(--primary)] w-6 h-6 rounded-full flex items-center justify-center text-[13px]">
                {index + 1}
              </span>
              {isMobile ? null : (
                <span
                  className={`ml-2 text-[var(--primary)] ${
                    route.isActive && "disabled"
                  }  cursor-pointer whitespace-nowrap font-medium`}
                >
                  {route.title === "Content" ? (
                    <Link to={`/dashboard${card.id && "/" + card.id}`}>
                      {route.title}
                    </Link>
                  ) : route.title === "QR Design" ? (
                    <Link>{route.title}</Link>
                  ) : (
                    <Link to={route.path}>{route.title}</Link>
                  )}
                </span>
              )}
            </div>
          ))
        }
      </div>

      {progress && progress[1].isActive && !isMobile ? (
        <div className="flex items-center gap-4 ">
          <div onClick={() => navigate(-1)}>
            <Button
              className="rounded-lg"
              type={"button"}
              text={"Back"}
              icon={<FaLongArrowAltLeft color="#fff" />}
              width={"127px"}
              height={"46px"}
              textColor={"#fff"}
              borderColor={"#03dac5"}
              borderWidth={"1px"}
              fontSize={"17px"}
              fontWeight={"700"}
              iconOrder={"left"}
              gap={"4px"}
            />
          </div>
          <div
            onClick={() =>
              second_path === "qr-design" &&
              navigate("/bulkqrgeneration/upload-csv")
            }
          >
            <Button
              className="rounded-lg"
              text={
                second_path === "qr-design"
                  ? "Next"
                  : progress[progress.length - 1].isActive
                  ? "Complete"
                  : "Next"
              }
              icon={<FaLongArrowAltRight color="#000" />}
              width={"127px"}
              height={"46px"}
              textColor={"var(--dark-gray-bg)"}
              fontSize={"17px"}
              fontWeight={"700"}
              bgColor={"var(--primary)"}
              iconOrder={"right"}
              gap={"4px"}
              type={"submit"}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {logo && (
        <div className="flex absolute left-0 -z-1 items-center justify-center w-full">
          <img
            src={Qrlogo}
            alt="QR Logo"
            height={40}
            width={40}
            className="mx-auto" // Added for extra centering support
          />
        </div>
      )}

      {/* //Setting Image Added  */}
      {/* <div className="setting w-7">
        <img src={setting} className="w-full cursor-pointer " alt=""
          onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}
        />
        {isOpenSubMenu && <SubMenuSetting />}
      </div> */}
      {openMobileHamMenu && (
        <MobileSidebar
          menuState={openMobileHamMenu}
          stateMenuState={setOpenMobileHamMenu}
        />
      )}
    </div>
  );
};

export default DashboardHeader;
