import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardSkeleton({ baseStyle }) {
  return (
    <div className="w-full">
      {/* Header Section */}
      <div className="p-6 flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2 mb-1">
            <Skeleton
              width={180}
              height={32}
              {...baseStyle}
              className="!rounded-md"
            />
            <div className=" px-3 py-1 rounded-full">
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-full"
              />
            </div>
          </div>
          <Skeleton
            width={400}
            height={20}
            {...baseStyle}
            className="!rounded-md"
          />
        </div>

        {/* Example Text */}
        <div className="text-right">
          <Skeleton
            width={80}
            height={24}
            {...baseStyle}
            className="!rounded-md ml-auto"
          />
        </div>
      </div>

      {/* Main Content with Grid and Mobile Preview */}
      <div className="px-6 flex gap-6">
        {/* Grid of QR Types */}
        <div className="flex-1 grid grid-cols-4 gap-4">
          {[
            { title: "Menu", desc: "This is for menu" },
            { title: "PDF", desc: "This is description" },
            { title: "Website", desc: "Website QR Code" },
            { title: "vCard Plus", desc: "vCard Plus" },
            { title: "Images", desc: "Images" },
            { title: "Video", desc: "Video" },
            { title: "List of links", desc: "List of links" },
            { title: "Business", desc: "Business" },
            { title: "Apps", desc: "Redirect to an app store" },
            { title: "MP3", desc: "Play an audio file" },
            { title: "Coupon", desc: "Share a coupon" },
            { title: "Feedback", desc: "Collect feedback and get rated" },
          ].map((item, i) => (
            <div
              key={i}
              className=" bg-[#1B2C37] rounded-lg shadow-[0_1px_3px_0_rgba(0,0,0,0.1)] p-6"
            >
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 bg-[#1B2C37] rounded-lg shadow-sm p-4 w-14 h-14 flex items-center justify-center">
                  <Skeleton
                    width={32}
                    height={32}
                    {...baseStyle}
                    className="!rounded-md"
                  />
                </div>
                <Skeleton
                  width={80}
                  height={20}
                  {...baseStyle}
                  className="mb-1 !rounded-md"
                />
                <Skeleton
                  width={140}
                  height={16}
                  {...baseStyle}
                  className="!rounded-md"
                />
              </div>
            </div>
          ))}
        </div>

        {/* Mobile Preview Section */}
        <div className="w-[375px] min-h-[760px]  rounded-[40px] border-8 bg-[#1B2C37] border-[#15212b] relative p-4">
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[120px] h-[25px] bg-[#1B2C37] rounded-b-[20px]"></div>
          <div className="h-full bg-[#1B2C37] rounded-[32px] p-6 flex flex-col items-center">
            <Skeleton
              width={200}
              height={28}
              {...baseStyle}
              className="!rounded-md mb-4"
            />
            <Skeleton
              width={280}
              height={32}
              {...baseStyle}
              className="!rounded-md mb-4"
            />
            <Skeleton
              width={240}
              height={20}
              {...baseStyle}
              className="!rounded-md mb-8"
            />
            <div className="w-[140px] h-[40px] border-2  border-[#15212b] rounded-full mb-8">
              <Skeleton height={36} {...baseStyle} className="!rounded-full" />
            </div>
            <div className="w-full aspect-video bg-[#1B2C37] rounded-lg">
              <Skeleton height="100%" {...baseStyle} className="!rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSkeleton;
