// withSkeletonLoader.js
import React, { Suspense, useEffect, useState } from 'react';
import SkeletonLoader from '../../components/global/skeleton/SkeletonLoader';
import { useParams } from 'react-router';

// This is the Higher-Order Component (HOC)
function withSkeletonLoader(Component) {
  return function WrappedComponent(props) {
    const [isPath, setisPath] = useState()
    useEffect(() => {
      const path = window.location.pathname;
      setisPath(path)
      console.log(path); // Example output: "/dashboard"
    }, []);
    return (
      // <SkeletonLoader variant={isPath} >
      //   <Component {...props} />
      // </SkeletonLoader>
      <Suspense fallback={<SkeletonLoader variant={isPath} />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default withSkeletonLoader;