import axiosInstance from "../config/axios.instance";
// Lodash
import { set } from "lodash";

const axiosBaseQuery = () => async (config) => {
  try {
    if (localStorage.getItem("user")) {
      const { accessToken } = JSON.parse(localStorage.getItem("user"));
      axiosInstance.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
      set(config, "headers.Authorization", `Bearer ${accessToken}`);
    }
    const result = await axiosInstance(config);
    console.log(result);
    return {
      data: result?.data,
      meta: result?.meta,
    };
  } catch (axiosError) {
    return {
      error: axiosError?.data,
    };
  }
};

export default axiosBaseQuery;
