import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
// Redux & Redux Toolkit
import { Provider } from "react-redux";
import store from "./store/store.js";
// Context APIs
import { AuthProvider } from "./context/authCtx.jsx";
import { QrProvider } from "./context/qrContext.jsx";
// Senrty
import * as Sentry from "@sentry/react";
import { ValidProvider } from "./context/validCtx.jsx";
import App from "./App.jsx";
import "./index.css";
// import { TransitionProvider } from "./context/Transitioncontext.jsx";

// Init Sentry
Sentry.init({
  dsn: "https://232d1aad89c25ce152b02e62b55bde2c@o4508805142413312.ingest.us.sentry.io/4508805143330816",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <TransitionProvider> */}
    <ValidProvider>
      <AuthProvider>
        <QrProvider>
          <App />
        </QrProvider>
      </AuthProvider>
    </ValidProvider>
    {/* </TransitionProvider> */}
  </Provider>
  // </React.StrictMode>
);
