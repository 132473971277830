import React, { useEffect, useState } from "react";
import { useValidContext, ValidContext } from "../../context/validCtx";

const Button = ({
  data,
  text,
  url,
  icon,
  className,
  onClick,
  iconOrder,
  children,
  type = "button",
  fontWeight,
  borderWidth,
  border,
  bgColor,
  padding,
  paddingVertical,
  paddingHorizontal,
  margin,
  backgroundColor,
  borderRadius,
  color,
  cursor,
  paddingLeft,
  paddingRight,
  iconType = "",
  boxShadow,
  width,
  height,
  display,
  alignItems,
  justifyContent,
  borderStyle,
  borderColor,
  gap,
  fontSize,
  rounded,
  textColor,
  shadow,
  iconWidth,
  fontDisplay,
  nameOfGlobalState = "",
  style = {
    fontWeight: fontWeight,
    borderWidth: borderWidth,
    border: `${borderWidth} ${borderStyle} ${borderColor}`,
    bgColor: bgColor,
    padding: padding,
    margin: margin,
    backgroundColor: bgColor,
    borderRadius: rounded,
    color: textColor,
    cursor: cursor,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    boxShadow: shadow,
    width: width,
    height: height,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: gap ? gap : "2px",
  },
}) => {
  const [load, setLoad] = useState(false);
  const handleButtonLoad = () => {
    setLoad(true);

    setTimeout(() => {
      setLoad(false);
    }, 2000);
  };
  const { isDisableSubmitButton } = useValidContext(ValidContext);

  return (
    <button
      type={type}
      {...(type !== "submit"
        ? {
          onClick: () => {
            handleButtonLoad();
            onClick && onClick();
          },
        }
        : {})}
      className={`${(data?.videobutton || text || icon) && className
        } hover:cursor-pointer ${load &&  "disabled:opacity-40"
        } ${load && `disabled`} ${className}`}
      // disabled={isDisableSubmitButton}
      disabled={load}
      style={style}
    >
      <a href={url}>
        {/* <div className="h-4 w-4 rounded-full border-2 border-[var(--primary)] border-t-[var(--dark-bg)] animate-spin"></div> */}
        <div className="flex gap-2 h-5 justify-center items-center ">
          {iconOrder === "left" && iconType !== "image" && icon && (
            <span>{icon}</span>
          )}
          {iconType == "image" && (
            <img src={icon} alt="icon" width={iconWidth} />
          )}
          {text ? (
            <p
              style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                color: textColor,
                display: fontDisplay,
              }}
            >
              {text}
            </p>
          ) : (
            (nameOfGlobalState || text) && (
              <p
                style={{
                  fontSize: fontSize,
                  fontWeight: fontWeight,
                  color: textColor,
                  display: fontDisplay,
                }}
              >
                {data && nameOfGlobalState ? data[nameOfGlobalState] : text}
              </p>
            )
          )}
          {children}
          {iconOrder === "right" && iconType !== "image" && icon && (
            <span>{icon}</span>
          )}
        </div>
      </a>
    </button>
  );
};

export default Button;
