import { authApi } from "../services/auth";
import { contactApi } from "../services/contact";
import { dashboardApi } from "../services/dashboard.js";
import { myDomainApi } from "../services/domains";
import { favoritesApi } from "../services/favorites";
import { folderApi } from "../services/folder";
import { metaDataApi } from "../services/metadata";
import { myQrCodeApi } from "../services/myqrcodes";
import { newQrCodeApi } from "../services/newqrcode";
import { plansApi } from "../services/plans";
import { productApi } from "../services/products";
import { profileApi } from "../services/profile";
import { qrApi } from "../services/qr.js";
import { qrDomainsApi } from "../services/qrDomain.js";
import { resourcesApi } from "../services/resources";
import { resourceByIdApi } from "../services/resourcesbyid";
import { settingsApi } from "../services/settings";
import { statsApi } from "../services/stats";
import { uploadApi } from "../services/upload";
// Init Middlewares
const storeMiddlewares = [
  authApi.middleware,
  contactApi.middleware,
  favoritesApi.middleware,
  uploadApi.middleware,
  folderApi.middleware,
  qrApi.middleware,
  metaDataApi.middleware,
  myDomainApi.middleware,
  myQrCodeApi.middleware,
  newQrCodeApi.middleware,
  plansApi.middleware,
  productApi.middleware,
  profileApi.middleware,
  resourcesApi.middleware,
  resourceByIdApi.middleware,
  settingsApi.middleware,
  statsApi.middleware,
  dashboardApi.middleware,
  qrDomainsApi.middleware,
];

export default storeMiddlewares;
