import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ApiSkeleton({ baseStyle }) {
  return (
    <div className="w-full h-screen bg-[#0D1B2A] flex flex-col items-center">
      {/* Hero Section */}
      <div className="w-full flex flex-col items-center mt-10">
        <Skeleton {...baseStyle} width={300} height={40} />
        <Skeleton {...baseStyle} width={400} height={20} />
        <div className="mt-8 w-full flex justify-center">
          <Skeleton {...baseStyle} width={200} height={40} />
        </div>
      </div>

      {/* QR Code Generation Section */}
      <div className="w-full flex justify-center items-center mt-10">
        <div className="w-1/3 flex flex-col items-center">
          <Skeleton {...baseStyle} width={80} height={80} />
          <Skeleton {...baseStyle} width={100} height={20} />
          <Skeleton {...baseStyle} width={250} height={15} />
        </div>
        <div className="w-1/3 flex flex-col items-center">
          <Skeleton {...baseStyle} width={250} height={250} />
          <Skeleton {...baseStyle} width={200} height={40} />
        </div>
        <div className="w-1/3 flex flex-col items-center">
          <Skeleton {...baseStyle} width={80} height={80} />
          <Skeleton {...baseStyle} width={100} height={20} />
          <Skeleton {...baseStyle} width={250} height={15} />
        </div>
      </div>

      {/* Feature Section */}
      <div className="w-full flex flex-wrap justify-center gap-8 mt-12">
        {Array(6)
          .fill()
          .map((_, index) => (
            <div key={index} className="w-1/5 flex flex-col items-center">
              <Skeleton {...baseStyle} width={80} height={80} />
              <Skeleton {...baseStyle} width={120} height={20} />
              <Skeleton {...baseStyle} width={200} height={15} />
            </div>
          ))}
      </div>

      {/* Pricing Section */}
      <div className="w-full flex justify-center gap-6 mt-12">
        {Array(3)
          .fill()
          .map((_, index) => (
            <div
              key={index}
              className="w-1/4 flex flex-col items-center border border-gray-700 p-6 rounded-md"
            >
              <Skeleton {...baseStyle} width={150} height={25} />
              <Skeleton {...baseStyle} width={80} height={30} />
              <Skeleton {...baseStyle} width={200} height={15} />
              <Skeleton {...baseStyle} width={180} height={15} />
              <Skeleton {...baseStyle} width={180} height={15} />
              <Skeleton {...baseStyle} width={180} height={15} />
              <Skeleton {...baseStyle} width={180} height={15} />
              <Skeleton {...baseStyle} width={120} height={35} />
            </div>
          ))}
      </div>

      {/* Footer Skeleton */}
      <div className="w-full h-20 flex justify-center items-center mt-16 bg-[#0D1B2A]">
        <Skeleton {...baseStyle} width={300} height={20} />
      </div>
    </div>
  );
}

export default ApiSkeleton;
