import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function MyDomainSkeleton({ baseStyle }) {
  return (
    <div className="p-6">
      {/* Empty State */}
      <div className="flex flex-col items-center justify-center py-20">
        <div className="w-32 h-32  bg-[#1B2C37] rounded-full flex items-center justify-center mb-4">
          <Skeleton
            width={48}
            height={48}
            {...baseStyle}
            className="!rounded-md"
          />
        </div>
        <Skeleton
          width={160}
          height={24}
          {...baseStyle}
          className="!rounded-md"
        />
      </div>

      {/* FAQs Section */}
      <div className="mt-12">
        <Skeleton
          width={80}
          height={32}
          {...baseStyle}
          className="!rounded-md mb-6"
        />

        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className=" rounded-lg border p-4 bg-[#1B2C37] border-[#15212b]"
            >
              <div className="flex justify-between items-center">
                <Skeleton
                  width={400}
                  height={24}
                  {...baseStyle}
                  className="!rounded-md"
                />
                <Skeleton
                  width={24}
                  height={24}
                  {...baseStyle}
                  className="!rounded-md"
                />
              </div>
              {i === 0 && (
                <div className="mt-4">
                  <Skeleton
                    count={2}
                    height={20}
                    className="!rounded-md"
                    {...baseStyle}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyDomainSkeleton;
