import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function LoginSkeleton({ baseStyle }) {
  return (
    <div className="w-full flex">
      {/* Left Section: Login Form */}
      <div className="w-1/3 h-screen bg-[#0D1B2A] flex flex-col justify-center items-center p-6">
        {/* Logo */}
        <Skeleton {...baseStyle} width={100} height={40} className="mb-4" />
        <Skeleton {...baseStyle} width={80} height={15} className="mb-6" />

        {/* Social Login Buttons */}
        <div className="w-full flex flex-col items-center space-y-2">
          <Skeleton {...baseStyle} width={250} height={40} />
          <Skeleton {...baseStyle} width={250} height={40} />
        </div>

        <Skeleton {...baseStyle} width={250} height={20} className="my-4" />

        {/* Email and Password Fields */}
        <Skeleton {...baseStyle} width={250} height={40} className="mb-3" />
        <Skeleton {...baseStyle} width={250} height={40} className="mb-3" />

        {/* Remember Me and Forgot Password */}
        <div className="w-full flex justify-between px-6 my-2">
          <Skeleton {...baseStyle} width={120} height={15} />
          <Skeleton {...baseStyle} width={80} height={15} />
        </div>

        {/* Cloudflare Captcha Placeholder */}
        <Skeleton {...baseStyle} width={250} height={60} className="my-4" />

        {/* Sign In Button */}
        <Skeleton {...baseStyle} width={250} height={40} className="mb-3" />

        {/* New Account Link */}
        <Skeleton {...baseStyle} width={200} height={15} />
      </div>

      {/* Right Section: Illustration */}
      <div className="w-2/3 h-screen flex justify-center items-center">
        <Skeleton {...baseStyle} width="80%" height="70%" />
      </div>
    </div>
  );
}

export default LoginSkeleton;
