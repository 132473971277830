import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function BulkQrGenerationSkeleton({ baseStyle }) {
  return (
    <div className="min-h-screen bg-[#1B2C37]">
      <div className="bg-[#1E2A36] shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center space-x-8">
          {/* Step 1 */}

          <div className="flex items-center">
            <Skeleton circle width={24} height={24} {...baseStyle} />
            <Skeleton width={100} height={18} className="ml-2" {...baseStyle} />
          </div>

          {/* Step 2 */}
          <div className="flex items-center opacity-50">
            <Skeleton circle width={24} height={24} {...baseStyle} />
            <Skeleton width={70} height={18} className="ml-2" {...baseStyle} />
          </div>

          {/* Step 3 */}
          <div className="flex items-center opacity-50">
            <Skeleton circle width={24} height={24} {...baseStyle} />
            <Skeleton width={85} height={18} className="ml-2" {...baseStyle} />
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex w-full  px-4 py-8">
        <SidebarSkeleton />
        <div className="flex flex-wrap w-full lg:flex-nowrap gap-8">
          {/* Main Content Area - 70% */}
          <div className="w-full lg:w-[70%] space-y-6">
            {/* Title Area */}
            <div className="flex items-center gap-2">
              <Skeleton width={200} height={28} {...baseStyle} />
              <Skeleton width={90} height={24} {...baseStyle} />
            </div>

            {/* Description */}
            <Skeleton width={300} height={20} {...baseStyle} />

            {/* Cards Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
              {[1, 2, 3, 4, 5, 6].map((_, idx) => (
                <div key={idx} className="p-4">
                  <Skeleton height={180} {...baseStyle} className="mb-3" />
                  <Skeleton width={120} height={20} {...baseStyle} />
                </div>
              ))}
            </div>
          </div>

          {/* Preview Area - 30% */}
          <div className="w-full lg:w-[30%]">
            <div className="sticky top-4">
              <Skeleton height={500} {...baseStyle} className="rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkQrGenerationSkeleton;
