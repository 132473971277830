const BASE_URL = "https://stage-admin.qr-gen.com/api/"; //"https://stage.a2charge.com/"; //import.meta.env.VITE_APP_API_BASE_URL ||;
const IMAGE_BASE_URL = "https://stage-admin.qr-gen.com/uploads/";
const IMAGE_BASE_URL_WU = "https://stage-admin.qr-gen.com/";

import dayjs from "dayjs";
import LevelHImage from "./../../src/assets/images/qr-corrections/levelHImage-ChYiNhEg.webp";
import LevelLImage from "./../../src/assets/images/qr-corrections/levelLImage-CE1X60nT.webp";
import LevelMImage from "./../../src/assets/images/qr-corrections/levelMImage-DTPCQezT.webp";
import LevelQImage from "./../../src/assets/images/qr-corrections/levelQImage-DWQQ9sMv.webp";

const API_METHODS = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
};

const DEFAULT_ERROR_MESSAGE = "Something went wrong, please try again later";

const QUERY_NAMES = {
    PRODUCTS: ["products"],
    GET_META_DATA: ["getMetadata"],
    LOGIN: ["login"],
    SIGN_UP: ["registerUser"],
    LOGOUT: ["logout"],
    RESET_PASSWORD: ["updatePassword"],
    FORGOT_PASSWORD: ["forgotPassword"],
    PROFILE: ["profile"],
    CONTACT_SEND: ["contactSend"],
    SEND_MAIL_QUERY: ["sendMailQuery"],
    GET_QR_RESOURCES_DATA: ["qrresources"],
    GET_QR_RESOURCES_DATA_BY_ID: ["qrResourcesById"],
    GET_QRCODE_BY_ID: ["qrCodeById"],
    GET_QRCODE_BY_URL: ["qrCodeById"],
    GET_QRCODE_SCAN_LOG: ["qrCodeScanLog"],
    GET_QR_LOGO_DATA: ["qr-logos"],
    GET_QR_FRAME_DATA: ["qr-frames"],
    GET_QR_SHAPE_DATA: ["qr-styles"],
    GET_QR_LEVEL_DATA: ["qr-levels"],
    GET_QR_TEMPLATES_DATA: ["qr-templates"],
    GET_GENERATED_INTENT_DATA: ["genarate-intent"],
    GET_GENERATED_APIKEY_DATA: ["genarate-apikey"],
    GET_PRICE_BY_COUNTRY_DATA: ["get-price-by-country"],
    NEW_SUBSCRIPTION: ["new-subscription"],
    GET_QR_TEMPLATE_DETAILS: ["qr-template-details"],
    MY_QR_CODE: ["myQrcode"],
    QR_BY_URL: ["qrByURl"],
    QR_CODE_STORE: ["qrCodeStore"],
    TEMPLATE_STORE: ["templateStore"],
    TEMPLATE_EDIT: ["templateEdit"],
    TEMPLATE_DELETE: ["templateDelete"],
    QR_CODE_UPDATE: ["qrCodeUpdate"],
    NEW_QR_CODE: ["newQrCode"],
    ADD_FAVORITES: ["addFavorites"],
    REMOVE_FAVORITES: ["removeFromFavorites"],
    DUPLICATE: ["duplicate"],
    FOLDERS: ["folders"],
    STATS: ["stats"],
    PLANS: ["plans"],
    SUBSCRIPTIONS: ["subscriptions"],
    DOMAIN_STORE: ["domainStore"],
    GET_DOMAIN_DATA: ["domain"],
    GET_FOLDER_DATA: ["folder"],
    FOLDER_STORE: ["folderStore"],
    VALIDATE_URL: ["validateURL"],
    UPLOAD_FILE: ["uploadFile"],
    UPLOAD_IMAGE: ["uploadImage"],
    UPDATE_PROFILE: ["updateProfile"],
    UPDATE_TEXT_FILED: ["updateTextField"],
};

const ANT_THEME_CONFIG = {};

const QUERY_CONFIG = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: 0,
            staleTime: 5 * 1000,
        },
    },
};

const META_DATA_PAGES = {
    INDEX: "home",
    STATIC_QR_CODE: "staticQrCode",
    FAQ: "faq",
    DYNAMICQR: "dynamicQrCode",
    GENERALDATAPROTECTION: "generalDataProtection",
    TYPEOFQRCODE: "typeofQrCode",
    QRCODEFOR: "qrCodesFor",
    RESTAURANT: "restaurants",
    GYMANDWELLNESS: "gymandWellness",
    CONSUMERGOODSPACKAGES: "consumergoodspackaged",
    NONPROFITORGANIZATION: "nonProfitOrganization",
    RETAILERS: "retailers",
    SOWFWEARGAMEDEVELOPER: "softwareGameDevelopers",
    EDITORS: "editors",
    BUSINESSSERVICES: "businessServices",
    HOTELSRESTAURANT: "hotelsandResorts",
    GOVERMENTS: "government",
    EDUCATIONALINSTITUTIONS: "educationalInstitutions",
    REALESTATEAGENCIES: "realEstateagencies",
    MEDIACAL: "mediCalcaredoctors",
    TORISUM: "tourism",
    PERSONALUSE: "personaluse",
    CITIESCULTURES: "citiesandCultures",
    MUSICADNARTISTIS: "musiciansandartists",
    PHOTOGRAPHI: "photographersandvideographers",
    HOMEMAINTANCE: "repairshomeMaintenance",
    FINANCEADNINSURANCE: "financialandinsuranceCompanies",
    EVEMENTMANAGMENT: "eventmanagement",
    BUSINESSCONSULTANTS: "businessconsultants",
    MUSUMEGALARIES: "museumsandgalaries",
    GRAPHICDESIGNSTUDO: "graphicDesignStudio",
    CONSUMERELECTRONICS: "consumerelectronics",
    CONVERSTIONCENTERS: "stadiumsconventioncenters",
    MEDIACENTER: "mediaandentertainment",
    BAVERAGEINDUSTRY: "beverageIndustry",
    AIRLINES: "airlines",
    QRCODEON: "qrcodesOn",
    PLANSPRICING: "plansPricing",
    API: "api",
    PRODUCT: "product",
    BENIFITS: "benefits",
    WHERTOUSE: "wheretoUse",
    AFFILIATEPROGRAM: "affiliateProgram",
    PAYMENTCONTENT: "paymentContent",
    PRICING: "pricing",
    REVIEW: "reviews",
    ABOUTUS: "aboutUs",
    ACCOUNTPAYMENT: "accountPayment",
    PRIVACYPOLICY: "privacyPolicy",
    TERMSCONDITION: "termsConditions",
    INSTRUCTIONS: "instructions",
    BLOG: "blog",
    HELPCENTER: "helpCenter",
};

const DEFAULT_META_DATA = {
    TITLE: "Free QR Code Generator | Create Custom QR Codes Online",
    KEYWORDS:
        "QR code generator, free QR codes, custom QR codes, dynamic QR codes, static QR codes, QR code creator, create QR code, online QR code generator",
    DESCRIPTION:
        "Generate free custom QR codes for websites, social media, events, and more. Create dynamic and static QR codes with logos, colors, and designs in just a few clicks.",
};

const BUTTON_DATA = [
    {
        // icon: LinkIcon,
        name: "Link 1",
    },
];

const FONTS_OPTIONS = [
    { value: "Jaro", label: "Jaro" },
    { value: "Roboto", label: "Roboto" },
    { value: "OpenSans", label: "Open Sans" },
];
const FONT_SIZE_OPTIONS = [
    { value: "8px", label: "8px" },
    { value: "10px", label: "10px" },
    { value: "12px", label: "12px" },
    { value: "14px", label: "14px" },
    { value: "16px", label: "16px" },
    { value: "18px", label: "18px" },
    { value: "20px", label: "20px" },
    { value: "22px", label: "22px" },
    { value: "24px", label: "24px" },
    { value: "26px", label: "26px" },
    { value: "28px", label: "28px" },
    { value: "30px", label: "30px" },
    { value: "32px", label: "32px" },
    { value: "36px", label: "36px" },
    { value: "40px", label: "40px" },
];


const MAX_FILE_SIZE_MB = 10;
const MAX_QR_MESSAGE_SIZE = 130;

const BORDER_STYLES = [
    { "rounded-full": [50, 50, 50, 50] },
    { "rounded-md": [10, 10, 10, 10] },
    { "rounded-tr-xl rounded-bl-xl rounded-br-xl": [0, 40, 40, 40] },
    { "rounded-tr-xl rounded-bl-xl": [0, 40, 0, 40] },
    { "rounded-tr-xl rounded-br-xl": [0, 40, 40, 0] },
    { "rounded-tl-full rounded-br-md": [100, 0, 0, 0] },
    { "rounded-bl-full rounded-tr-md": [0, 0, 0, 100] },
    { "rounded-tl-lg rounded-br-lg": [20, 0, 20, 0] },
    { "rounded-bl-lg rounded-tr-lg": [0, 20, 0, 20] },
    { "rounded-tr-2xl rounded-bl-xl": [0, 40, 0, 40] },
];
const CENTER_STYLES = [
    { "rounded-full": [50, 50, 50, 50] },
    { "rounded-md": [10, 10, 10, 10] },
    { "rounded-tr-xl rounded-bl-xl rounded-br-xl": [0, 40, 40, 40] },
    { "rounded-tr-xl rounded-bl-xl": [0, 40, 0, 40] },
    { "rounded-tr-xl rounded-br-xl": [0, 40, 40, 0] },
    { "rounded-tl-full rounded-br-md": [100, 0, 0, 0] },
    { "rounded-bl-full rounded-tr-md": [0, 0, 0, 100] },
    { "rounded-tl-lg rounded-br-lg": [20, 0, 20, 0] },
    { "rounded-bl-lg rounded-tr-lg": [0, 20, 0, 20] },
    { "rounded-tr-2xl rounded-bl-xl": [0, 40, 0, 40] },
];

export const LEVEL_DATA = [
    {
        id: 1,
        image: LevelQImage,
        title: "Level Q",
        number: 25,
        value: "Q",
    },
    {
        id: 2,
        image: LevelHImage,
        title: "Level H",
        number: 15,
        value: "H",
    },
    {
        id: 3,
        image: LevelMImage,
        title: "Level M",
        number: 35,
        value: "M",
    },
    {
        id: 4,
        image: LevelLImage,
        title: "Level L",
        number: 7,
        value: "L",
    },
];

export const DEFAULT_COLOR = [
    {
        color: "rgb(16, 142, 233)",
        percent: 0,
    },
    {
        color: "rgb(135, 208, 104)",
        percent: 100,
    },
];

const INITIAL_STATE_QR_CONTENT = {
    name: "",
    folder: "",
    domain: "",
    accessPassword: "",
    activePassword: false,
    generateUri: true,
    uri: "",
    scanLimit: "",
    allowScanLimit: false,
    scheduleFrom: "",
    scheduleTo: "",
    allowSchedule: false,
    googleAnalyticsId: "",
    facebookPixelId: "",
    googleTagManagerId: "",
    trackEvents: false,
    preview: "",
    previewTime: "",
    lastName: "",
    photo: "",
    phones: [],
    emails: [],
    websites: [],
    images: [],
    facilities: [],
    socialsTitle: "",
    socials: [],
    gtin: "",
    address: {
        data: {
            city: "",
            country: "",
            number: "",
            postalCode: "",
            state: "",
            street: "",
            url: "",
            latitude: "",
            longitude: "",

        }
    },
    timeScheduling: {
        enabled: false,
        ranges: [],
    },
    // design: {
    //     primary: COLOR_PALLETES_DATA[0].colors[2],
    // },
    pdfs: [],
    phoneText: "",
    emailText: "",
    locationText: "",
    contactText: "add contact",
    addContactAtTop: false,
    floatingButton: false,
    contactStorageOption: "",
    fonts: {
        text: null,
        title: null,
    },
    // fontsColor: {
    //     text: COLOR_PALLETES_DATA[0].colors[1],
    //     title: COLOR_PALLETES_DATA[0].colors[0],
    // },
    style: {
        shape: {
            backgroundColor: "#ffffff",
            color: "#000000",
            style: "square",
        },
        corners: {
            dotColor: "#000000",
            squareColor: "#000000",
            squareStyle: "default",
            dotStyle: "default",
        },
        frame: {
            id: null,
            color: "#000000",
            text: "Scan me!",
            fontSize: 20,
            backgroundColor: "#ffffff",
            frameColor: "#000000",
        },
        errorCorrectionLevel: "Q",
        logoPreset: null,
    },
    pageTemplateId: 0,
    startTime: dayjs("08:00 am", "hh:mm a"),
    endTime: dayjs("08:00 pm", "hh:mm a"),
    frameId: null,
};

const INITIAL_STATE_QR_UPLOAD_CONTENT = {
    index: 0,
    selectedTemplateId: 1,
    // titleColor: COLOR_PALLETES_DATA[0].colors[0],
    textsColor: null,
    // bgColor: COLOR_PALLETES_DATA[0].colors[1],
    titleFont: null,
    textsFont: null,
    navbarColor: "#000000",
    company: null,
    pdfTitle: null,
    pdfDescription: null,
    profileTitle: null,
    profileDescription: null,
    pdfCoverImage: null,
    website: null,
    text: "",
    uploadedPDFs: [],
    showPDFDirectly: false,
    welcomeScreen: {
        welcomeScreenImg: null,
        welcomeScreenTime: 2.5,
    },
    gtin: "",
    price: "",
    badge: "",
    couponDiscountPercentage: "",
    couponCode: "",
    image: "",
    mp3: "",
    evaluations: {
        allowReviews: true,
        onlyPositiveReviews: false,
        showRatingsInMenu: false,
        sortByTopRated: false
    },
    assessmentType: null,
    links: [],
    categories: [],
    comments: false,
    additionalInformation: "",
    searchAddress: "",
    locationTab: "",
    colorPaletteKey: 0,
    previewTime: "",
    couponDate: "",
    couponTermsAndConditions: "",
    description: "",
    showCodeButton: "",
    images: [],
    audios: [],
    viewType: 'v',
    socialNetworksTitle: null,
    socialNetworks: [],
    buttons: [],
    videos: [],
    ytVideos: [],
    aboutUs: "",
    showVideosDirectly: false,
    eventDate: null,
    shareButton: false,
    calendarText: "",
    title: "",
    coverImage: null,
    bgImage: null,
    name: "",
    email: "",
    openingHours: [
        { day: "Monday", enabled: false, slots: [] },
        { day: "Tuesday", enabled: false, slots: [] },
        { day: "Wednesday", enabled: false, slots: [] },
        { day: "Thursday", enabled: false, slots: [] },
        { day: "Friday", enabled: false, slots: [] },
        { day: "Saturday", enabled: false, slots: [] },
        { day: "Sunday", enabled: false, slots: [] },
    ],
    nutritionals: [],
    mandatoryEmail: false,
    reviewByEmail: false,
    mandatoryPhone: false,
    ingredients: [],
    allergens: [],
    certificates: [],
    organic: [],
    responsibleConsumption: [],
    recyclingStamps: [],
    feedbackCategories: [],
    sections: [],
    qrImage: null,
    menuItems: [],
    editCardInfo: null,
    contactText: 'add contact'
};

const FirebaseConfig = {
    apiKey: "AIzaSyAgWAyW9voJFkGNSIFs2DVRLBL7nl7yQEM",
    authDomain: "qr-gen-a235f.firebaseapp.com",
    projectId: "qr-gen-a235f",
    storageBucket: "qr-gen-a235f.firebasestorage.app",
    messagingSenderId: "15585916843",
    appId: "1:15585916843:web:5781f476618474d9b7c4ee",
    measurementId: "G-GG6Q5LSXPT",
};

const GOOGLE_CLIENT_ID = "566737031331-a4p8m3eve9p4pd6va75rr0oqdovivpj1.apps.googleusercontent.com"

const QR_TYPES = {
    STATIC: "static",
    DYNAMIC: "dynamic",
};

const NEW_QR_STEPS = {
    TYPE_OF_QR: 1,
    CONTENT: 2,
    QR_DESIGN: 3,
};
export {
    BASE_URL,
    IMAGE_BASE_URL,
    IMAGE_BASE_URL_WU,
    API_METHODS,
    DEFAULT_ERROR_MESSAGE,
    QUERY_NAMES,
    ANT_THEME_CONFIG,
    QUERY_CONFIG,
    BUTTON_DATA,
    DEFAULT_META_DATA,
    META_DATA_PAGES,
    FONTS_OPTIONS,
    MAX_FILE_SIZE_MB,
    BORDER_STYLES,
    CENTER_STYLES,
    INITIAL_STATE_QR_CONTENT,
    INITIAL_STATE_QR_UPLOAD_CONTENT,
    FirebaseConfig,
    QR_TYPES,
    NEW_QR_STEPS,
    FONT_SIZE_OPTIONS,
    // CONTENT,
    MAX_QR_MESSAGE_SIZE,
    // API_URLS,
    GOOGLE_CLIENT_ID
};
