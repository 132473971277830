import { countries } from "countries-list";
import countryList, { getCode } from "country-list";

const getCountryName = () => {
    return countryList.getNames();
}

const getCountryCode = (countryName) => {
    const response = getCode(countryName);
    return response;
}

const getCountryCurrency = (countryName) => {
    const countryCode = getCode(countryName);
    if (!countryCode) return null;
    const countryData = countries[countryCode];
    return countryData?.currency[0];
}


export { getCountryName, getCountryCode, getCountryCurrency };
