import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function BlogSkeleton({ baseStyle }) {
  return (
    <div className="w-full flex flex-col items-center py-10">
      {/* Title Section */}
      <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

      {/* Subtitle Section */}
      <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

      {/* Filter Tabs */}
      <div className="w-full flex justify-center space-x-6 mb-8">
        <Skeleton
          {...baseStyle}
          width={100}
          height={30}
          className="rounded-md"
        />
        <Skeleton
          {...baseStyle}
          width={100}
          height={30}
          className="rounded-md"
        />
        <Skeleton
          {...baseStyle}
          width={100}
          height={30}
          className="rounded-md"
        />
      </div>

      {/* Search Section */}
      <div className="w-full flex justify-center mb-8">
        <Skeleton
          {...baseStyle}
          width={300}
          height={40}
          className="rounded-md"
        />
      </div>

      {/* Blog Cards Section */}
      <div className="w-full grid grid-cols-3 gap-8">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="flex flex-col items-center space-y-3">
            <Skeleton
              {...baseStyle}
              width={250}
              height={200}
              borderRadius={10}
            />
            <Skeleton {...baseStyle} width={200} height={20} />
            <Skeleton {...baseStyle} width={150} height={15} />
            <Skeleton {...baseStyle} width={120} height={20} />
            <Skeleton {...baseStyle} width={100} height={20} />
          </div>
        ))}
      </div>

      {/* Sidebar Section */}
      <div className="w-full mt-10 flex flex-col items-center space-y-6">
        <Skeleton {...baseStyle} width={200} height={30} />
        <Skeleton {...baseStyle} width={200} height={20} />
        <Skeleton {...baseStyle} width={200} height={20} />
        <Skeleton {...baseStyle} width={200} height={20} />
      </div>
    </div>
  );
}

export default BlogSkeleton;
