import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DynamicDashboardSkeleton({ baseStyle }) {
  return (
    <div className="w-full bg-gray-900 text-white p-6">
      <div className="flex flex-row gap-8 py-10">
        {/* Left Section - Form Fields */}
        <div className="w-4/5">
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <Skeleton {...baseStyle} width={300} height={32} />
            <div className="flex items-center">
              <Skeleton
                {...baseStyle}
                width={100}
                height={28}
                className="mr-2"
              />
              <Skeleton
                {...baseStyle}
                width={24}
                height={24}
                className="rounded-sm"
              />
            </div>
          </div>

          {/* QR Name Input */}
          <div className="mb-4">
            <Skeleton
              {...baseStyle}
              width="100%"
              height={48}
              className="rounded-md mb-2"
            />
            <div className="flex items-center">
              <Skeleton
                {...baseStyle}
                width={20}
                height={20}
                className="rounded-full mr-2"
              />
              <Skeleton {...baseStyle} width={150} height={20} />
              <Skeleton
                {...baseStyle}
                width={20}
                height={20}
                className="rounded-full ml-2"
              />
            </div>
          </div>

          {/* Accordion Sections */}
          {/* Appearance Section */}
          <div className="bg-[#1B2C37] rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Skeleton
                  {...baseStyle}
                  width={24}
                  height={24}
                  className="mr-3"
                />
                <div>
                  <Skeleton
                    {...baseStyle}
                    width={120}
                    height={24}
                    className="mb-1"
                  />
                  <Skeleton {...baseStyle} width={200} height={16} />
                </div>
              </div>
              <Skeleton {...baseStyle} width={24} height={24} />
            </div>
          </div>

          {/* Basic Information Section */}
          <div className="bg-[#1B2C37] rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Skeleton
                  {...baseStyle}
                  width={24}
                  height={24}
                  className="mr-3 rounded-full"
                />
                <div>
                  <Skeleton
                    {...baseStyle}
                    width={150}
                    height={24}
                    className="mb-1"
                  />
                  <Skeleton {...baseStyle} width={180} height={16} />
                </div>
              </div>
              <Skeleton {...baseStyle} width={24} height={24} />
            </div>
          </div>

          {/* Content Section */}
          <div className="bg-[#1B2C37] rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Skeleton
                  {...baseStyle}
                  width={24}
                  height={24}
                  className="mr-3 rounded-full"
                />
                <div>
                  <Skeleton
                    {...baseStyle}
                    width={100}
                    height={24}
                    className="mb-1"
                  />
                  <Skeleton {...baseStyle} width={220} height={16} />
                </div>
              </div>
              <Skeleton {...baseStyle} width={24} height={24} />
            </div>
          </div>

          {/* Stats Section */}
          <div className="bg-[#1B2C37] rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Skeleton
                  {...baseStyle}
                  width={24}
                  height={24}
                  className="mr-3"
                />
                <div>
                  <Skeleton
                    {...baseStyle}
                    width={80}
                    height={24}
                    className="mb-1"
                  />
                  <Skeleton {...baseStyle} width={260} height={16} />
                </div>
              </div>
              <Skeleton {...baseStyle} width={24} height={24} />
            </div>
          </div>

          {/* Configuration Section */}
          <div className="bg-[#1B2C37] rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Skeleton
                  {...baseStyle}
                  width={24}
                  height={24}
                  className="mr-3"
                />
                <div>
                  <Skeleton
                    {...baseStyle}
                    width={130}
                    height={24}
                    className="mb-1"
                  />
                  <Skeleton {...baseStyle} width={280} height={16} />
                </div>
              </div>
              <Skeleton {...baseStyle} width={24} height={24} />
            </div>
          </div>
        </div>

        {/* Right Section - Phone Preview */}
        <div className="w-[375px] min-h-[760px]  rounded-[40px] border-8 bg-[#1B2C37] border-[#15212b] relative p-4">
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[120px] h-[25px] bg-[#1B2C37] rounded-b-[20px]"></div>
          <div className="h-full bg-[#1B2C37] rounded-[32px] p-6 flex flex-col items-center">
            <Skeleton
              width={200}
              height={28}
              {...baseStyle}
              className="!rounded-md mb-4"
            />
            <Skeleton
              width={280}
              height={32}
              {...baseStyle}
              className="!rounded-md mb-4"
            />
            <Skeleton
              width={240}
              height={20}
              {...baseStyle}
              className="!rounded-md mb-8"
            />
            <div className="w-[140px] h-[40px] border-2  border-[#15212b] rounded-full mb-8">
              <Skeleton height={36} {...baseStyle} className="!rounded-full" />
            </div>
            <div className="w-full aspect-video bg-[#1B2C37] rounded-lg">
              <Skeleton height="100%" {...baseStyle} className="!rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicDashboardSkeleton;
