import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getQrFrameData
 * @description API call to fetch QR frame data
 *
 * @param payload: object
 */
const getQrFrameData = (builder) => {
  return builder.mutation({
    query() {
      return {
        url: `/qr-frames`,
        method: "GET",
      };
    },
    providesTags: [STORE.TAGS.QR_TAG],
  });
};

/*
 * @private
 * @name getQrTemplatesData
 * @description API call to fetch QR templates data
 *
 * @param payload: object
 */
const getQrTemplatesData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-templates`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getOneQrTemplateData
 * @description API call to fetch details of one QR template
 *
 * @param payload: object
 */
const getOneQrTemplateData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-template-get-by-id`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getQrLogoData
 * @description API call to fetch QR logo data
 *
 * @param payload: object
 */
const getQrLogoData = (builder) => {
  return builder.query({
    query() {
      return {
        url: `/qr-logos`,
        method: "GET",
      };
    },
  });
};

/*
 * @private
 * @name getQrShapeData
 * @description API call to fetch QR shape data
 *
 * @param payload: object
 */
const getQrShapeData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-styles`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getQrLevelData
 * @description API call to fetch QR level data
 *
 * @param payload: object
 */
const getQrLevelData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-levels`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name qrCodeStoreData
 * @description API call to store QR code data
 *
 * @param payload: object
 */
const qrCodeStoreData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-code/store`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name templateStoreData
 * @description API call to store template data
 *
 * @param payload: object
 */
const templateStoreData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-template-create`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name createQrTemplate
 * @description API call to store template data
 *
 * @param payload: object
 */
const createQrTemplate = (builder) => {
  console.log("createQrTemplate");
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/create`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name templateEditData
 * @description API call to edit template data
 *
 * @param payload: object
 */
const templateEditData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-template-update`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name templateDeleteData
 * @description API call to delete template data
 *
 * @param payload: object
 */
const templateDeleteData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/templates/delete?id=${payload?.id}`,
        method: "Delete",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name qrCodeUpdateData
 * @description API call to update QR code data
 *
 * @param payload: object
 */
const qrCodeUpdateData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-code/update`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getFolderData
 * @description API call to fetch folder data
 *
 * @param payload: object
 */
const getFolderData = (builder) => {
  return builder.query({
    query(payload) {
      return {
        url: `/qr-folders`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// ----------------------------------------------------------get api
/*
 * @private
 * @name getFolderData
 * @description API call to fetch folder data
 *
 * @param payload: object
 */
const getQrTemplate = (builder) => {
  return builder.query({
    query(user_id) {
      return {
        url: `/templates/list?id=${user_id}`,
        method: "GET",
      };
    },
  });
};

// Create API slice
const qrApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.QR_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [STORE.TAGS.QR_TAG],
  endpoints: (builder) => ({
    getQrFrameData: getQrFrameData(builder),
    getQrTemplatesData: getQrTemplatesData(builder),
    getOneQrTemplateData: getOneQrTemplateData(builder),
    getQrLogoData: getQrLogoData(builder),
    getQrShapeData: getQrShapeData(builder),
    getQrLevelData: getQrLevelData(builder),
    qrCodeStoreData: qrCodeStoreData(builder),
    templateStoreData: templateStoreData(builder),
    templateEditData: templateEditData(builder),
    templateDeleteData: templateDeleteData(builder),
    qrCodeUpdateData: qrCodeUpdateData(builder),
    getFolderData: getFolderData(builder),
    createQrTemplate: createQrTemplate(builder),
    getQrTemplate: getQrTemplate(builder),
  }),
});

// Export hooks for using in components
const {
  useGetQrFrameDataMutation,
  useGetQrTemplatesDataQuery,
  useGetOneQrTemplateDataQuery,
  useGetQrLogoDataQuery,
  useGetQrShapeDataQuery,
  useGetQrLevelDataQuery,
  useQrCodeStoreDataMutation,
  useTemplateStoreDataMutation,
  useTemplateEditDataMutation,
  useTemplateDeleteDataMutation,
  useQrCodeUpdateDataMutation,
  useGetFolderDataQuery,
  useCreateQrTemplateMutation,
  useGetQrTemplateQuery,
} = qrApi;

export {
  qrApi,
  useGetQrFrameDataMutation,
  useGetQrTemplatesDataQuery,
  useGetOneQrTemplateDataQuery,
  useGetQrLogoDataQuery,
  useGetQrShapeDataQuery,
  useGetQrLevelDataQuery,
  useQrCodeStoreDataMutation,
  useTemplateStoreDataMutation,
  useTemplateEditDataMutation,
  useTemplateDeleteDataMutation,
  useQrCodeUpdateDataMutation,
  useGetFolderDataQuery,
  useCreateQrTemplateMutation,
  useGetQrTemplateQuery,
};
