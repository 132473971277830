import React, { useEffect, useState } from "react";
import DashboardMobilePreview from "../../dashboardComponents/DashboardMobilePreview";
import { data, useNavigate, useParams } from "react-router";
import DashboardHeader from "../../Header/DashboardHeader";
import "./DashboardPage.css";
import StaticQRCards from "../../dashboardComponents/StaticQRCards";
import { FaChevronDown, FaWhatsapp } from "react-icons/fa";
import {
  dynamicQRItems,
  staticQrItems,
} from "../../../constants/Data/DashboardPageData";
import QRDashbaordCard from "../../dashboardComponents/QRDashboardCard";
import defaultMobilePreview from "../../../assets/svg/mobileImage.webp";
import Button from "../../global/Button";
import { useGetCardTypesMutation } from "../../../services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardCards } from "../../../store/slices/dashboardCardSlice";
import { useAuth } from "../../../context/authCtx";
import { authenticateUser } from "../../../store/slices/userSlice";
import {
  setQRApiData,
  setValues,
} from "../../../store/slices/MobilePreviewSlice";
import { useQrContext } from "../../../context/qrContext";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { setQrType } = useQrContext();
  const [staticDropdown, setStaticDropdown] = useState(false);
  const [dynamicMobilePreviewImageUrl, setDynamicMobilePreviewImageUrl] =
    useState(defaultMobilePreview);
  const [gotit, setgotit] = useState(true);
  const path = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const { user: userData, error } = useSelector(
    (state) => state.root.USER_DATA_SLICE
  );
  const globalDashboardCards = useSelector(
    (state) => state.root.DASHBOARD_CARD_SLICE.dashboardCards
  );
  const [getCards] = useGetCardTypesMutation();
  const imageBaseUrl = import.meta.env.VITE_IMAGE_API_URL;
  const url = `/dashboard/`;
  useEffect(() => {
    console.log("User Data ", userData);
    (async () => {
      if (!globalDashboardCards?.length) {
        const data = await getCards();
        dispatch(setDashboardCards(data.data));
      }
    })();
    dispatch(setQRApiData(null));
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    if (path !== "bulkqrgeneration") {
      setgotit(false);
    }
  }, []);

  const progress = [
    {
      title: "Types of QR Codes",
      isActive: true,
      route: "/dashboard",
    },
    {
      title: "Content",
      isActive: false,
      route: "/dashboard",
    },
    {
      title: "QR Design",
      isActive: false,
      route: "/qr-design",
    },
  ];
  const bulkprogress = [
    {
      title: "Types of QR Codes",
      isActive: true,
      route: "/dashboard",
    },
    {
      title: "QR Design",
      isActive: false,
      route: "/qr-design",
    },
    {
      title: "Upload CSV",
      isActive: false,
      route: "/upload-csv",
    },
  ];

  return (
    <div className="w-[100%]  bg-[var(--dark-bg)]">
      <div className="!font-[300]">
        <DashboardHeader progress={progress} bulkprogress={bulkprogress} />
      </div>
      <div className="w-[100%] mx-auto px-5 md:px-2 lg:px-2 py-6 bg-[var(--dark-bg)]">
        <div className="dashboard-page-container flex flex-col md:flex-row gap-2 w-[100%]">
          {/* Main Content */}
          <div className="flex flex-col w-[100%] ">
            {/* Dynamic QR Section */}
            <div className="mb-12">
              {path == "bulkqrgeneration" && gotit && (
                <div className=" flex justify-between items-center py-3 rounded-md mb-6 bg-[var(--primary)] px-4 md:px-8">
                  <h2 className="text-xs md:text-base">
                    Remember that you are creating qrs in bulk.{" "}
                  </h2>
                  <button
                    onClick={() => {
                      setgotit(!gotit);
                    }}
                    className={
                      "text-white w-[100px] md:w-[140px]  md:px-8 bg-[var(--darker-bg)] rounded-md py-1"
                    }
                  >
                    Got it
                  </button>
                </div>
              )}
              <div className="flex items-center pl-4 mb-2">
                <h2 className="text-lg sm:text-2xl font-bold text-white">
                  Dynamic QR
                </h2>
                <span className="ml-2 px-2 py-1 bg-[#03DAC5] text-xs rounded !font-[300]">
                  With Tracking
                </span>
              </div>
              <p className=" text-gray-200 pl-4 text-[15px] mb-6 !font-[300]">
                Update content in real time, without changing your code
              </p>

              <div className="flex items-center flex-wrap justify-center  md:justify-start md:min-w-[500px] md:pl-4  md:max-w-[100%] gap-4 ">
                {globalDashboardCards?.map(
                  (item, index) =>
                    item.type === "dynamic" && (
                      <div
                        key={index}
                        className="w-[44%] sm:w-[32%] md:w-[28%] lg:w-[22%] xl:w-[22%]"
                      >
                        <div
                          onClick={() => {
                            path === "bulkqrgeneration" &&
                              navigate(`/bulkqrgeneration/qr-design/${index}`);
                            console.log("dasdadasdasdasdasdasdasasdjhi");
                            setQrType("dynamic");
                            console.log("item.type", item.type);
                          }}
                        >
                          <QRDashbaordCard
                            {...item}
                            cardType={item.type}
                            icon={imageBaseUrl + item.icon}
                            title={item.name}
                            onClick={
                              url + item.name.toLowerCase().replace(/\s+/g, "")
                            }
                            setImageUrl={setDynamicMobilePreviewImageUrl}
                            Url={imageBaseUrl + item?.thumbnail}
                          />
                        </div>
                      </div>
                    )
                )}
              </div>
              {/* Static QR Section */}
              <div className="w-[100%] pl-7 my-8 ">
                <div
                  onClick={() => setStaticDropdown(!staticDropdown)}
                  className="flex cursor-pointer items-center justify-between mb-2"
                >
                  <div>
                    <h2 className="text-2xl font-bold text-white flex items-center">
                      Static QR
                      <span className="ml-2 px-2 py-1 bg-[var(--darker-bg)] text-[var(--text-light)] text-xs rounded !font-[300]">
                        No Tracking
                      </span>
                    </h2>
                    <p className="text-gray-200 mt-1 text-[12px] lg:text-[16px] !font-[300]">
                      Reprint required when updating content
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 px-4">
                    <div
                      className={`${
                        staticDropdown ? "rotate-180" : ""
                      } transition-transform duration-300`}
                    >
                      <FaChevronDown
                        size={32}
                        className="text-[var(--primary)]"
                      />
                    </div>
                  </div>
                </div>

                {staticDropdown && (
                  <div className=" flex items-center flex-wrap gap-4 mt-6">
                    {globalDashboardCards.map(
                      (item, index) =>
                        item.type === "static" && (
                          <div
                            onClick={() => {
                              setQrType("static");

                              console.log("item.type", item.type);
                            }}
                            key={index}
                            className="w-[44%] sm:w-[32%] md:w-[28%] lg:w-[22%] xl:w-[22%]"
                          >
                            <QRDashbaordCard
                              {...item}
                              cardType={item.type}
                              icon={imageBaseUrl + item.icon}
                              title={item.name}
                              onClick={
                                url +
                                item.name.toLowerCase().replace(/\s+/g, "")
                              }
                              setImageUrl={setDynamicMobilePreviewImageUrl}
                              Url={imageBaseUrl + item?.thumbnail}
                            />
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden md:block min-w-[400px]">
            <div className="sticky top-4 right-0 h-[calc(100vh-2rem)] ">
              <DashboardMobilePreview url={dynamicMobilePreviewImageUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
