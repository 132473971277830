import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import SelectField from "../GlobalInputs/SelectField";
import TextInputs from "../GlobalInputs/TextInputs";
import FileInput from "../GlobalInputs/FileInputs";
import { useContactUsMutation } from "../../../services/qr";
import { useDispatch, useSelector } from "react-redux";
import { Trash2 } from "lucide-react"; // Importing the delete icon from Lucide
import "./ContactModal.css";
import useScreenSize from "../../../hooks/useScreenSize";
import Button from "../Button";

const ContactModal = ({ visible, onClose }) => {
  const { user } = useSelector((state) => state.root.USER_DATA_SLICE);
  const userId = user?.id;
  const methods = useForm();
  const { setValue, handleSubmit, watch } = methods;
  const dispatch = useDispatch();
  const reasonValue = watch("reason");

  const [contactUs, { isLoading, isError, isSuccess, error }] =
    useContactUsMutation();

  // State to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleOk = async (data) => {
    console.log("Form Data:", data); // Log the entire form data to check if file is included

    // Convert the FileList to an array and safely process files
    const files = Array.isArray(data.file)
      ? data.file
      : data.file
      ? Array.from(data.file) // Convert FileList to array if it's not already
      : [];

    console.log("Files processed:", files); // Log processed files

    const formData = {
      userId: userId,
      reason: data.reason,
      subject: data.subject,
      query: data.query,
      files: files.map((f) => f.name).join(", "), // Safely map over files
      notify_colleagues: data.notify_colleagues,
    };

    try {
      if (!userId) {
        console.error("User ID is missing");
        return;
      }
      const response = await contactUs(formData);
      if (response) {
        console.log("Contact Api", response);
      }
      onClose();
    } catch (err) {
      console.error("🚀 ~ Error:", err);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setSelectedFiles(Array.from(files)); // Set selected files
      setValue("file", files); // Update the form with selected files
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index); // Remove selected file by index
    setSelectedFiles(updatedFiles); // Update the state with remaining files
  };

  const options = [
    { option: "Invoice and Payment" },
    { option: "Feedback or suggestions" },
    { option: "Bug or technical issues" },
    { option: "Refund policy" },
    { option: "Others" },
  ];

  const { isMobile } = useScreenSize();
  return (
    <Modal
      title={<span className="text-white text-3xl">Write Us Your Query</span>}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      className={"custom-modal"}
      width={isMobile ? "100%" : "50%"}
      // bodyStyle={{ padding: 0 }}
      style={{ maxWidth: "100%", width: "100%" }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOk)}>
          <div className="flex gap-4 mb-6">
            <div className="w-full md:w-1/2">
              <SelectField
                name="reason"
                label="Reason"
                required
                validationError="Reason is required"
                placeholder="Select"
                options={options}
                bgcolour="#172630"
                top={80}
                setValue={setValue}
                value={reasonValue}
              />
            </div>

            <div className="w-full md:w-1/2">
              <TextInputs
                name="subject"
                label="Subject"
                required
                validationError="Subject is required"
                placeholder="Write a subject"
                setValue={setValue}
              />
            </div>
          </div>

          <div className="mb-6">
            <TextInputs
              name="query"
              label="Query"
              required
              height="50px"
              validationError="Query is required"
              placeholder="Write your query here..."
              setValue={setValue}
            />
          </div>

          <FileInput
            name={"fileInput"}
            isAddDropZone={true}
            isRemoveUploadedFile={true}
            label="Image"
            onChange={(files) => {
              setSelectedFiles([...files]);
            }}
          />

          {/* Display selected image as a thumbnail */}
          {selectedFiles.length > 0 && (
            <div className="mb-6 border-2 border-teal-400 mt-4 rounded-lg">
              <div className="bg-var(--dark-bg) p-4 rounded-lg">
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center py-2"
                    >
                      {/* Display Image as Thumbnail */}
                      <div className="flex text-white  h-[20px] items-center">
                        <img
                          src={URL.createObjectURL(file)} // Display image using object URL
                          alt={file.name}
                          className="w-13 h-13 text-white object-cover rounded-md mr-2"
                        />
                        <span>{file.name}</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleFileDelete(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 size={16} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="mb-6">
            <TextInputs
              name="notify_colleagues"
              label="Notify my colleagues"
              required
              validationError="Please enter colleagues' emails"
              placeholder="enter@email.com"
              setValue={setValue}
            />
          </div>

          {/* Footer buttons */}
          <div className="flex justify-between gap-4 mt-6">
            <button
              type="button"
              onClick={handleCancel}
              className="w-[140px] text-[16px] text-teal-400 border-2 border-teal-400 hover:bg-teal-400 hover:text-white rounded-md py-2 px-4"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="w-[140px] text-[16px] text-white bg-teal-400 border-2 border-teal-400 hover:bg-teal-500 hover:text-white rounded-md py-2 px-4"
            >
              {isLoading ? "Sending..." : "Send"}
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ContactModal;
