import { combineReducers } from "redux";
// Constants
import STORE from "../constants/STORE";
// Reducers
import authReducer from "./slices/authSlice";
import mobilePreviewReducer from "./slices/MobilePreviewSlice";
import apperanceCustomizerSliceReducer from "./slices/appearanceCustomizerSlice.js";
import qrGeneratorSlice from "./slices/qrCodeGenerationSlice.js";
import dashboardCardsSliceReducer from "./slices/dashboardCardSlice.js";
import userDataSliceReducer from "./slices/userSlice.js";
import plansDataSliceReducer from "./slices/plansSlice.js";
import invoiceDataSliceReducer from "./slices/invoiceSlice.js";
import checkOutSliceReducer from "./slices/checkoutSlice.js";
import subscriptionSliceReducer from "./slices/subscriptionSlice.js";

// Services
import { authApi } from "../services/auth";
import { contactApi } from "../services/contact";
import { favoritesApi } from "../services/favorites";
import { uploadApi } from "../services/upload";
import { folderApi } from "../services/folder";
import { metaDataApi } from "../services/metadata";
import { myDomainApi } from "../services/domains";
import { myQrCodeApi } from "../services/myqrcodes";
import { newQrCodeApi } from "../services/newqrcode";
import { plansApi } from "../services/plans";
import { productApi } from "../services/products";
import { profileApi } from "../services/profile";
import { resourcesApi } from "../services/resources";
import { resourceByIdApi } from "../services/resourcesbyid";
import { settingsApi } from "../services/settings";
import { statsApi } from "../services/stats";
import { qrApi } from "../services/qr.js";
import updateProfileSlice from "./slices/updateProfileSlice.js";
import cardFormDataSliceReducer from "./slices/cardFormData.js";
import folderSliceReducer from "./slices/folderSlice.js";
import memberRolesSliceReducer from "./slices/memberRolesSlice.js";
import domainSliceReducer from "./slices/domainsSlice.js";
import qrScanAnalytics from "./slices/qrScanAnalytics.js";
import registerUserSlice from "../store/slices/registeredUserSlice.js";
import { qrDomainsApi } from "../services/qrDomain.js";

const rootReducer = combineReducers({
  [STORE.SLICES.AUTH_SLICE]: authReducer,
  [STORE.SLICES.USER_DATA_SLICE]: userDataSliceReducer,
  [STORE.SLICES.SUBSCRIPTION_SLICE]: subscriptionSliceReducer,
  [STORE.SLICES.MOBILE_PREVIEW_SLICE]: mobilePreviewReducer,
  [STORE.SLICES.MY_QR_CODE_SLICE]: qrGeneratorSlice,
  [STORE.SLICES.UPDATE_PROFILE_SLICE]: updateProfileSlice,
  [STORE.SLICES.APPERANCE_CUSTOMIZE_SLICE]: apperanceCustomizerSliceReducer,
  [STORE.SLICES.DASHBOARD_CARD_SLICE]: dashboardCardsSliceReducer,
  [STORE.SLICES.CARD_FORM_DATA]: cardFormDataSliceReducer,
  [STORE.SLICES.PLANS_SLICE]: plansDataSliceReducer,
  [STORE.SLICES.INVOICE_SLICE]: invoiceDataSliceReducer,
  [STORE.SLICES.CHECKOUT_SLICE]: checkOutSliceReducer,
  [STORE.SLICES.FOLDER_SLICE]: folderSliceReducer,
  [STORE.SLICES.DOMAIN_SLICE]: domainSliceReducer,
  [STORE.SLICES.MEMBER_ROLES_SLICE]: memberRolesSliceReducer,
  [STORE.SLICES.REGISTER_USER_SLICE]: registerUserSlice,
  [STORE.SLICES.QR_SCAN_ANALYTICS]: qrScanAnalytics,
  [authApi.reducerPath]: authApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [uploadApi.reducerPath]: uploadApi.reducer,
  [folderApi.reducerPath]: folderApi.reducer,
  [qrApi.reducerPath]: qrApi.reducer,
  [metaDataApi.reducerPath]: metaDataApi.reducer,
  [myDomainApi.reducerPath]: myDomainApi.reducer,
  [myQrCodeApi.reducerPath]: myQrCodeApi.reducer,
  [newQrCodeApi.reducerPath]: newQrCodeApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [resourcesApi.reducerPath]: resourcesApi.reducer,
  [resourceByIdApi.reducerPath]: resourceByIdApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [qrDomainsApi.reducerPath]: qrDomainsApi.reducer,
});

export default rootReducer;
