import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getNewQrCodeData
 * @description API call to fetch new QR code data
 *
 * @param payload: object
 */
const getNewQrCodeData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/qr-types",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getUrlValidateData
 * @description API call to validate URL
 *
 * @param payload: object
 */
const getUrlValidateData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/qr-code/check-string-availability",
      method: "POST",
      data: payload,
    }),
  });
};

// Create New QR Code API slice
const newQrCodeApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.NEW_QR_CODE_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNewQrCodeData: getNewQrCodeData(builder),
    getUrlValidateData: getUrlValidateData(builder),
  }),
});

// Export hooks for using in components
const { useGetNewQrCodeDataQuery, useGetUrlValidateDataQuery } = newQrCodeApi;

export { newQrCodeApi, useGetNewQrCodeDataQuery, useGetUrlValidateDataQuery };
