import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getPlans
 * @description API call to fetch plans
 */
const getPlans = (builder) => {
  return builder.mutation({
    query: (params) => ({
      url: `/plans/by-country-code?countryCode=${params}`,
      method: "GET",
    }),
  });
};

/*
 * @private
 * @name getSubscriptions
 * @description API call to fetch all subscriptions
 */
const getSubscriptions = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `/user-subscriptions/user/${payload}`,
      method: "GET",
    }),
  });
};

const getCheckout = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/stripe/checkout",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getGenaratedIntentData
 * @description API call to generate intent data
 */
const getGenaratedIntentData = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/subscription/generate-intent",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getGenaratedAPIKeyData
 * @description API call to generate API key data
 */
const getGenaratedAPIKeyData = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/generate-auth-token",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getPriceByCountryData
 * @description API call to fetch price by country
 */
const getPriceByCountryData = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/subscription/get-price-by-country",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name newSubscriptionService
 * @description API call to create a new subscription
 */
const newSubscriptionService = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: "/stripe/subscription/new",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name cancleSubscriptionQuery
 * @description API call to cancel a subscription
 */
const cancleSubscriptionQuery = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/subscription/cancel",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name resumeSubscriptionQuery
 * @description API call to resume a canceled subscription
 */
const resumeSubscriptionQuery = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/subscription/resume",
      method: "POST",
      data: payload,
    }),
  });
};

const getPlansByIPAddress = (builder) => {
  return builder.mutation({
    query: (ipAddress) => ({
      url: `/plans/by-ip-address/${ipAddress}`,
      method: "GET",
    }),
  });
};

const calculateTax = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/stripe/calculate-tax",
      method: "POST",
      data: payload,
    }),
  });
};

// Create Plans API slice
const plansApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.PLANS_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPlans: getPlans(builder),
    getSubscriptions: getSubscriptions(builder),
    getGenaratedIntentData: getGenaratedIntentData(builder),
    getGenaratedAPIKeyData: getGenaratedAPIKeyData(builder),
    getPriceByCountryData: getPriceByCountryData(builder),
    newSubscriptionService: newSubscriptionService(builder),
    cancleSubscriptionQuery: cancleSubscriptionQuery(builder),
    resumeSubscriptionQuery: resumeSubscriptionQuery(builder),
    getPlansByIPAddress: getPlansByIPAddress(builder),
    getCheckout: getCheckout(builder),
    calculateTax: calculateTax(builder),
  }),
});

// Export hooks for using in components
const {
  useGetPlansMutation,
  useGetSubscriptionsMutation,
  useGetGenaratedIntentDataMutation,
  useGetGenaratedAPIKeyDataMutation,
  useGetPriceByCountryDataMutation,
  useNewSubscriptionServiceMutation,
  useCancleSubscriptionQueryMutation,
  useResumeSubscriptionQueryMutation,
  useGetPlansByIPAddressMutation,
  useGetCheckoutMutation,
  useCalculateTaxMutation,
} = plansApi;

export {
  plansApi,
  useGetPlansMutation,
  useGetSubscriptionsMutation,
  useGetGenaratedIntentDataMutation,
  useGetGenaratedAPIKeyDataMutation,
  useGetPriceByCountryDataMutation,
  useNewSubscriptionServiceMutation,
  useCancleSubscriptionQueryMutation,
  useResumeSubscriptionQueryMutation,
  useGetPlansByIPAddressMutation,
  useGetCheckoutMutation,
  useCalculateTaxMutation,
};
