import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const registerUserSlice = createSlice({
  name: "registerUserSlice",
  reducerPath:STORE.SLICES.REGISTER_USER_SLICE,
  initialState: [],
  reducers: {
    addRegisterUser: (state,action)=> {
        state.push(action.payload)
    },
    deleteRegisterUser: (state, action) => {
      return state.filter(user => user.email !== action.payload.email);
    }
  },
});

export const { addRegisterUser ,deleteRegisterUser } = registerUserSlice.actions;

export default registerUserSlice.reducer;
