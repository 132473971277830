import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const invoiceSlice = createSlice({
  name: STORE.SLICES.INVOICE_SLICE,
  initialState: {
    data: {
      country: "",
      tax_id: "",
      address: "",
      city: "",
      postal_code: "",
      companyName: "",
      email: "",
      name: "",
      surname: "",
      type: "",
    },
    method: "",
    isInvoiceFilled: false,
  },
  reducers: {
    setValueOfInvoiceState: (state, action) => {
      const nameOfStateField = action.payload?.name;
      const data = action.payload?.data;
      state[nameOfStateField] = data;
    }
  },
});

export const { setValueOfInvoiceState } = invoiceSlice.actions;
export default invoiceSlice.reducer;
