import { useState } from "react";

// Email validation regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Password validation rules
const isStrongPassword = (password) => {
    return (
        password.length >= 8 &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[!@#$%^&*]/.test(password)
    );
};

const useFormValidation = () => {
    const [errors, setErrors] = useState({});

    // Validate a specific field
    const validateField = (name, value, formData) => {
        let error = "";

        switch (name) {
            case "name":
                if (!value.trim()) error = "Name is required.";
                break;
            case "email":
                if (!value.trim()) {
                    error = "Email is required.";
                } else if (!emailRegex.test(value)) {
                    error = "Invalid email format.";
                }
                break;
            case "password":
                if (!value.trim()) {
                    error = "Password is required.";
                } else if (!isStrongPassword(value)) {
                    error =
                        "Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character.";
                }
                break;
            case "confirmPassword":
                if (!value.trim()) {
                    error = "Confirm Password is required.";
                } else if (value !== formData.password) {
                    error = "Passwords do not match.";
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    // Validate the whole form before submission
    const validateForm = (formData) => {
        let newErrors = {};
        let isValid = true;

        Object.keys(formData).forEach((key) => {
            const value = formData[key];
            let error = "";

            switch (key) {
                case "name":
                    if (!value.trim()) error = "Name is required.";
                    break;
                case "email":
                    if (!value.trim()) {
                        error = "Email is required.";
                    } else if (!emailRegex.test(value)) {
                        error = "Invalid email format.";
                    }
                    break;
                case "password":
                    if (!value.trim()) {
                        error = "Password is required.";
                    } else if (!isStrongPassword(value)) {
                        error =
                            "Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character.";
                    }
                    break;
                case "confirmPassword":
                    if (!value.trim()) {
                        error = "Confirm Password is required.";
                    } else if (value !== formData.password) {
                        error = "Passwords do not match.";
                    }
                    break;
                default:
                    break;
            }

            if (error) {
                newErrors[key] = error;
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };


    return { errors, validateField, validateForm };
};

export default useFormValidation;
