import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function PlansCheckoutSkeleton({ baseStyle }) {
  return (
    <div className="w-full bg-gray-900 text-white p-6 px-20">
      <div className="flex flex-row gap-6">
        {/* Left Section - Payment Form */}
        <div className="w-2/3">
          {/* Selected Plan */}
          <div className="flex justify-between items-center mb-6">
            <Skeleton {...baseStyle} width={160} height={32} />
            <div className="flex items-center">
              <Skeleton
                {...baseStyle}
                width={120}
                height={24}
                className="mr-2"
              />
              <Skeleton
                {...baseStyle}
                width={24}
                height={24}
                className="rounded-full"
              />
            </div>
          </div>

          {/* Payment Section */}
          <Skeleton {...baseStyle} width={120} height={28} className="mb-6" />

          {/* Invoicing Section */}
          <div className="bg-gray-800 rounded-lg p-6 mb-6">
            <div className="flex items-center mb-6">
              <div className="bg-teal-500 rounded-full w-8 h-8 flex items-center justify-center mr-3">
                <Skeleton
                  {...baseStyle}
                  width={16}
                  height={16}
                  className="rounded-full"
                />
              </div>
              <Skeleton {...baseStyle} width={120} height={28} />
            </div>

            {/* Type Selection */}
            <div className="mb-4">
              <Skeleton
                {...baseStyle}
                width={60}
                height={20}
                className="mb-2"
              />
              <div className="grid grid-cols-2 gap-4">
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={48}
                  className="rounded-md"
                />
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={48}
                  className="rounded-md"
                />
              </div>
            </div>

            {/* Company Name */}
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <Skeleton
                  {...baseStyle}
                  width={120}
                  height={20}
                  className="mr-2"
                />
                <Skeleton
                  {...baseStyle}
                  width={16}
                  height={16}
                  className="rounded-full"
                />
              </div>
              <Skeleton
                {...baseStyle}
                width="100%"
                height={44}
                className="rounded-md"
              />
            </div>

            {/* Country */}
            <div className="mb-4">
              <Skeleton
                {...baseStyle}
                width={80}
                height={20}
                className="mb-2"
              />
              <Skeleton
                {...baseStyle}
                width="100%"
                height={44}
                className="rounded-md"
              />
            </div>

            {/* Tax ID */}
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <Skeleton
                  {...baseStyle}
                  width={60}
                  height={20}
                  className="mr-2"
                />
                <Skeleton
                  {...baseStyle}
                  width={16}
                  height={16}
                  className="rounded-full"
                />
              </div>
              <Skeleton
                {...baseStyle}
                width="100%"
                height={44}
                className="rounded-md"
              />
            </div>

            {/* Name and Surname */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <div className="flex items-center mb-2">
                  <Skeleton
                    {...baseStyle}
                    width={60}
                    height={20}
                    className="mr-2"
                  />
                  <Skeleton
                    {...baseStyle}
                    width={16}
                    height={16}
                    className="rounded-full"
                  />
                </div>
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={44}
                  className="rounded-md"
                />
              </div>
              <div>
                <Skeleton
                  {...baseStyle}
                  width={100}
                  height={20}
                  className="mb-2"
                />
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={44}
                  className="rounded-md"
                />
              </div>
            </div>

            {/* Address */}
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <Skeleton
                  {...baseStyle}
                  width={80}
                  height={20}
                  className="mr-2"
                />
                <Skeleton
                  {...baseStyle}
                  width={16}
                  height={16}
                  className="rounded-full"
                />
              </div>
              <Skeleton
                {...baseStyle}
                width="100%"
                height={44}
                className="rounded-md"
              />
            </div>

            {/* Postal Code and City */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Skeleton
                  {...baseStyle}
                  width={100}
                  height={20}
                  className="mb-2"
                />
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={44}
                  className="rounded-md"
                />
              </div>
              <div>
                <div className="flex items-center mb-2">
                  <Skeleton
                    {...baseStyle}
                    width={40}
                    height={20}
                    className="mr-2"
                  />
                  <Skeleton
                    {...baseStyle}
                    width={16}
                    height={16}
                    className="rounded-full"
                  />
                </div>
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={44}
                  className="rounded-md"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Summary */}
        <div className="w-1/3">
          <div className="bg-gray-800 rounded-lg p-6">
            {/* Summary Header */}
            <div className="flex justify-between items-center mb-6">
              <Skeleton {...baseStyle} width={100} height={24} />
              <div className="flex items-center">
                <Skeleton {...baseStyle} width={40} height={20} />
                <Skeleton
                  {...baseStyle}
                  width={16}
                  height={16}
                  className="ml-2"
                />
              </div>
            </div>

            {/* Price Breakdown */}
            <div className="mb-6">
              <div className="flex justify-between mb-3">
                <Skeleton {...baseStyle} width={100} height={20} />
                <Skeleton {...baseStyle} width={100} height={20} />
              </div>
              <div className="flex justify-between mb-6">
                <Skeleton {...baseStyle} width={80} height={24} />
                <Skeleton {...baseStyle} width={120} height={24} />
              </div>
            </div>

            {/* Plan Details */}
            <div className="bg-gray-700 rounded-lg p-4 mb-6">
              <div className="mb-4">
                <div className="flex items-center mb-2">
                  <Skeleton
                    {...baseStyle}
                    width={16}
                    height={16}
                    className="mr-2 rounded-sm"
                  />
                  <Skeleton {...baseStyle} width="80%" height={20} />
                </div>
                <div className="flex items-center mb-2">
                  <Skeleton
                    {...baseStyle}
                    width={16}
                    height={16}
                    className="mr-2 rounded-sm"
                  />
                  <Skeleton {...baseStyle} width="70%" height={20} />
                </div>
                <div className="flex items-center">
                  <Skeleton
                    {...baseStyle}
                    width={16}
                    height={16}
                    className="mr-2 rounded-sm"
                  />
                  <Skeleton {...baseStyle} width="60%" height={20} />
                </div>
              </div>
            </div>

            {/* Payment Methods */}
            <div className="mb-6">
              <div className="flex justify-between mb-4">
                <Skeleton {...baseStyle} width={140} height={20} />
                <Skeleton {...baseStyle} width={180} height={20} />
              </div>
              <div className="flex justify-center space-x-4">
                <Skeleton
                  {...baseStyle}
                  width={40}
                  height={30}
                  className="rounded-md"
                />
                <Skeleton
                  {...baseStyle}
                  width={40}
                  height={30}
                  className="rounded-md"
                />
                <Skeleton
                  {...baseStyle}
                  width={40}
                  height={30}
                  className="rounded-md"
                />
              </div>
            </div>

            {/* Cancel Button */}
            <Skeleton
              {...baseStyle}
              width="100%"
              height={48}
              className="rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansCheckoutSkeleton;
