import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";
import { data } from "react-router";

/*
 * @private
 * @name authLogin
 * @description API call to log in a user
 *
 * @param payload: object
 */
const authLogin = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/auth/login`,
        method: "POST",
        data: payload,
      };
    },
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      console.log("Login Query Strted");

      try {
        const { response } = await queryFulfilled;
        console.log("Login Respomse ==>", response);
      } catch (error) {
        console.log("error", error);
      }
    },
  });
};

/*
 * @private
 * @name authGoogleLogin
 * @description API call to log in a user with his google account
 *
 * @param payload: object
 */
const authGoogleLogin = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/auth/google`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name authFacebookLogin
 * @description API call to log in a user with his facebook account
 *
 * @param payload: object
 */
const authFacebookLogin = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/auth/facebook`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name refreshToken
 * @description API call to get a refresh token
 *
 * @param payload: object => { authorization: value }
 */
const refreshToken = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/auth/refresh`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name authSignup
 * @description API call to sign up a new user
 *
 * @param payload: object
 */
const authSignup = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/users/create`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name authLogout
 * @description API call to log out the user
 *
 * @param no params: no params
 */
const authLogout = (builder) => {
  return builder.mutation({
    query() {
      return {
        url: `/auth/logout`,
        method: "POST",
      };
    },
  });
};

/*
 * @private
 * @name authForgotPassword
 * @description API call to request password reset
 *
 * @param payload: object
 */
const authForgotPassword = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/getCodeForRecoverPass`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name authResetPassword
 * @description API call to reset the user's password
 *
 * @param payload: object { id, current_password, password }
 */
const authResetPassword = (builder) => {
  return builder.mutation({
    query({ id, current_password, password }) {
      return {
        url: `/updatePassword/${id}`,
        method: "POST",
        data: { current_password, password },
      };
    },
  });
};

const updatePassword = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/users/update-password`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${payload.token}`, // Adding the Bearer token in the header
        },
        data: { password: payload.password },
      };
    },
  });
};


/*
 * @private
 * @name getUserDetails
 * @description API call to user details
 *
 * @param payload: object { id }
 */
const getUserDetails = (builder) => {
  return builder.mutation({
    query(id) {
      console.log("Id in query", id);

      return {
        url: `/users/get-by-id`,
        method: "GET",
        params: {
          id: id,
        },
      };
    },
  });
};

/*
 * @private
 * @name getUserDetails
 * @description API call to user details
 *
 * @param payload: object { id }
 */
const getMemberRoles = (builder) => {
  return builder.mutation({
    query() {
      return {
        url: `/member-roles`,
        method: "GET",
      };
    },
  });
};

const getMembers = (builder) => {
  return builder.mutation({
    query() {
      return {
        url: `/users/members`,
        method: "GET",
      };
    },
  });
};

/*
 * @private
 * @name getUserDetails
 * @description API call to get all the modules
 *
 * @param payload: object { id }
 */
const getModules = (builder) => {
  return builder.mutation({
    query() {
      return {
        url: `/modules`,
        method: "GET",
      };
    },
  });
};

/*
 * @private
 * @name getUserDetails
 * @description API call to get all the modules
 *
 * @param payload: object { id }
 */
const createMember = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/users/add-member`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// Create the auth API slice
const authApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.AUTH_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    authLogin: authLogin(builder),
    authGoogleLogin: authGoogleLogin(builder),
    authFacebookLogin: authFacebookLogin(builder),
    authSignup: authSignup(builder),
    authLogout: authLogout(builder),
    authForgotPassword: authForgotPassword(builder),
    authResetPassword: authResetPassword(builder),
    refreshToken: refreshToken(builder),
    getUserDetails: getUserDetails(builder),
    getMemberRoles: getMemberRoles(builder),
    getModules: getModules(builder),
    createMember: createMember(builder),
    getMembers: getMembers(builder),
    updatePassword: updatePassword(builder),
  }),
});

// Export hooks for using in components
const {
  useAuthLoginMutation,
  useAuthGoogleLoginMutation,
  useAuthFacebookLoginMutation,
  useAuthSignupMutation,
  useAuthLogoutMutation,
  useAuthForgotPasswordMutation,
  useAuthResetPasswordMutation,
  useRefreshTokenMutation,
  useGetUserDetailsMutation,
  useGetMemberRolesMutation,
  useGetModulesMutation,
  useCreateMemberMutation,
  useGetMembersMutation,
  useUpdatePasswordMutation
} = authApi;

export {
  authApi,
  useAuthLoginMutation,
  useAuthGoogleLoginMutation,
  useAuthFacebookLoginMutation,
  useAuthSignupMutation,
  useAuthLogoutMutation,
  useAuthForgotPasswordMutation,
  useAuthResetPasswordMutation,
  useRefreshTokenMutation,
  useGetUserDetailsMutation,
  useGetMemberRolesMutation,
  useGetModulesMutation,
  useCreateMemberMutation,
  useGetMembersMutation,
  useUpdatePasswordMutation
};
