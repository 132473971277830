import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";
import { plansApi } from "../../services/plans";
import { setValuesOfUserData } from "./userSlice";

const checkOutSlice = createSlice({
  name: STORE.SLICES.CHECKOUT_SLICE,
  initialState: {
    clientSecret: "",
    data: {},
    stripePaymentMethod: "",
    stripePriceId: "",
  },
  reducers: {
    setValuesInCheckoutSlice: (state, action) => {
      const nameOfState = action.payload?.name;
      const value = action.payload?.data;
      state[nameOfState] = value;
    },
  },
});

export const { setValuesInCheckoutSlice } = checkOutSlice.actions;

export const getCheckoutIntent = () => async (dispatch, getState) => {
  const currentState = getState();
  const userData = currentState?.root?.USER_DATA_SLICE;
  const plansData = currentState?.root?.PLANS_SLICE;
  const payload = {
    user_id: userData?.user?.id,
    plan_id: plansData?.selectedPlan?.id,
    country_code: plansData?.countryCode,
    currency: plansData?.currencyType?.code,
  };
  try {
    const response = await dispatch(
      plansApi.endpoints.getCheckout.initiate(payload)
    ).unwrap();
    if (response) {
      console.log("Intent Response", response);

      dispatch(setValuesInCheckoutSlice({ name: "data", data: response }));
      dispatch(
        setValuesInCheckoutSlice({
          name: "clientSecret",
          data: response?.intent?.client_secret,
        })
      );
      dispatch(
        setValuesInCheckoutSlice({
          name: "stripePriceId",
          data: response?.plan?.stripePriceId,
        })
      );
    }
  } catch (error) {
    console.log("Intent error", error);
  }
};

export const createSubscription = () => async (dispatch, getState) => {
  const currentState = getState();
  const userData = currentState?.root?.USER_DATA_SLICE;
  const plansData = currentState?.root?.PLANS_SLICE;
  const invoiceData = currentState?.root?.INVOICE_SLICE;
  const checkoutData = currentState?.root?.CHECKOUT_SLICE;

  console.log();

  const payload = {
    priceId: checkoutData?.stripePriceId,
    planId: plansData?.selectedPlan?.id,
    userId: userData?.user?.id,
    paymentMethod: checkoutData?.stripePaymentMethod,
    discountId: "",
    companyName: invoiceData?.data?.companyName,
    taxId: invoiceData?.data?.tax_id,
    name: invoiceData?.data?.name,
    surname: invoiceData?.data?.surname,
    address: invoiceData?.data?.address,
    postalCode: invoiceData?.data?.postal_code,
    city: invoiceData?.data?.city,
    country: invoiceData?.data?.country,
    email: invoiceData?.data?.email,
  };

  try {
    const response = await dispatch(
      plansApi.endpoints.newSubscriptionService.initiate(payload)
    ).unwrap();
    if (response?.status == "active") {
      console.log("Success Response", response);
      dispatch(setValuesOfUserData({ name: "isPremiumActive", data: true }));
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export default checkOutSlice.reducer;
