// Import React Icons
import NewQrIcon from "../../../assets/NewQrIcon.webp";
import MyQrCode from "../../../assets/MyQrCode.webp";
import SideBarStatsIcon from "../../../assets/SideBarStatsIcon.webp";
import BulkQrGeneration from "../../../assets/BulkQrGeneration.webp";
import SideBarTemplateIcon from "../../../assets/SideBarTemplateIcon.webp";
import MyDomainIcon from "../../../assets/MyDomainIcon.webp";
import dollar from "../../../assets/dollar 1.webp";

const sidebarData = [
  {
    icon: <img src={NewQrIcon} />, // Icon for New QR
    title: "New QR",
    route: "/dashboard",
  },
  {
    icon: <img src={BulkQrGeneration} />, // Icon for New QR
    title: "Bulk QR Generation",
    route: "/bulkqrgeneration",
  },
  {
    icon: <img src={MyQrCode} />, // Icon for My QR Codes
    title: "My QR Code",
    route: "/my-qr-codes",
  },
  {
    icon: <img src={SideBarStatsIcon} />, // Icon for Stats
    title: "Stats",
    route: "/stats",
  },
  {
    icon: <img src={SideBarTemplateIcon} />, // Icon for Template
    title: "Template",
    route: "/template",
  },
  {
    icon: <img src={MyDomainIcon} />, // Icon for My Domains
    title: "My Domains",
    route: "/my-domain",
  },
  {
    icon: <img src={dollar} />, // Icon for Plans & Payments
    title: "Plan & Payments",
    route: "/plans",
  },
];

export default sidebarData;
