import React, { useEffect } from "react";
import { FaCaretRight } from "react-icons/fa";
import { useValidContext, ValidContext } from "../../../context/validCtx"; // Importing the context hook

function BasicInformationWrapper({
  title,
  children,
  isOpen,
  setIsOpen,
  setNestedOpen,
  nestedOpen,
}) {
  const cardName = location.pathname?.split("/")[2];
  const { userTrigeredCard } = useValidContext(ValidContext);

  return (
    <div className="w-full p-2 rounded-lg">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center cursor-pointer ${
          isOpen ? "" : "shadow-md py-2"
        }`}
      >
        <div
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-90 transition ease-linear duration-300" : ""
          }`}
        >
          <FaCaretRight className="w-5 h-5 text-teal-400" />
        </div>
        <h3 className="text-sm  text-white !font-[300]">{title}</h3>
      </div>
      <div
        className={`_basicInfo relative ${
          title === "Fonts" && "z-10"
        } transition-[max-height] duration-300 ease-in rounded-lg`}
      >
        <div
          className={`${
            isOpen ? "visible h-[fit-content]" : "h-[0px] invisible"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default BasicInformationWrapper;
