import React, { useState, useEffect } from 'react';
import { XCircle } from 'lucide-react';
import { useNavigate } from 'react-router';

const TrialExpiryModal = ({ days, hours, minutes, seconds, setModalState }) => {
    const navigate = useNavigate()
    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[rgba(0,0,0,0.7)] bg-opacity-50">
            <div className="bg-[var(--darker-bg)] rounded-2xl shadow-xl max-w-md w-full p-6">
                <h2 className="text-center text-xl font-medium mb-6 text-white">Your trial plan will expire in</h2>

                <div className="flex justify-center space-x-6 text-center mb-4">
                    <div>
                        <div className="text-3xl font-bold text-white">{days}</div>
                        <div className="text-sm text-gray-300">DAYS</div>
                    </div>
                    <div>
                        <div className="text-3xl font-bold text-white">{hours}</div>
                        <div className="text-sm text-gray-300">HOURS</div>
                    </div>
                    <div>
                        <div className="text-3xl font-bold text-white">{minutes}</div>
                        <div className="text-sm text-gray-300">MIN</div>
                    </div>
                    <div>
                        <div className="text-3xl font-bold text-white">{seconds}</div>
                        <div className="text-sm text-gray-300">Sec</div>
                    </div>
                </div>

                <p className="text-sm text-gray-300 text-center mb-4">
                    After that, you will lose access to the following features:
                </p>

                <ul className="text-sm text-gray-700 space-y-3 mb-6">
                    <li className="flex items-start space-x-2">
                        <XCircle className="text-red-500 w-5 h-5 mt-0.5" />
                        <span className='text-gray-300'><strong>Your dynamic QR codes</strong> will no longer be scannable.</span>
                    </li>
                    <li className="flex items-start space-x-2">
                        <XCircle className="text-red-500 w-5 h-5 mt-0.5" />
                        <span className='text-gray-300'>You will <strong>not be able to create</strong> new QR codes or edit existing ones.</span>
                    </li>
                    <li className="flex items-start space-x-2">
                        <XCircle className="text-red-500 w-5 h-5 mt-0.5" />
                        <span className='text-gray-300'>You will <strong>lose access to all</strong> of your tracking metrics.</span>
                    </li>
                    <li className="flex items-start space-x-2">
                        <XCircle className="text-red-500 w-5 h-5 mt-0.5" />
                        <span className='text-gray-300'><strong>Downloads</strong> will no longer be available.</span>
                    </li>
                </ul>

                <p className="text-xs text-gray-300 text-center mb-4">
                    To continue using QRfy without interruption, upgrade to one of our plans.
                </p>

                <div className="flex justify-between gap-4">
                    <button
                        onClick={() => setModalState(false)}
                        className="w-full border border-gray-300 rounded-full py-2 text-gray-300 font-medium hover:border-[var(--primary)] hover:text-[var(--primary)] cursor-pointer"
                    >
                        Close
                    </button>
                    <button
                        className="w-full bg-[var(--primary)] rounded-full py-2 font-medium hover:bg-blue-700 cursor-pointer"
                        onClick={() => navigate("/plans")}>
                        Upgrade now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TrialExpiryModal;
