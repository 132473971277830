import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getStatsData
 * @description API call to fetch stats data
 */
const getStatsData = (builder) => {
  return builder.query({
    query: (payload) => ({
      url: "/qr-scan-log/analytics",
      method: "POST",
      data: payload,
    }),
  });
};

// Create Stats API slice
const statsApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.STATS_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getStatsData: getStatsData(builder),
  }),
});

// Export hooks for use in components
const {
  useGetStatsDataQuery,
} = statsApi;

export {
  statsApi,
  useGetStatsDataQuery,
};
