import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getMetaData
 * @description API call to fetch meta data
 *
 * @param payload: object
 */
const getMetaData = (builder) => {
  return builder.query({
    query: (params = {}) => ({
      url: "/getMetadata",
      method: "POST",
      params,
    }),
  });
};

// Create Meta Data API slice
const metaDataApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.META_DATA_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMetaData: getMetaData(builder),
  }),
});

// Export hooks for using in components
const { useGetMetaDataQuery } = metaDataApi;

export {
  metaDataApi,
  useGetMetaDataQuery,
};
