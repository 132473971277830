import React from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

function SettingSkeleton({ baseStyle }) {
  return (
    <div className="p-6">
      {/* Left Sidebar Menu */}
      <div className="fixed left-72 top-16 bottom-0 w-64  border-r p-4">
        <Skeleton
          width={140}
          height={24}
          {...baseStyle}
          className="!rounded-md"
        />
        <div className="space-y-4">
          {[
            "Tax Information",
            "Tracking Analytics",
            "API Key",
            "Notifications",
            "Subscribed Plans",
          ].map((_, i) => (
            <Skeleton
              key={i}
              width={120}
              height={20}
              {...baseStyle}
              className="!rounded-md"
            />
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="ml-64">
        {/* Contact Information Section */}
        <div className=" rounded-lg border  p-6 mb-6">
          <Skeleton
            width={180}
            height={24}
            {...baseStyle}
            className="!rounded-md mb-6"
          />

          <div className="grid grid-cols-2 gap-6">
            {/* First Name & Last Name */}
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>

            {/* Email & Phone */}
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>

            {/* Password & Timezone */}
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>
            <div>
              <Skeleton
                width={80}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton height={40} {...baseStyle} className="!rounded-md" />
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <Skeleton
              width={100}
              height={40}
              {...baseStyle}
              className="!rounded-md"
            />
          </div>
        </div>

        {/* Account Status Section */}
        <div className="rounded-lg border p-6">
          <Skeleton
            width={140}
            height={24}
            {...baseStyle}
            className="!rounded-md mb-4"
          />
          <div className="flex justify-between items-center">
            <Skeleton
              width={300}
              height={20}
              {...baseStyle}
              className="!rounded-md"
            />
            <Skeleton
              width={120}
              height={40}
              {...baseStyle}
              className="!rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingSkeleton;
