import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { qrApi } from "../../services";

const generatedQR = createSlice({
  name: "myQrCodes",
  initialState: {
    qrCodes: [],
    createQrPayload: {
      name: "createQrFormData",
    },
    totalQrCodes: 0,
  },
  reducers: {
    setQRs: (state, action) => {
      const data = action.payload;
      state.qrCodes = data;
    },
    addQR: (state, action) => {
      const data = action.payload;
      state.qrCodes?.push(data);
    },
    setCreateQrPayload: (state, action) => {
      const { key, payload } = action.payload;
      console.log(key, payload);
      if (key) {
        state.createQrPayload[key] = payload;
      } else {
        state.createQrPayload = payload;
      }
    },
    setTotalQR: (state, action) => {
      state.totalQrCodes = action.payload;
    },
  },
});

const { setQRs, addQR, setCreateQrPayload, setTotalQR } = generatedQR.actions;

// export const generateQRCodeThunk = (data) => async (dispatch) => {
//   dispatch(setLoading());
//   try {
//     const result = await dispatch(
//       qrApi.endpoints.generateQRCOde?.initiate(data)
//     );
//     dispatch(setSuccess());
//     dispatch(pushQRCode(result?.data));
//     console.log(result?.data);
//   } catch (error) {
//     dispatch(setError(error?.message));
//     console.log(error?.message);
//   }
// };

export { addQR, setQRs, setCreateQrPayload, setTotalQR };
export default generatedQR.reducer;
