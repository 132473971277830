import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";


/*
 * @private
 * @name contactSend
 * @description API call to send a contact message
 *
 * @param payload: object
 */
const contactSend = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/contactSend`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name sendMailQuery
 * @description API call to send a mail query
 *
 * @param payload: object
 */
const sendMailQuery = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/send-mail-query`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// Create contact API slice
const contactApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.CONTACT_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    contactSend: contactSend(builder),
    sendMailQuery: sendMailQuery(builder),
  }),
});

// Export hooks for using in components
const {
  useContactSendMutation,
  useSendMailQueryMutation,
} = contactApi;

export {
  contactApi,
  useContactSendMutation,
  useSendMailQueryMutation,
};
