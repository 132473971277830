import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getFolderData
 * @description API call to fetch folder data
 *
 * @param payload: object
 */
const getAllFolders = (builder) => {
  return builder.mutation({
    query(payload) {
      console.log("payload", payload);
      return {
        url: `/qr-folders/list?id=${payload.id}`,
        method: "GET",
        // data: payload,
      };
    },
    providesTags: [STORE.TAGS.FOLDER_TAG],
  });
};

/*
 * @private
 * @name getFolderStoreData
 * @description API call to store folder data
 *
 * @param payload: object
 */
const getFolderStoreData = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-folders`,
        method: "POST",
        data: payload,
      };
    },
    invalidatesTags: [STORE.TAGS.FOLDER_TAG],
  });
};

/*
 * @private
 * @name getFolderStoreData
 * @description API get QRCodes by Folder ID
 *
 * @param payload: object
 */
const getQRCodesByFolderID = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-folders/${payload.id}`,
        method: "GET",
      };
    },
    invalidatesTags: [STORE.TAGS.FOLDER_TAG],
  });
};

/*
 * @private
 * @name getFolderData
 * @description API call to edit folder data
 *
 * @param payload: object
 */
const editFolder = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-folders/${payload.id}`,
        method: "PATCH",
        data: payload.data,
      };
    },
    providesTags: [STORE.TAGS.FOLDER_TAG],
  });
};

/*
 * @private
 * @name getFolderData
 * @description API call to delete folder data
 *
 * @param payload: object
 */
const deleteFolder = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-folders/${payload.id}`,
        method: "DELETE",
      };
    },
    providesTags: [STORE.TAGS.FOLDER_TAG],
  });
};

// Create folder API slice
const folderApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.FOLDER_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [STORE.TAGS.FOLDER_TAG],
  endpoints: (builder) => ({
    getAllFolders: getAllFolders(builder),
    getFolderStoreData: getFolderStoreData(builder),
    editFolder: editFolder(builder),
    deleteFolder: deleteFolder(builder),
    getQRCodesByFolderID: getQRCodesByFolderID(builder),
  }),
});

// Export hooks for using in components
const {
  useGetAllFoldersMutation,
  useGetFolderStoreDataMutation,
  useGetQRCodesByFolderIDMutation,
  useEditFolderMutation,
  useDeleteFolderMutation,
} = folderApi;

export {
  folderApi,
  useGetAllFoldersMutation,
  useGetFolderStoreDataMutation,
  useGetQRCodesByFolderIDMutation,
  useEditFolderMutation,
  useDeleteFolderMutation,
};
