// React Imports
import { jwtVerify } from "jose";
import { createContext, useContext, useEffect, useState } from "react";
import { useGetUserDetailsMutation } from "../services/auth";
import { useNavigate } from "react-router";
// Creating the Auth Context
const AuthContext = createContext();
// Custom Hook
export const useAuth = () => useContext(AuthContext);
// States
// const localUser = localStorage.getItem("user");
// const parsedUser = localUser && JSON.parse(localUser) || null;


export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isAuthCall, setIsAuthCall] = useState(false)
    // Login handler
    const doLogin = (user) => {
        setIsAuthCall(true);
    };
    // Reset Password Handler
    const doResetPassword = ({ userData }) => {
        setUser({ userData });
    };
    // Register Handler
    const doSignup = async (user) => {
        setIsAuthCall(true);
    };
    // Logout Handler
    const doLogout = () => {
        setUser(null);
        localStorage.clear();
        window.location.href = "/login";
    };
    // Get User Info
    // const [getUserDetails] = useGetUserDetailsMutation();
    // const getUserData = async () => {
    //     if (!JSON.parse(localStorage.getItem("user"))) {
    //         console.log("Token not found ");
    //         return
    //     };
    //     const { accessToken } = JSON.parse(localStorage.getItem("user"));
    //     console.log("ACCESS_TOKEN==>", accessToken);

    //     if (!accessToken) return;
    //     const secret = new TextEncoder().encode(import.meta.env.VITE_APP_JWT_SECRET);
    //     const { payload: decoded } = await jwtVerify(accessToken, secret);
    //     console.log("decodedUser==>", decoded);

    //     const data = await getUserDetails(decoded?.id);

    //     if (data) {
    //         console.log("Data ===>", data);

    //         setUser(data?.data);
    //     }
    // }

    // useEffect(() => {
    //     if (isAuthCall) {
    //         console.log("AuthProvider component is mounted.", user);
    //         getUserData();
    //     }
    // }, [isAuthCall]);

    const value = {
        user,
        doLogin,
        doSignup,
        doLogout,
        doResetPassword,
        isAuthenticated: !!user,
        loading: initialLoading,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};