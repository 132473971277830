import React from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

function SidebarSkeleton() {
    const baseStyle = {
        baseColor: "#111b22",
        highlightColor: "#233a49",
        borderRadius: "0.375rem", // Tailwind rounded-md
        duration: 0.5,
    };

    return (
        <div className="w-70  min-h-screen bg-[#1B2C37]">
            {/* Back Button */}
            <div className="h-12 flex justify-end pr-3 mr-5 pt-3">
                <Skeleton width={28} height={28} {...baseStyle} />
            </div>

            {/* Logo Section */}
            <div className="px-6 pt-8 pb-16 ml-20">
                <Skeleton width={75} height={30} {...baseStyle} />
            </div>

            {/* Navigation Items */}
            <div className="px-6 space-y-8">
                {/* New QR */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={60} height={18} {...baseStyle} />
                </div>

                {/* My QR Codes */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={90} height={18} {...baseStyle} />
                </div>

                {/* Stats */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={40} height={18} {...baseStyle} />
                </div>

                {/* Template */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={70} height={18} {...baseStyle} />
                </div>

                {/* My Domains */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={85} height={18} {...baseStyle} />
                </div>

                {/* Plans & Payments */}
                <div className="flex items-center gap-3">
                    <Skeleton width={22} height={22} {...baseStyle} />
                    <Skeleton width={115} height={18} {...baseStyle} />
                </div>
            </div>
        </div>
    )
}

export default SidebarSkeleton