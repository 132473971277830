import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ContactUsSkeleton({ baseStyle }) {
  return (
    <div className="w-full flex flex-col items-center py-10">
      {/* Title */}
      <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

      {/* Contact Form */}
      <div className="w-full max-w-lg space-y-6">
        <div className="flex items-center justify-between">
          <Skeleton {...baseStyle} width={200} height={30} />
          <Skeleton {...baseStyle} width={150} height={30} />
        </div>

        <Skeleton {...baseStyle} width="100%" height={50} borderRadius={10} />

        <div className="flex items-center justify-between">
          <Skeleton {...baseStyle} width={200} height={30} />
          <Skeleton {...baseStyle} width={200} height={30} />
        </div>

        <Skeleton {...baseStyle} width="100%" height={50} borderRadius={10} />
        <Skeleton {...baseStyle} width="100%" height={200} borderRadius={10} />

        {/* Map */}
        <Skeleton {...baseStyle} width="100%" height={250} borderRadius={10} />
      </div>
    </div>
  );
}

export default ContactUsSkeleton;
