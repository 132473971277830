import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getMyDomainData
 * @description API call to fetch my domain data
 *
 * @param payload: object
 */
const getMyDomainData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/domains",
      method: "POST",
      data: payload,
    }),
  });
};

/*
 * @private
 * @name getMyDomainStoreData
 * @description API call to store my domain data
 *
 * @param payload: object
 */
const getMyDomainStoreData = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/domain/store",
      method: "POST",
      data: payload,
    }),
  });
};

// Create My Domain API slice
const myDomainApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.MY_DOMAIN_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMyDomainData: getMyDomainData(builder),
    getMyDomainStoreData: getMyDomainStoreData(builder),
  }),
});

// Export hooks for using in components
const {
  useGetMyDomainDataQuery,
  useGetMyDomainStoreDataMutation,
} = myDomainApi;

export {
  myDomainApi,
  useGetMyDomainDataQuery,
  useGetMyDomainStoreDataMutation,
};
