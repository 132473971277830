import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";
import { plansApi } from "../../services/plans";
import {
  getCountryCode,
  getCountryCurrency,
} from "../../constants/CountryList";

const plansSlice = createSlice({
  name: STORE.SLICES.PLANS_SLICE,
  initialState: {
    plans: [],
    selectedPlan: null,
    error: "",
    isError: false,
    isLoading: false,
    currencyType: {
      code: "",
      symbol: "",
    },
    selectedPlanTotalAmount: 0,
    selectedPlanBasePrice: 0,
    countryCode: "",
  },
  reducers: {
    setValuesInPlansState: (state, action) => {
      const nameOfStateField = action.payload?.name;
      const data = action.payload?.data;
      state[nameOfStateField] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPlansData.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPlansData.rejected, (state, action) => {
        state.error = action.error;
        state.isError = true;
      });
  },
});

export const { setValuesInPlansState } = plansSlice.actions;

// fetch Plans Data
export const fetchPlansData = createAsyncThunk(
  "plans/fetchPlansData",
  async (ipAddress, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(
        plansApi.endpoints.getPlansByIPAddress.initiate(ipAddress)
      ).unwrap();
      if (response) {
        console.log("Plans Response", response);
        dispatch(updateCurrencyType(response[0].currency));
        dispatch(
          setValuesInPlansState({
            name: "countryCode",
            data: response[0].countryCode,
          })
        );
      }
      return response;
    } catch (error) {
      console.log("Plans Error", error);
      return rejectWithValue(error.message || "Failed to fetch user details");
    }
  }
);

// Extract Currency Symbol
export const updateCurrencyType = (currencyCode) => (dispatch) => {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
  }).format(0);
  const symbol = formatted.replace(/[\d\.,\s]/g, "");
  dispatch(
    setValuesInPlansState({
      name: "currencyType",
      data: { code: currencyCode, symbol: symbol },
    })
  );
};

// Calculate total Amount with tax
export const calculateTotalAmount = () => async (dispatch, getState) => {
  const currentState = getState();
  const userData = currentState?.root?.USER_DATA_SLICE;
  const plansData = currentState?.root?.PLANS_SLICE;
  const invoiceData = currentState?.root?.INVOICE_SLICE;
  const payload = {
    user_id: userData?.user?.id,
    plan_id: plansData?.selectedPlan?.id,
    country_code: plansData?.countryCode,
    currency: plansData?.currencyType?.code,
    address: {
      address: invoiceData?.data?.address,
      city: invoiceData?.data?.city,
      postal_code: invoiceData?.data?.postal_code,
      country: invoiceData?.countryCode,
    },
  };
  try {
    const response = await dispatch(
      plansApi.endpoints.calculateTax.initiate(payload)
    ).unwrap();
    if (response) {
      const data = response;
      const basePrice = data?.plan?.price;
      const tax = data?.tax;
      const discountPercent = response?.discount?.discountPercent;
      const discount = (discountPercent / 100) * basePrice;
      const totalAmount = basePrice + tax - discount;
      dispatch(
        setValuesInPlansState({
          name: "selectedPlanBasePrice",
          data: basePrice,
        })
      );
      dispatch(updateCurrencyType(response?.plan?.currency));
      dispatch(
        setValuesInPlansState({
          name: "selectedPlanTotalAmount",
          data: totalAmount?.toFixed(2),
        })
      );
    }
  } catch (error) {
    console.log("Error in calculateTotalAmount", error);
  }
};

// Update Country Code and Currency Type
export const updateCountryCode = (country) => (dispatch) => {
  console.log("Country", country);
  const code = getCountryCode(country);
  const currency = getCountryCurrency(country);
  if (code && currency) {
    dispatch(setValuesInPlansState({ name: "countryCode", data: code }));
    dispatch(updateCurrencyType(currency));
  }
};
export default plansSlice.reducer;
