import axios from "axios";

// Instance of axios with default configuration
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (res) => {
    return {
      data: res?.data,
      meta: res?.headers.get("total-count"),
    };
  },
  (err) => {
    const res = err.response;
    const errors = res?.data?.errors;

    // Check if errors is defined and not an empty array
    const msg = errors ? errors[0] : res?.data?.error;

    const status = res?.status ? res.status : 0;

    return Promise.reject(res);
  }
);
export default axiosInstance;
