import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function QrDetailsSkeleton({ baseStyle }) {
  return (
    <div className="w-full bg-gray-900 text-white p-6">
      {/* Header section */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <Skeleton {...baseStyle} width={80} height={24} />
          <div className="flex items-center space-x-2">
            <Skeleton
              {...baseStyle}
              width={24}
              height={24}
              className="rounded-md"
            />
            <Skeleton {...baseStyle} width={100} height={24} />
          </div>
          <div className="flex items-center space-x-2">
            <Skeleton
              {...baseStyle}
              width={24}
              height={24}
              className="rounded-full"
            />
            <Skeleton {...baseStyle} width={80} height={24} />
          </div>
        </div>

        <div className="flex space-x-3">
          <Skeleton
            {...baseStyle}
            width={120}
            height={40}
            className="rounded-md"
          />
          <Skeleton
            {...baseStyle}
            width={120}
            height={40}
            className="rounded-md"
          />
          <Skeleton
            {...baseStyle}
            width={120}
            height={40}
            className="rounded-md"
          />
        </div>
      </div>

      {/* Main metrics section */}
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <div className="grid grid-cols-5 gap-4">
          {/* Total Scans */}
          <div className="flex flex-col justify-center items-center">
            <Skeleton
              {...baseStyle}
              width={60}
              height={60}
              className="text-teal-400 mb-2"
            />
            <Skeleton {...baseStyle} width={120} height={20} className="mb-1" />
            <Skeleton {...baseStyle} width={80} height={16} />
          </div>

          {/* Medium */}
          <div className="flex flex-col justify-center items-center">
            <div {...baseStyle} className="rounded-full p-3 mb-2">
              <Skeleton
                {...baseStyle}
                width={40}
                height={40}
                className="rounded-md"
              />
            </div>
            <Skeleton {...baseStyle} width={80} height={20} className="mb-1" />
            <Skeleton {...baseStyle} width={60} height={16} />
          </div>

          {/* Print run */}
          <div className="flex flex-col justify-center items-center">
            <div className=" rounded-full p-3 mb-2">
              <Skeleton
                {...baseStyle}
                width={40}
                height={40}
                className="rounded-md"
              />
            </div>
            <Skeleton {...baseStyle} width={80} height={20} className="mb-1" />
            <Skeleton {...baseStyle} width={60} height={16} />
          </div>

          {/* Start of Campaign */}
          <div className="flex flex-col justify-center items-center">
            <div className=" rounded-full p-3 mb-2">
              <Skeleton
                {...baseStyle}
                width={40}
                height={40}
                className="rounded-md"
              />
            </div>
            <Skeleton {...baseStyle} width={140} height={20} className="mb-1" />
            <Skeleton {...baseStyle} width={100} height={16} />
          </div>

          {/* End of Campaign */}
          <div className="flex flex-col justify-center items-center">
            <div className=" rounded-full p-3 mb-2">
              <Skeleton
                {...baseStyle}
                width={40}
                height={40}
                className="rounded-md"
              />
            </div>
            <Skeleton {...baseStyle} width={140} height={20} className="mb-1" />
            <Skeleton {...baseStyle} width={100} height={16} />
          </div>
        </div>
      </div>

      {/* Export button */}
      <div className="flex justify-end mb-6">
        <Skeleton
          {...baseStyle}
          width={180}
          height={40}
          className="rounded-md"
        />
      </div>

      {/* Date range and options selectors */}
      <div className="flex justify-between mb-6">
        <Skeleton
          {...baseStyle}
          width={200}
          height={40}
          className="rounded-md"
        />
        <Skeleton
          {...baseStyle}
          width={100}
          height={40}
          className="rounded-md"
        />
      </div>

      {/* Scan activities section */}
      <div className="mb-6">
        <Skeleton {...baseStyle} width={120} height={24} className="mb-4" />

        <div className="flex items-center space-x-4 mb-4">
          <div className="flex items-center space-x-2">
            <Skeleton
              {...baseStyle}
              width={20}
              height={20}
              className="rounded-sm"
            />
            <Skeleton {...baseStyle} width={60} height={16} />
          </div>
          <div className="flex items-center space-x-2">
            <Skeleton
              {...baseStyle}
              width={20}
              height={20}
              className="rounded-sm"
            />
            <Skeleton {...baseStyle} width={70} height={16} />
          </div>
          <div className="flex items-center space-x-2">
            <Skeleton
              {...baseStyle}
              width={20}
              height={20}
              className="rounded-sm"
            />
            <Skeleton {...baseStyle} width={140} height={16} />
          </div>

          <div className="ml-auto flex space-x-2">
            <Skeleton
              {...baseStyle}
              width={60}
              height={30}
              className="rounded-md"
            />
            <Skeleton
              {...baseStyle}
              width={60}
              height={30}
              className="rounded-md"
            />
            <Skeleton
              {...baseStyle}
              width={60}
              height={30}
              className="rounded-md"
            />
          </div>
        </div>

        {/* Chart area */}
        <div className="h-40 w-full rounded-lg mb-4 relative">
          <Skeleton
            {...baseStyle}
            width="100%"
            height="100%"
            className="rounded-lg"
          />
          <div className="absolute bottom-0 left-0 right-0 flex justify-center">
            <Skeleton {...baseStyle} width={60} height={16} />
          </div>
        </div>
      </div>

      {/* Scans by operating system */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <Skeleton {...baseStyle} width={200} height={24} />
          <Skeleton
            {...baseStyle}
            width={24}
            height={24}
            className="rounded-md"
          />
        </div>

        {/* Table header */}
        <div className="grid grid-cols-3 mb-2">
          <Skeleton {...baseStyle} width={40} height={20} />
          <Skeleton {...baseStyle} width={150} height={20} />
          <Skeleton
            {...baseStyle}
            width={60}
            height={20}
            className="justify-self-end"
          />
        </div>

        {/* Table row */}
        <div className="grid grid-cols-3 items-center">
          <Skeleton {...baseStyle} width={20} height={20} />
          <Skeleton {...baseStyle} width={100} height={20} />
          <div className="w-full flex items-center justify-between">
            <Skeleton
              {...baseStyle}
              width="80%"
              height={16}
              className="rounded-full"
            />
            <Skeleton {...baseStyle} width={60} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrDetailsSkeleton;
