import { createContext, useContext, useState } from "react";

export const ValidContext = createContext();

export const ValidProvider = ({ children }) => {
  const [userTrigeredCard, setUserTrigeredCard] = useState("");
  const [isErrorOpen, SetErrorOpen] = useState(false);
  const [Cardname, SetCardname] = useState("BasicInformation");
  const [isDisableSubmitButton, setIsDisableSubmitButton] = useState(false);

  return (
    <ValidContext.Provider
      value={{
        Cardname,
        SetCardname,
        isErrorOpen,
        SetErrorOpen,
        userTrigeredCard,
        setUserTrigeredCard,
        isDisableSubmitButton,
        setIsDisableSubmitButton,
      }}
    >
      {children}
    </ValidContext.Provider>
  );
};

// Custom hook to access context
export const useValidContext = () => {
  return useContext(ValidContext);
};
