import React from "react";

const Checkbox = ({ checked, onChange, label, className = "" }) => {
  return (
    <label className={`flex items-center gap-2 cursor-pointer ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)} // Ensure correct event handling
        className="hidden"
      />
      <div
        className={`w-5 h-5 border rounded flex items-center justify-center transition-all ${
          checked
            ? "border-teal-400 bg-teal-400"
            : "border-[#03DAC5] bg-transparent"
        }`}
      >
        {checked && (
          <svg
            className="w-4 h-4 text-white "
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        )}
      </div>
      {label && <span className="text-[var(--white)] !font-[300] text-sm">{label}</span>}
    </label>
  );
};

export default Checkbox;
