import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";
import { plansApi } from "../../services/plans";
import { setValuesOfUserData } from "./userSlice";

const subscriptionSlice = createSlice({
  name: STORE.SLICES.SUBSCRIPTION_SLICE,
  initialState: {
    allSubscriptions: null,
    currentSubscriptionDetails: null,
    currentSubscriptionId: null,
    isFreeTrialValid: false,
    createdAt: null,
    type: null,
    trial_ends_at: null,
    trial_remaining_time: null,
    isSubscriptionActive: false,
    currentSubscriptionStatus: null,
  },
  reducers: {
    setValueInSubscriptionSlice: (state, action) => {
      const nameOfState = action.payload?.name;
      const value = action.payload?.data;
      state[nameOfState] = value;
    },
  },
});

export const { setValueInSubscriptionSlice } = subscriptionSlice.actions;

export const getUserCurrentSubscription = () => async (dispatch, getState) => {
  const state = getState()
  const userId = state?.root?.USER_DATA_SLICE?.user?.id;
  try {
    const response = await dispatch(plansApi.endpoints.getSubscriptions.initiate(userId)).unwrap();
    console.log("SUBSCRIPTION_RESPONSE==>", response);
    if (response) {
      if (response?.length > 0) {
        console.log("Last Subsscription==>", response[response?.length - 1]);
        dispatch(setValueInSubscriptionSlice({ name: "allSubscriptions", data: response }));
        dispatch(setValuesOfUserData({ name: "isPremiumActive", data: true }));
        dispatch(setValueInSubscriptionSlice({ name: "currentSubscriptionDetails", data: response[response?.length - 1] }))
        dispatch(setValueInSubscriptionSlice({ name: "currentSubscriptionId", data: response[response?.length - 1]?.stripe_id }));
        dispatch(setValueInSubscriptionSlice({ name: "currenttSubscriptionStatus", data: response[response?.length - 1]?.stripe_status }));
      } else {
        dispatch(setValueInSubscriptionSlice({ name: "allSubscriptions", data: [] }));
        dispatch(setValueInSubscriptionSlice({ name: "currentSubscriptionDetails", data: null }));
        dispatch(setValueInSubscriptionSlice({ name: "currentSubscriptionId", data: null }));
        dispatch(setValuesOfUserData({ name: "isPremiumActive", data: false }));
      };
    }
  } catch (error) {
    console.log("SUBSCRIPTION_ERROR==>", error);
  }
}

export const calculateTrialRemainingTime = () => (dispatch, getState) => {
  const state = getState();
  const remainingTime = state?.root?.SUBSCRIPTION_SLICE?.trial_remaining_time;
  let trialInterval;
  if (remainingTime > 0) {
    trialInterval = setInterval(() => {
      const currentState = getState();
      const current_remaining_time = currentState?.root?.SUBSCRIPTION_SLICE?.trial_remaining_time;
      if (current_remaining_time > 0) {
        dispatch(setValueInSubscriptionSlice({
          name: "trial_remaining_time",
          data: current_remaining_time - 1000
        }));
      } else {
        clearInterval(trialInterval);
        trialInterval = null;
        dispatch(setValueInSubscriptionSlice({
          name: "trial_remaining_time",
          data: 0
        }))
        dispatch(setValueInSubscriptionSlice({
          name: "isTrialValid",
          data: false
        }))
      }
    }, 1000);
  }
}

export default subscriptionSlice.reducer;
