import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../actions/authActions";
import STORE from "../../constants/STORE";

// initialize states
const initialState = {
  loading: false,
  adminInfo: null,
  adminToken: null,
  error: null,
  success: false,
  isSidebarOpen: true,
  welcomeScreen: null,
  publicQrCodeActiveNav: null,
  publicQrCodeActiveFrame: null
};

const authSlice = createSlice({
  name: STORE.SLICES.AUTH_SLICE,
  initialState: {
    ...initialState,
    userToken:
      typeof window !== "undefined"
        ? window.localStorage.getItem("userToken")
        : false,
  },
  reducers: {
    logout: (state) => {
      const itemsToRemove = ["adminInfo", "adminToken"];
      itemsToRemove.forEach((item) => localStorage.removeItem(item));
      state.adminInfo = null;
      state.adminToken = null;
    },
    setAdminCredentials: (state, { payload }) => {
      state.adminInfo = payload;
    },
    setAdminToken: (state, { payload }) => {
      state.adminToken = payload;
    },
    setIsSidebarOpen: (state, { payload }) => {
      state.isSidebarOpen = payload;
    },
    setWelcomeScreen: (state, { payload }) => {
      state.welcomeScreen = {
        image: payload.image,
        time: payload.time
      };
    },
    setPublicQrCodeActiveNav: (state, { payload }) => {
      state.publicQrCodeActiveNav = payload;  
    },
    setPublicQrCodeActiveFrame: (state, { payload }) => {
      state.publicQrCodeActiveFrame = payload;  
    },
  },
  extraReducers: (builder) => {
    // login admin
    builder.addCase(adminLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.adminInfo = payload;
      state.adminToken = payload.token;
      localStorage.setItem("adminInfo", JSON.stringify(payload));
    });

    builder.addCase(adminLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { logout, setCredentials, setToken, setAdminCredentials, setAdminToken, setType, setIsSidebarOpen, setWelcomeScreen, setPublicQrCodeActiveNav, setPublicQrCodeActiveFrame } = authSlice.actions;
export default authSlice.reducer;
