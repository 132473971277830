import React from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
function PlanSkeleton({ baseStyle }) {
  return (
    <div className="p-6 bg-[#0B1219]">
      <Skeleton
        width={400}
        height={28}
        {...baseStyle}
        className="!rounded-md mb-8"
      />

      {/* Plans Grid */}
      <div className="flex gap-6 mb-6">
        {/* Features List Card */}
        <div className="bg-[#1B2C37] p-6 rounded-lg w-80">
          {[...Array(9)].map((_, i) => (
            <div key={i} className="flex items-center gap-2 mb-4">
              <Skeleton circle width={20} height={20} {...baseStyle} />
              <Skeleton
                width={200}
                height={20}
                {...baseStyle}
                className="!rounded-md"
              />
            </div>
          ))}
        </div>

        {/* Plan Cards */}
        <div className="flex gap-6 flex-1">
          {[
            { duration: "1 month", billing: "Billed monthly" },
            {
              duration: "12 months",
              billing: "Billed annually",
              tag: "Best Value",
            },
            { duration: "24 months", billing: "Billed bi-annually" },
          ].map((plan, i) => (
            <div
              key={i}
              className="bg-[#1B2C37] border border-[#243447] rounded-lg p-6 flex-1"
            >
              <div className="flex justify-between items-start mb-4">
                <Skeleton
                  width={20}
                  height={20}
                  {...baseStyle}
                  className="!rounded-md"
                />
                {i === 1 && (
                  <Skeleton
                    width={80}
                    height={24}
                    {...baseStyle}
                    className="!rounded-full"
                  />
                )}
              </div>
              <Skeleton
                width={120}
                height={32}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton
                width={100}
                height={20}
                {...baseStyle}
                className="!rounded-md mb-6"
              />
              <Skeleton
                width={140}
                height={24}
                {...baseStyle}
                className="!rounded-md mb-2"
              />
              <Skeleton
                width={100}
                height={20}
                {...baseStyle}
                className="!rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      <Skeleton
        width={260}
        height={20}
        {...baseStyle}
        className="!rounded-md mb-12"
      />

      {/* FAQ Section */}
      <div className="space-y-4">
        <Skeleton
          width={280}
          height={32}
          {...baseStyle}
          className="!rounded-md mb-6"
        />

        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="bg-[#1B2C37] border border-[#243447] rounded-lg"
          >
            <div className="p-4 flex justify-between items-center">
              <Skeleton
                width={300}
                height={24}
                {...baseStyle}
                className="!rounded-md"
              />
              <Skeleton
                width={24}
                height={24}
                {...baseStyle}
                className="!rounded-md"
              />
            </div>
            {i === 0 && (
              <div className="px-4 pb-4">
                <Skeleton height={40} {...baseStyle} className="!rounded-md" />
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Add Button */}
      <div className="flex justify-end mt-6">
        <Skeleton
          width={100}
          height={40}
          {...baseStyle}
          className="!rounded-md"
        />
      </div>
    </div>
  );
}

export default PlanSkeleton;
