import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getMyDomainData
 * @description API call to fetch all dashboard cards
 *
 * @param payload: object
 */
const getCardTypes = (builder) => {
  return builder.mutation({
    query: (payload = {}) => ({
      url: "/qr-types",
      method: "GET",
      data: payload,
    }),
  });
};

// Create My Domain API slice
const dashboardApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.DASHBOARD_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCardTypes: getCardTypes(builder),
  }),
});

// Export hooks for using in components
const { useGetCardTypesMutation } = dashboardApi;

export { dashboardApi, useGetCardTypesMutation };
