// Product Images
import dummyBagImg from "../../assets/cardsMockImages/product/dummyBag.webp";

// Menu Images
import menuLogoImg from "../../assets/cardsMockImages/menu/menuLogo.webp";
import menuBannerImg from "../../assets/cardsMockImages/menu/menuBanner.webp";
import section1Img from "../../assets/cardsMockImages/menu/section1.webp";
import section2Img from "../../assets/cardsMockImages/menu/section2.webp";
import section3Img from "../../assets/cardsMockImages/menu/section3.webp";
import section4Img from "../../assets/cardsMockImages/menu/section4.webp";
import product1Img from "../../assets/cardsMockImages/menu/product1.webp";
import product2Img from "../../assets/cardsMockImages/menu/product2.webp";
import product3Img from "../../assets/cardsMockImages/menu/product3.webp";

// Business Images
import feedbackImg from "../../assets/svg/image.svg";

// List of Links Images
import linksLogo from "../../assets/cardsMockImages/listOfLinks/linksLogo.webp";

// Events Images
import eventBanner from "../../assets/cardsMockImages/event/eventDemoImg.webp";

// LandingPage Images
import landingPageImg from "../../assets/cardsMockImages/landingPage/landingPageImg.webp";

// Playlist Images
import playlistImg from "../../assets/cardsMockImages/playlist/playlistImg.webp";
import playlistSong from "../../assets/cardsMockImages/playlist/demoSong.mp3";

// Mp3 Images
import mp3Banner from "../../assets/cardsMockImages/mp3/mp3Banner.webp";

//Images Card Images
import crouselImg1 from "../../assets/cardsMockImages/images/crouselImg1.webp";
import crouselImg2 from "../../assets/cardsMockImages/images/crouselImg2.webp";
import crouselImg3 from "../../assets/cardsMockImages/images/crouselImg3.webp";

// PDF Images
import PdfbackgroundImg from "../../assets/pdfimagesbg.svg";
import PdfLogoImg from "../../assets/Frame (1).svg";
import dummyPdfFile from "../../assets/cardsMockImages/pdf/dummy.pdf";

export const autoCompleteDataSchema = {
  pdfFiles: [
    {
      file: dummyPdfFile,
      name: "Dummy PDF",
    },
  ],
  pdfCompany: "Explore New Arrivals",
  pdfButtonText: "Download Catalogue ",
  pdfDescription:
    "Our selection of burgers will surprise you. Their flavour will delight you.",
  pdfWebsite: "",
  pdfLogoCoverImage: [
    {
      file: PdfLogoImg,
    },
  ],
  pdfFIleCoverImage: [
    {
      file: PdfbackgroundImg,
    },
  ],
  globalTimeSchedulingName: "",
  globalTimeSchedulingDays: "",
  globalGoogleAnalytics4TrackingID: "",
  globalFacebookID: "",
  globalGoogleTagManagerID: "",
  globalUrl: "",
  globalEnableScanLimit: "",
  globalPassword: "",
  vCardName: "",
  vCardSurname: "",
  vCardPlusAboutYouTitle: "",
  vCardPlusprofileImage: [],
  vCardPlusNumberLabel: "",
  vCardPlusPersonalNumber: "",
  vCardEmailLabel: "",
  vCardPersonalEmail: "",
  vCardwebLabel: "",
  vCardPersonalWebsite: "",
  vCardSearchAddress: "",
  vCardStreet: "",
  vCardNumber: "",
  vCardPostalCode: "",
  vCardCity: "",
  vCardState: "",
  vCardCountry: "",
  vCardUrl: "",
  vCardLatitude: "",
  vCardLongitude: "",
  vCardSummary: "",
  vCardCompanyName: "",
  vCardCompanyProfessionName: "",
  vCardCustomizeYourButtonPhone: "",
  vCardCustomizeYourButtonEmail: "",
  vCardCustomizeYourButtonLocation: "",
  vCardCustomizeYourButtonContact: "",
  vCardSocialNetworksUrl: "",
  vCardSocialNetworksText: "",
  vCardSocialNetworksButtonText: "",
  imagesContentImageTitle: "",
  imagesContentDescription: "",
  imagesContentWebsite: "",
  playlistImg: [
    {
      file: playlistImg,
    },
  ],
  videoCropCompany: "",
  videoTech: "",
  videoDescription: "",
  linkTitle: "Elizabeth Olsen",
  linkDescription:
    "I am a talented actor and model with a passion for storytelling through captivating performances and striking visuals.",
  linkWebsite: "",
  businessCompanyImage: [{ file: feedbackImg }],
  Businessbuttontext: "",
  Businessbuttonurl: "",
  linkContentLogo: [],
  linkContentTitle: "",
  linkContentDescription: "",
  linkContentWebsite: "",
  companyImage: [],
  linkLogo: [
    {
      name: "linksLogo.webp",
      file: linksLogo,
      fileObject: {},
    },
  ],
  businessContentCompany: "Clinic",
  businessContentTitle: "Dr. John’s Clinic",
  businessContentSubtitle:
    "A clinic is a healthcare facility that provides outpatient medical services, consultations, and treatments. Open from Wednesday  to Sunday from 8:00 am to 7:00 pm.",
  businessAdditionalInfo: "",
  businessContactInfoName: "",
  businessContactInfoWebsite: "",
  businessContactInfoTelephone: "",
  businessContactInfoEmail: "",
  globalAppsInfoTitle: "",
  globalAppsInfoDescription: "",
  appsGooglePlayLink: "",
  appsAppleStoreLink: "",
  mp3InfoTitle: "",
  mp3InfoWebsite: "",
  mp3InfoDescription: "",
  menuNameOfEstablishment: "Chef's Place Menu",
  menuDescription: "A complete food station",
  menuSection1Name: "",
  menuSection1Description: "",
  CouponFIleCoverImage: [],
  couponCompany: "Shop Mart",
  couponTitle: "Get 30% on your next buy",
  couponDescription:
    "Not valid with any other discounts and promotions. No cash value.",
  couponSalesBadge: "30",
  couponButtonToSee: "Redeem Now",
  couponCode: "SALE25OFF",
  couponValidUntil: "27/3/2025",
  couponTermsAndConditions: true,
  couponInfoButton: "Redeem now",
  couponInfoURL: "",
  feedbackInfoCompany: "Leather Shoes",
  feedbackInfoTitle: "100",
  feedbackInfoDescription:
    "Leather shoes are durable, stylish footwear made from animal hide, offering comfort and elegance.",
  feedbackCategory: [
    {
      name: "Feedback and Ratings",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing",
      subcategories: [
        {
          name: "Quality",
        },
        {
          name: "Endurance",
        },
      ],
    },
    {
      name: "Budget Friendly",
      description: "",
      subcategories: [
        {
          name: "price",
        },
      ],
    },
  ],
  playlistInfoTitle: "Rock band",
  playlistInfoName: "Kings Of Leon",
  playlistInfoDescription: "",
  barcodeGTIN2d: "",
  barcodeWebsiteUrl2d: "",
  socialMediatitle: "",
  socialMediaDescription: "",
  videobutton: "",
  video: [],
  socialnetworks: [
    {
      name: "socialnetworks",
      platform: {
        id: 1,
        name: "Bitcoin",
        slogan: "Invest in Your Future",
      },
    },
    {
      name: "socialnetworks",
      platform: {
        id: 2,
        name: "Contact",
        slogan: "Get In Touch",
      },
    },
    {
      name: "socialnetworks",
      platform: {
        id: 3,
        name: "Facebook",
        slogan: "Join the Conversation",
      },
    },
    {
      name: "socialnetworks",
      platform: {
        id: 8,
        name: "Paypal",
        slogan: "Secure Payments, Fast",
      },
    },
  ],
  imagePageCard: [
    {
      file: crouselImg1,
      name: "CrouselImg1.webp",
    },
    {
      file: crouselImg2,
      name: "CrouselImg2.webp",
    },
    {
      file: crouselImg3,
      name: "CrouselImg3.webp",
    },
  ],
  Bitcoinurl: "bitcoin.com",
  Bitcointext: "Bitcoin",
  Bitcoinbuttontext: "Bitcoin",
  Contactbuttontext: "Contact",
  Contacttext: "Contact",
  Contacturl: "qr-gen.com",
  Facebookurl: "facebook.com",
  Facebooktext: "Facebook",
  Facebookbuttontext: "Facebook",
  Paypalurl: "paypal.com",
  Paypaltext: "PayPal",
  Paypalbuttontext: "PayPal",
  Linkurl: "",
  Linktext: "",
  Linkbuttontext: "",
  Locationurl: "",
  Locationtext: "",
  Locationbuttontext: "",
  Messageurl: "",
  Messagetext: "",
  Messagebuttontext: "",

  Purpleurl: "",
  Purpletext: "",
  Purplebuttontext: "",
  Whatsappurl: "",
  Whatsapptext: "",
  Whatsappbuttontext: "",
  Wifiurl: "",
  Wifitext: "",
  Wifibuttontext: "",
  socialMediaLogo: [],
  Coverimage: [],
  SocialMediaTitle: "",
  menuCoverImage: [
    {
      name: "image.webp",
      file: menuBannerImg,
      fileObject: {},
    },
  ],
  menuCompanyLogo: [
    {
      name: "5528441 1.webp",
      file: menuLogoImg,
      fileObject: {},
    },
  ],
  videoFiles: [],
  videoUrl: "",
  basicInfoImage: [
    {
      file: landingPageImg,
    },
  ],
  landingpageImages: [
    {
      file: landingPageImg,
    },
  ],
  Landingpagetitle: "Python Online Course",
  Landingpagedescription:
    "Learn Python online with hands-on projects and expert guidance! Build real-world skills and advance your coding journey today!",
  AllDay: false,
  AppbgImages: [],
  AppDeveloperCompany: "Healthy Eats at Your Doorstep!",
  ProductAppName: "Leather Bag",
  productAppLogo: [
    {
      name: "DummyBag.webp",
      file: dummyBagImg,
      fileObject: {},
    },
  ],
  mp3Files: [
    {
      file: playlistSong,
    },
  ],
  mp3CoverImage: [
    {
      file: mp3Banner,
    },
  ],
  eventStartDate: "27/03/2025",
  eventEndDate: "30/03/2025",
  sinceDate: "27/03/2025",
  endDate: "30/03/2025",
  Appdescription:
    "Order nutritious and delicious meals delivered straight to your door. Fresh ingredients, balanced meals, and convenience—all in one app!",
  Applogo: [],
  Appname: "Healthy Food",
  Appwebsite: "Download The App",
  appsAmazonStoreLink: "https://qr-gen.com",
  appsAppStoreLink: "https://qr-gen.com",
  appsPlayStoreLink: "https://qr-gen.com",
  menuSections: [
    {
      name: "Appetizers",
      sectionLogo: {
        name: "image (2).webp",
        file: section1Img,
        fileObject: {},
      },
      products: [
        {
          productLogo: {
            name: "image (6).png",
            file: product1Img,
            fileObject: {},
          },
          productName: "Party Cheese Bread",
          productTranslatedName: "",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "5",
        },
        {
          productLogo: {
            name: "image (5).png",
            file: product2Img,
            fileObject: {},
          },
          productName: "Teriyaki Pineapple Meatballs",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "10",
        },
        {
          productLogo: {
            name: "image (7).png",
            file: product3Img,
          },
          productName: "Hot Spinach Artichoke Dip",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "9",
        },
      ],
    },
    {
      name: "Burger",
      sectionLogo: {
        name: "image (1).webp",
        file: section2Img,
        fileObject: {},
      },
      products: [
        {
          productLogo: {
            name: "image (6).png",
            file: product1Img,
            fileObject: {},
          },
          productName: "Party Cheese Bread",
          productTranslatedName: "",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "5",
        },
        {
          productLogo: {
            name: "image (5).png",
            file: product2Img,
            fileObject: {},
          },
          productName: "Teriyaki Pineapple Meatballs",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "10",
        },
        {
          productLogo: {
            name: "image (7).png",
            file: product3Img,
          },
          productName: "Hot Spinach Artichoke Dip",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "9",
        },
      ],
    },
    {
      name: "Beverages",
      sectionLogo: {
        name: "image (3).webp",
        file: section3Img,
        fileObject: {},
      },
      products: [
        {
          productLogo: {
            name: "image (6).png",
            file: product1Img,
            fileObject: {},
          },
          productName: "Party Cheese Bread",
          productTranslatedName: "",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "5",
        },
        {
          productLogo: {
            name: "image (5).png",
            file: product2Img,
            fileObject: {},
          },
          productName: "Teriyaki Pineapple Meatballs",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "10",
        },
        {
          productLogo: {
            name: "image (7).png",
            file: product3Img,
          },
          productName: "Hot Spinach Artichoke Dip",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "9",
        },
      ],
    },
    {
      name: "Main Course",
      sectionLogo: {
        name: "image (4).webp",
        file: section4Img,
        fileObject: {},
      },
      products: [
        {
          productLogo: {
            name: "image (6).png",
            file: product1Img,
            fileObject: {},
          },
          productName: "Party Cheese Bread",
          productTranslatedName: "",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "5",
        },
        {
          productLogo: {
            name: "image (5).png",
            file: product2Img,
            fileObject: {},
          },
          productName: "Teriyaki Pineapple Meatballs",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "10",
        },
        {
          productLogo: {
            name: "image (7).png",
            file: product3Img,
          },
          productName: "Hot Spinach Artichoke Dip",
          productDescription:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          price: "9",
        },
      ],
    },
  ],
  eventImgUpload: [
    {
      file: eventBanner,
    },
  ],
  contentLinks: [
    {
      name: "",
      website: "",
      logo: [],
    },
  ],
  eventTitle: "Musical Night with Band",
  eventDescription:
    "Experience an enchanting evening filled with soulful melodies, electrifying performances, and a symphony of emotions.",
  eventDateSince: "27/03/2025",
  eventDateUntil: "30/03/2025",
  globalSearchAddress: "Le Meridien Dubai Hotel & Conference Center",
  videoButtonUrl: "",
  allergens: [],
  openingHours: [
    {
      day: "Monday",
      enabled: true,
      start: "08:00 am",
      end: "12:00 pm",
    },
    {
      day: "Tuesday",
      enabled: true,
      start: "05:00 pm",
      end: "08:00 pm",
    },
    {
      day: "Thursday",
      enabled: true,
      start: "01:00 pm",
      end: "06:00 pm",
    },
    {
      day: "Sunday",
      enabled: true,
      start: "12:00 am",
      end: "08:00 pm",
    },
  ],
  imagesContentTitle: "Birthday Party",
  imageContentDescription:
    "Words can't capture the fun and joy of our birthday celebration so we’re sharing the memories with you through these photos!",
  contentButtonText: "",
  contentButtonUrl: "",
  playlistItems: [
    {
      name: "Song 1",
      audioFile: {
        name: "file_example_MP3_700KB.mp3",
        file: playlistSong,
        fileObject: {},
      },
    },
    {
      name: "Song 2",
      audioFile: {
        name: "file_example_MP3_700KB.mp3",
        file: playlistSong,
        fileObject: {},
      },
    },
    {
      name: "Song 3",
      audioFile: {
        name: "file_example_MP3_700KB.mp3",
        file: playlistSong,
        fileObject: {},
      },
    },
  ],
  timePicker: "",
  imageGridType: "",
  Organic: [],
  RecyclingStamps: [],
  Responsibleconsumption: [],
  Certificates: [],
  productCategories: [
    {
      category: "Type of Bag",
      categoryValue: "Leather Bag",
    },
    {
      category: "Varieties",
      categoryValue: "Syrah, Black Grenache",
    },
    {
      category: "Add",
      categoryValue: "2023",
    },
    {
      category: "Region:",
      categoryValue: "Montsant",
    },
  ],
  productIngredients: [],
  nutritionalInformationQualification: "",
  Nutrients: [],
  facilitiesIcon: "Wi-Fi",
  organizationName: "",
  organizationWebsite: "",
  organizationtelephone: "",
  organizationEmail: "",
  shareToggle: false,
  autoComplete: true,
  productDescription: "woo-polo",
  ProductDeveloper: "Company",
  menuSection: [{}],
};

export const initialDataSchema = {
  pdfFiles: [],
  pdfCompany: "",
  pdfButtonText: "",
  pdfDescription: "",
  pdfWebsite: "",
  pdfLogoCoverImage: [],
  pdfFIleCoverImage: [],
  globalTimeSchedulingName: "",
  globalTimeSchedulingDays: "",
  globalGoogleAnalytics4TrackingID: "",
  globalFacebookID: "",
  globalGoogleTagManagerID: "",
  globalUrl: "",
  globalEnableScanLimit: "",
  globalPassword: "",
  vCardName: "",
  vCardSurname: "",
  vCardPlusAboutYouTitle: "",
  vCardPlusprofileImage: [],
  vCardPlusNumberLabel: "",
  vCardPlusPersonalNumber: "",
  vCardEmailLabel: "",
  vCardPersonalEmail: "",
  vCardwebLabel: "",
  vCardPersonalWebsite: "",
  vCardwebLabel: "",
  vCardEmailLabel: "",
  vCardSearchAddress: "",
  vCardStreet: "",
  vCardNumber: "",
  vCardPostalCode: "",
  vCardCity: "",
  vCardState: "",
  vCardCountry: "",
  vCardUrl: "",
  vCardLatitude: "",
  vCardLongitude: "",
  vCardSummary: "",
  vCardCompanyName: "",
  vCardCompanyProfessionName: "",
  vCardCustomizeYourButtonPhone: "",
  vCardCustomizeYourButtonEmail: "",
  vCardCustomizeYourButtonLocation: "",
  vCardCustomizeYourButtonContact: "",
  vCardSocialNetworksUrl: "",
  vCardSocialNetworksText: "",
  vCardSocialNetworksButtonText: "",
  imagesContentImageTitle: "",
  imagesContentDescription: "",
  imagesContentWebsite: "",
  imagePageCard: [],
  videoCropCompany: "",
  videoTech: "",
  videoDescription: "",
  linkTitle: "",
  linkDescription: "",
  linkWebsite: "",
  businessCompanyImage: [],
  Businessbuttontext: "",
  Businessbuttonurl: "",
  linkContentLogo: [],
  linkContentTitle: "",
  linkContentDescription: "",
  linkContentWebsite: "",
  companyImage: [],
  linkLogo: [],
  businessContentCompany: "",
  businessContentTitle: "",
  businessContentSubtitle: "",
  businessAdditionalInfo: "",
  businessContactInfoName: "",
  businessContactInfoWebsite: "",
  businessContactInfoTelephone: "",
  businessContactInfoEmail: "",
  globalAppsInfoTitle: "",
  globalAppsInfoDescription: "",
  appsGooglePlayLink: "",
  appsAppleStoreLink: "",
  mp3InfoTitle: "",
  mp3InfoWebsite: "",
  mp3InfoDescription: "",
  menuNameOfEstablishment: "",
  menuDescription: "",
  menuSection1Name: "",
  menuSection1Description: "",
  CouponFIleCoverImage: [],
  couponCompany: "",
  couponTitle: "",
  couponDescription: "",
  couponSalesBadge: "",
  couponButtonToSee: "",
  couponCode: "",
  couponValidUntil: "",
  couponTermsAndConditions: "",
  couponInfoButton: "",
  couponInfoURL: "",
  feedbackInfoCompany: "",
  feedbackInfoTitle: "",
  feedbackInfoDescription: "",
  feedbackCategoriesName: "",
  playlistInfoTitle: "",
  playlistInfoName: "",
  playlistInfoDescription: "",
  barcodeGTIN2d: "",
  barcodeWebsiteUrl2d: "",
  socialMediatitle: "",
  socialMediaDescription: "",
  videobutton: "",
  video: [],
  socialnetworks: [],
  Bitcoinurl: "",
  Bitcointext: "",
  Bitcoinbuttontext: "",
  Contacturl: "",
  Contacttext: "",
  Contactbuttontext: "",
  Facebookurl: "",
  Facebooktext: "",
  Facebookbuttontext: "",
  Kitchenurl: "",
  Kitchentext: "",
  Kitchenbuttontext: "",
  Linkurl: "",
  Linktext: "",
  Linkbuttontext: "",
  Locationurl: "",
  Locationtext: "",
  Locationbuttontext: "",
  Messageurl: "",
  Messagetext: "",
  Messagebuttontext: "",
  Paypalurl: "",
  Paypaltext: "",
  Paypalbuttontext: "",
  Purpleurl: "",
  Purpletext: "",
  Purplebuttontext: "",
  Whatsappurl: "",
  Whatsapptext: "",
  Whatsappbuttontext: "",
  Wifiurl: "",
  Wifitext: "",
  Wifibuttontext: "",
  socialMediaLogo: [],
  Coverimage: [],
  SocialMediaTitle: "",
  menuCoverImage: [],
  menuCompanyLogo: [],
  videobutton: "",
  videoFiles: [],
  videoUrl: "",
  landingpageImages: [],
  Landingpagetitle: "",
  Landingpagedescription: "",

  AllDay: false,
  AppbgImages: [],
  basicInfoImage: [],
  mp3Files: [],
  mp3CoverImage: [],
  eventStartDate: "",
  eventEndDate: "",
  appsAmazonStoreLink: "",
  appsAppStoreLink: "",
  appsPlayStoreLink: "",
  menuSections: [
    {
      name: "",
      sectionLogo: [],
      products: [
        {
          productLogo: [],
          productName: "",
          productTranslatedName: "",
          productDescription: "",
          price: "",
        },
      ],
    },
  ],
  eventImgUpload: [],
  contentLinks: [
    {
      name: "",
      website: "",
      logo: [],
    },
  ],
  eventTitle: "",
  eventDescription: "",
  eventDateSince: "",
  eventDateUntil: "",
  globalSearchAddress: "",
  globalUrl: "",
  videoButtonUrl: "",
  allergens: [],
  openingHours: [],
  imagesContentTitle: "",
  imageContentDescription: "",
  contentButtonText: "",
  contentButtonUrl: "",
  playlistItems: [],
  timePicker: "",
  imageGridType: "",
  allergens: [],
  openingHours: [],
  Organic: [],
  RecyclingStamps: [],
  Responsibleconsumption: [],
  Certificates: [],
  videoButtonUrl: "",
  productCategories: [],
  Responsibleconsumption: [],
  productIngredients: [],
  nutritionalInformationQualification: "",
  Nutrients: [],
  businessAdditionalInfo: "",
  facilitiesIcon: [],
  playlistImg: [],
  organizationName: "",
  organizationWebsite: "",
  organizationtelephone: "",
  organizationEmail: "",
  shareToggle: false,
  autoComplete: false,
};
