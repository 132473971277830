import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const cardFormData = createSlice({
  name: "cardFormData",
  reducerPath: STORE.SLICES.CARD_FORM_DATA,
  initialState: {},
  reducers: {
    setFormData: (state, action) => {
      const data = action.payload;
      return data;
    },
  },
});

export const { setFormData } = cardFormData.actions;

export default cardFormData.reducer;
