import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header";
import MainHeader from "../Header/MainHeader";
import Dashboard from "../shared/dashboard/DashboardPage";
import { motion } from "framer-motion";
import { IS_ENABLED_ANIMATE, animateToRight } from "../../constants/ANIMATE";

const MainLayout = ({ children, headerTitle }) => {
  return (
    <>
      <motion.div
        variants={IS_ENABLED_ANIMATE && animateToRight}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div className="flex min-h-screen">
          <div className="hidden sticky z-50 top-0 bg-white lg:flex ">
            <Sidebar />
          </div>
          <div className="flex flex-col w-[100%] bg-[var(--dark-bg)]">
            <div className="hidden lg:flex relative">
              {headerTitle && <Header title={headerTitle} />}
            </div>
            {/* <div className="flex md:none lg:hidden">
          <MainHeader />
        </div> */}
            <div>{children}</div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default MainLayout;
