import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
// Global URL
const backendURL = import.meta.env.VITE_APP_API_URL;

// Admin login action
export const adminLogin = createAsyncThunk(
  "auth/adminLogin",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${backendURL}login`,
        { email, password },
        config
      );
      // store admin's token in local storage
      localStorage.setItem("adminToken", data.token);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.statusText);
    }
  }
);