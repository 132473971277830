import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name updateProfile
 * @description API call to update profile information
 */
const updateProfile = (builder) => {
  return builder.mutation({
    query: ({ id, data }) => {
      if (!id) throw new Error("User ID is required to update profile");
      return {
        url: `/ProfileUpdate/${id}`,
        method: "PUT",
        data: data,
      };
    },
  });
};

// Create Settings API slice
const settingsApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.SETTINGS_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateProfile: updateProfile(builder),
  }),
});

// Export hooks for use in components
const {
  useUpdateProfileMutation,
} = settingsApi;

export {
  settingsApi,
  useUpdateProfileMutation,
};
