import React from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

function BulkqrgenerationUploadSkeleton({ baseStyle }) {
  return (
      <div className="w-full flex justify-center items-center py-40">
        <div className="w-2/3 flex flex-col justify-center items-center">
          {/* Title */}
          <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

          {/* Folder Selection */}
          <div className="w-full max-w-3xl mb-8">
            <Skeleton
              {...baseStyle}
              width="100%"
              height={40}
              className="mb-4"
            />
            <Skeleton {...baseStyle} width="100%" height={30} />
          </div>

          {/* Download CSV Button */}
          <Skeleton {...baseStyle} width={150} height={40} className="mb-8" />

          {/* Upload CSV Section */}
          <div className="w-full max-w-3xl mb-8">
            <Skeleton
              {...baseStyle}
              width="100%"
              height={100}
              className="mb-4"
            />
            <Skeleton {...baseStyle} width="100%" height={40} />
          </div>
        </div>

        <div className="w-1/3 flex flex-col justify-center items-center">
          {/* QR Code Preview */}
          <div className="w-full  flex flex-col items-center space-y-4">
            <Skeleton
              {...baseStyle}
              width={150}
              height={150}
              className="rounded-lg mb-4"
            />
            <Skeleton {...baseStyle} width={200} height={20} className="mb-2" />
            <Skeleton {...baseStyle} width={150} height={20} className="mb-2" />
          </div>
        </div>
      </div>
  );
}

export default BulkqrgenerationUploadSkeleton