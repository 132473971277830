import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const domainSlice = createSlice({
  name: "domains",
  reducerPath: STORE.SLICES.DOMAIN_SLICE,
  initialState: {
    domainsData: [],
    selectedDomains: {},
  },
  reducers: {
    setDomains: (state, action) => {
      state.domainsData = action.payload;
    },
    setSelectedDomains: (state, action) => {
      state.selectedDomains = action.payload;
    },
  },
});

export const { setDomains, setSelectedDomains } = domainSlice.actions;

export default domainSlice.reducer;
