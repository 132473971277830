import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getProfile
 * @description API call to fetch profile data
 */
const getProfile = (builder) => {
  return builder.query({
    query: (params = {}) => ({
      url: "/profile",
      method: "POST",
      params,
    }),
  });
};

// Create Profile API slice
const profileApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.PROFILE_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProfile: getProfile(builder),
  }),
});

// Export hooks for using in components
const {
  useGetProfileQuery,
} = profileApi;

export {
  profileApi,
  useGetProfileQuery,
};
