import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";
import { ChevronDown, RefreshCw } from "lucide-react";
import { FaCaretRight } from "react-icons/fa";
import BasicInformationWrapper from "./basic-Information/BasicInformationWrapper";
import switchIcon from "/src/assets/SwitchIcon.webp";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/slices/appearanceCustomizerSlice";
const QrContext = createContext({
  setFormData: () => {},
  sectionsData: {},
  setSectionsData: () => {},
});

const ColorPickerPopup = ({ color, onChange, onClose, direction = "down" }) => {
  const colors = [
    "#FF0000",
    "#FF4500",
    "#FFA500",
    "#FFD700",
    "#FFFF00",
    "#9ACD32",
    "#008000",
    "#20B2AA",
    "#00FFFF",
    "#0000FF",
    "#4B0082",
    "#800080",
    "#FF69B4",
    "#FFB6C1",
    "#FFF0F5",
    "#FFFFFF",
    "#C0C0C0",
    "#000000",
  ];

  return (
    <div
      className={`absolute right-0 ${
        direction === "up" ? "bottom-8" : "top-8"
      } z-50 bg-[#1E2A35] rounded-lg shadow-lg border border-gray-700`}
    >
      <div className="p-2 grid grid-cols-6 gap-2 w-48">
        {colors.map((clr) => (
          <button
            key={clr}
            className="w-6 h-6 rounded-md border border-gray-700 focus:outline-none focus:ring-2 focus:ring-[#00FFB2]"
            style={{ backgroundColor: clr }}
            onClick={() => {
              onChange(clr);
              onClose();
            }}
          />
        ))}
        <input
          type="color"
          value={color}
          onChange={(e) => {
            onChange(e.target.value);
            onClose();
          }}
          className="w-full h-8 col-span-6 mt-2"
        />
      </div>
    </div>
  );
};

const ColorPicker = () => {
  const [selectedColor, setSelectedColor] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [primaryColor, setPrimaryColor] = useState("#9A9CEA");
  const [secondaryColor, setSecondaryColor] = useState("#A2DCEE");
  const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);
  const [showSecondaryPicker, setShowSecondaryPicker] = useState(false);
  const [colorPairs, setColorPairs] = useState([
    { colors: ["#9A9CEA", "#A2DCEE"] },
    { colors: ["#1F8A70", "#BFDB38"] },
    { colors: ["#8B4513", "#E6E6FA"] },
    { colors: ["#483D8B", "#FFD700"] },
    { colors: ["#FF1493", "#FFB6C1"] },
    { colors: ["#4682B4", "#87CEEB"] },
    { colors: ["#9370DB", "#F4C430"] },
    { colors: ["#4169E1", "#FFD700"] },
  ]);

  const context = useContext(QrContext);
  const sectionsData = context?.sectionsData || {};
  const setSectionsData = context?.setSectionsData || (() => {});
  const primaryPickerRef = useRef(null);
  const secondaryPickerRef = useRef(null);
  const dispatch = useDispatch();

  // Close pickers when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        primaryPickerRef.current &&
        !primaryPickerRef.current.contains(event.target)
      ) {
        setShowPrimaryPicker(false);
      }
      if (
        secondaryPickerRef.current &&
        !secondaryPickerRef.current.contains(event.target)
      ) {
        setShowSecondaryPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        backgroundColor: secondaryColor,
        foregroundColor: primaryColor,
      })
    );
  }, [primaryColor, secondaryColor]);

  // Validate hex color
  const isValidHex = (color) => {
    return /^#[0-9A-F]{6}$/i.test(color);
  };

  // Format color to valid hex
  const formatHexColor = (color) => {
    let formattedColor = color.toUpperCase();
    if (!formattedColor.startsWith("#")) {
      formattedColor = `#${formattedColor}`;
    }
    while (formattedColor.length < 7) {
      formattedColor += "0";
    }
    return formattedColor;
  };

  const handleColorSelect = (index) => {
    setSelectedColor(index);
    setPrimaryColor(colorPairs[index].colors[0]);
    setSecondaryColor(colorPairs[index].colors[1]);
    updateSectionsData(
      colorPairs[index].colors[0],
      colorPairs[index].colors[1],
      index
    );
    // dispatch(
    //   setColor({
    //     foregroundColor: colorPairs[index].colors[1],
    //     backgroundColor: colorPairs[index].colors[0],
    //   })
    // );
  };

  const updateSectionsData = (primary, secondary, index) => {
    setSectionsData({
      ...sectionsData,
      titleColor: primary,
      bgColor: secondary,
      colorPaletteKey: index,
    });
  };

  const handleColorInput = (value, isSecondary = false) => {
    const newColor = formatHexColor(value);

    if (isSecondary) {
      setSecondaryColor(newColor);
    } else {
      setPrimaryColor(newColor);
    }

    // Update palette if valid hex
    if (isValidHex(newColor)) {
      updatePalette(newColor, isSecondary);
    }
  };

  const updatePalette = (newColor, isSecondary) => {
    if (selectedColor !== null) {
      const newPairs = [...colorPairs];
      const currentPair = [...newPairs[selectedColor].colors];

      if (isSecondary) {
        currentPair[1] = newColor;
      } else {
        currentPair[0] = newColor;
      }

      newPairs[selectedColor] = { colors: currentPair };
      setColorPairs(newPairs);
      updateSectionsData(currentPair[0], currentPair[1], selectedColor);
    }
  };

  const swapColors = () => {
    const newPrimary = secondaryColor;
    const newSecondary = primaryColor;

    setPrimaryColor(newPrimary);
    setSecondaryColor(newSecondary);

    if (selectedColor !== null) {
      const newPairs = [...colorPairs];
      newPairs[selectedColor] = { colors: [newPrimary, newSecondary] };
      setColorPairs(newPairs);
      updateSectionsData(newPrimary, newSecondary, selectedColor);
    }
  };

  return (
    <BasicInformationWrapper
      title={"Design"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {isOpen && (
        <div className="px-4 pb-4 space-y-4">
          <div className="space-y-2">
            <h4 className="text-xs p-1 font-medium text-gray-400">
              Color Palette
            </h4>

            <div className="relative">
              <div
                className="flex gap-2 custom-scrollbar px-1 ml-[-5px] overflow-x-auto custom-scrollbar py-2 cursor-grab"
                style={{
                  scrollBehavior: "smooth",
                }}
                onMouseDown={(e) => {
                  const slider = e.currentTarget;
                  let isDown = true;
                  let startX = e.pageX - slider.offsetLeft;
                  let scrollLeft = slider.scrollLeft;

                  const mouseMoveHandler = (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 2;
                    slider.scrollLeft = scrollLeft - walk;
                  };

                  const mouseUpHandler = () => {
                    isDown = false;
                    slider.classList.remove("cursor-grabbing");
                    document.removeEventListener("mousemove", mouseMoveHandler);
                    document.removeEventListener("mouseup", mouseUpHandler);
                  };

                  slider.classList.add("cursor-grabbing");
                  document.addEventListener("mousemove", mouseMoveHandler);
                  document.addEventListener("mouseup", mouseUpHandler);
                }}
              >
                {colorPairs.map((pair, index) => (
                  <div
                    key={index}
                    onClick={(e) => {
                      // Only trigger click if we haven't dragged
                      if (
                        !e.currentTarget.classList.contains("cursor-grabbing")
                      ) {
                        handleColorSelect(index);
                      }
                    }}
                    className="flex-none w-[calc(50%-7px)] md:w-[calc(24%-7px)] lg:w[calc(18.4%)] xl:w-[calc(16.8%-7px)]"
                  >
                    <div
                      className={`h-20 rounded-md cursor-pointer bg-[var(--darker-bg)] transition-all duration-200
                            ${
                              selectedColor === index
                                ? "ring-2 ring-[#00FFB2]"
                                : "hover:ring-1 hover:ring-[#00FFB2]/50"
                            }`}
                    >
                      <div className="relative w-full h-full flex items-center justify-center">
                        <div
                          className="absolute w-12 h-12 rounded-full -translate-x-5 transition-all"
                          style={{ backgroundColor: pair.colors[0] }}
                        />
                        <div
                          className="absolute w-12 h-12 rounded-full translate-x-5 transition-all"
                          style={{ backgroundColor: pair.colors[1] }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex items-center flex-col sm:flex-row gap-3 justify-center p-4 rounded-lg bg-[var(--darker-bg)]">
            {/* Primary Color */}
            <div className="flex-1 space-y-1.5 w-full">
              <label className="block text-xs font-medium text-gray-400">
                Border color
              </label>
              <div className="flex items-center gap-2 bg-[var(--dark-bg)] p-2 rounded">
                <div className="flex items-center flex-1">
                  <span className="text-gray-400 mr-1">#</span>
                  <input
                    type="text"
                    value={primaryColor.replace("#", "")}
                    onChange={(e) => handleColorInput(e.target.value)}
                    className="w-full bg-transparent text-gray-200 focus:outline-none uppercase text-sm !font-[300]"
                    maxLength={6}
                  />
                </div>
                <div className="relative" ref={primaryPickerRef}>
                  <div
                    className="w-6 h-6 rounded cursor-pointer transition-transform hover:scale-110"
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => setShowPrimaryPicker(!showPrimaryPicker)}
                  />
                  {showPrimaryPicker && (
                    <ColorPickerPopup
                      color={primaryColor}
                      onChange={(color) => handleColorInput(color)}
                      onClose={() => setShowPrimaryPicker(false)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Swap Button */}
            <div className="flex-1 justify-center text-center">
              <button
                type="button"
                onClick={swapColors}
                className="p-2 mt-2 rounded-lg  transition-all duration-200 hover:scale-105"
              >
                {/* <RefreshCw className="w-5 h-5 text-[var(--input-bg)]" /> */}
                <img src={switchIcon} alt="" />
              </button>
            </div>

            {/* Secondary Color */}
            <div className="flex-1 space-y-1.5 w-full">
              <label className="block text-xs font-medium text-gray-400">
                Border color
              </label>
              <div className="flex items-center gap-2 bg-[var(--dark-bg)] p-2 rounded">
                <div className="flex items-center flex-1">
                  <span className="text-gray-400 mr-1">#</span>
                  <input
                    type="text"
                    value={secondaryColor.replace("#", "")}
                    onChange={(e) => handleColorInput(e.target.value, true)}
                    className="w-full bg-transparent text-gray-200 focus:outline-none uppercase text-sm !font-[300]"
                    maxLength={6}
                  />
                </div>
                <div className="relative" ref={secondaryPickerRef}>
                  <div
                    className="w-6 h-6 rounded cursor-pointer transition-transform hover:scale-110"
                    style={{ backgroundColor: secondaryColor }}
                    onClick={() => setShowSecondaryPicker(!showSecondaryPicker)}
                  />
                  {showSecondaryPicker && (
                    <ColorPickerPopup
                      color={secondaryColor}
                      onChange={(color) => handleColorInput(color, true)}
                      onClose={() => setShowSecondaryPicker(false)}
                      direction="up"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <style jsx global>{`
        .custom-scrollbar::-webkit-scrollbar {
          height: 6px;
          display: none;
        }

        .custom-scrollbar:hover::-webkit-scrollbar {
          display: block;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #1e2a35;
          border-radius: 3px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #2a3744;
          border-radius: 3px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #3a4754;
        }

        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #2a3744 #1e2a35;
        }
      `}</style>
    </BasicInformationWrapper>
  );
};

export default ColorPicker;
