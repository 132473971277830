import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalInformation: {
    contactInformation: {
      name: "",
      surname: "",
      email: "",
      telephone: "",
      countryCode: "+91",
    },
    password: "",
    language: "English",
    timeZone: "(GMT+05:30) Kolkata",
    dateFormat: "DD-MM-YYYY",
    hoursFormat: "12",
    thousandsSeparator: ",",
    accountStatus: {
      showConfirmation: false,
    },
},
    
    taxInformation: {
      formType: "company",
      companyName: "",
      country: "India",
      taxId: "",
      firstName: "",
      lastName: "",
      address: "",
      postalCode: "",
      city: "",
      email: "",
    },
    trackingAnalytics: {
      googleAnalyticsId: "",
      facebookPixelId: "",
      googleTagManagerId: "",
    },
    apiKey: {
      key: "",
    },
    notificationPreferences: {
      billing: false,
      communications: false,
      newsletter: false,
    },

};

const updateProfileSlice = createSlice({
  name: "updateProfile",
  initialState,
  reducers: {
    // Contact Information Actions
    setContactInfo: (state, action) => {
      state.generalInformation.contactInformation = {
        ...state.generalInformation.contactInformation,
        ...action.payload,
      };
    },
    

    // Password Actions
    setPassword: (state, action) => {
      state.generalInformation.password = action.payload;
    },

    // Language Actions
    setLanguage: (state, action) => {
      state.generalInformation.language = action.payload;
    },

    // Time Zone Actions
    setTimeZone: (state, action) => {
      state.generalInformation.timeZone = action.payload;
    },

    // Date Format Actions
    setDateFormat: (state, action) => {
      state.generalInformation.dateFormat = action.payload;
    },

    // Hours Format Actions
    setHoursFormat: (state, action) => {
      state.generalInformation.hoursFormat = action.payload;
    },

    // Thousands Separator Actions
    setThousandsSeparator: (state, action) => {
      state.generalInformation.thousandsSeparator = action.payload;
    },

    // Account Status Actions
    toggleAccountStatusConfirmation: (state) => {
      state.generalInformation.accountStatus.showConfirmation = !state.generalInformation.accountStatus.showConfirmation;
    },

    // Tax Information Actions
    setTaxInformation: (state, action) => {
      state.generalInformation.taxInformation = {
        ...state.generalInformation.taxInformation,
        ...action.payload,
      };
    },

    setFormType: (state, action) => {
      state.generalInformation.taxInformation.formType = action.payload;
    },

    // Tracking Analytics Actions
    setTrackingAnalytics: (state, action) => {
      state.generalInformation.trackingAnalytics = {
        ...state.generalInformation.trackingAnalytics,
        ...action.payload,
      };
    },

    // API Key Actions
    generateApiKey: (state, action) => {
      state.generalInformation.apiKey.key = action.payload;
    },

    // Notification Preferences Actions
    setNotificationPreferences: (state, action) => {
      state.generalInformation.notificationPreferences = {
        ...state.generalInformation.notificationPreferences,
        ...action.payload,
      };
    },
  },
});

export const {
  setContactInfo,
  setPassword,
  setLanguage,
  setTimeZone,
  setDateFormat,
  setHoursFormat,
  setThousandsSeparator,
  toggleAccountStatusConfirmation,
  setTaxInformation,
  setFormType,
  setTrackingAnalytics,
  generateApiKey,
  setNotificationPreferences,
} = updateProfileSlice.actions;

export default updateProfileSlice.reducer;
