import React, { useEffect } from "react";
import logoutIcon from "/src/assets/Logout.webp";
import { useNavigate } from "react-router";
import { removeLocalData } from "/src/utils/localData"

const SidebarProfile = ({ profileData = {}, isSideBarOpen }) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("🚀 ~ SidebarProfile ~ profileData:", profileData);
  }, []);

  const handleLogout = () => {
    removeLocalData("user");
    navigate("/");
    window.location.href = "/";
  };

  return (
    <div className="py-5">
      <div
        className={`flex items-center justify-start gap-3 py-3 pl-5 ${
          isSideBarOpen ? "w-auto" : "w-full"
        }`}
      >
        <div className="flex flex-col items-center">
          <img
            src={profileData?.image}
            alt="User Image"
            className="lg:w-12 w-10 h-10 cursor-pointer lg:h-12"
          />

          {/* Logout button when the sidebar is closed */}
          {!isSideBarOpen && (
            <div
              className="mt-3 flex items-center space-x-1"
              onClick={handleLogout}
            >
              <img
                src={logoutIcon}
                alt="Logout"
                className="w-8 mt-2 ml-2.5 h-8 cursor-pointer"
              />
            </div>
          )}
        </div>

        {/* Profile info and logout button when the sidebar is open */}
        {isSideBarOpen && (
          <div className="ml-4">
            <h3 className="lg:text-lg text-lg font-semibold text-[var(--text-light)]">
              {profileData?.name}
            </h3>
            <p className="text-sm text-gray-500">{profileData?.accountType}</p>
          </div>
        )}

        {/* Logout button when the sidebar is open */}
        {isSideBarOpen && (
          <div
            className="ml-3 flex items-center space-x-1"
            onClick={handleLogout}
          >
            <img
              src={logoutIcon}
              alt="Logout"
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarProfile;
