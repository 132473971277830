import React, { useState, useEffect } from "react";
import { Power, Settings } from "lucide-react";
import SkeletonLoader from "../global/skeleton/SkeletonLoader";
import { Link } from "react-router";
import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from "../../utils/localData";
import Button from "../global/Button";
import { MOCK_DELAY_TIMER } from "../../constants/GLOBAL";
import { FaUser } from "react-icons/fa";
import SubMenuSetting from "./SubMenuSetting";
import ContactModal from "../global/modals/ContactModal";

const HeaderButton = ({ text ,onClick}) => (
  
  <Button
    text={text}
    className={`px-2 min-w-[100px] py-2 bg-[var(--dark-bg)] text-white hover:bg-[#2A333E] cursor-pointer transition-colors duration-200 rounded-md text-sm`}
    onClick={onClick}
  />
);

const Header = ({ title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalContactVisible, setIsModalContactVisible] = useState(false);



  const [loading, setLoading] = useState(() => {
    return !getLocalData("sidebarLoaded");
  });

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
        setLocalData("sidebarLoaded", "true");
      }, MOCK_DELAY_TIMER);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      removeLocalData("sidebarLoaded");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);


  const ContactModalShow = () => {
    console.log('wor=kinf');
    setIsModalContactVisible(true);
  }
  const handleContactCancel = () => {
    setIsModalContactVisible(false);
  };
  return loading ? (
    <SkeletonLoader variant="qrHeader" />
  ) : (
    <div className="w-full  bg-[var(--darker-bg)]">
      <div className="max-w-[1920px] relative z-[10] px-0 sm:px-0 lg:px-0">
        <div className="flex items-center justify-between sticky top-0 h-20 py-2 px-2 pr-10 bg-[var(--darker-bg)]">
          {/* Title */}
          <h1 className="text-xl font-semibold text-white ">
            {title || "Plan & Payments"}
          </h1>

          {/* Navigation Buttons */}
          <div className="hidden md:flex items-center space-x-3">
            <HeaderButton onClick={ContactModalShow}  text="Contact" />
            <Link to={"/dashboard/help-center"}>
              <HeaderButton text="Help Center" />
            </Link>
            <HeaderButton text="Developer" />
            <Button
              onClick={() => setIsModalOpen(isModalOpen ? false : true)}
              className={`p-2 bg-[#1A232E] cursor-pointer border border-[var(--primary)] hover:bg-[#2A333E] transition-colors duration-200 rounded-md`}
            >
              <Settings className="w-5 h-5 text-[var(--primary)]" />
            </Button>
          </div>

          {/* header modal */}
          {isModalOpen && <SubMenuSetting /> }
          {/* Mobile Menu Button */}
          <button className="md:hidden p-2 rounded-md text-white hover:bg-[#2A333E]">
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      <ContactModal visible={isModalContactVisible} onClose={handleContactCancel} />
    </div>
  );
};

export default Header;
