import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getProducts
 * @description API call to fetch products
 */
const getProducts = (builder) => {
  return builder.query({
    query: (params = {}) => ({
      url: "/products/products",
      method: "GET",
      params,
    }),
  });
};

// Create Products API slice
const productApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.PRODUCTS_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProducts: getProducts(builder),
  }),
});

// Export hooks for using in components
const {
  useGetProductsQuery,
} = productApi;

export {
  productApi,
  useGetProductsQuery,
};
