import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name addFavorites
 * @description API call to add a favorite item
 *
 * @param payload: object
 */
const addFavorites = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/addToFavorites`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name removeFavorites
 * @description API call to remove a favorite item
 *
 * @param payload: object
 */
const removeFavorites = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/removeFromFavorites`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// Create favorites API slice
const favoritesApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.FAVORITES_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    addFavorites: addFavorites(builder),
    removeFavorites: removeFavorites(builder),
  }),
});

// Export hooks for using in components
const { useAddFavoritesMutation, useRemoveFavoritesMutation } = favoritesApi;

export { favoritesApi, useAddFavoritesMutation, useRemoveFavoritesMutation };
