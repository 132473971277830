import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name uploadFile
 * @description API call to upload a file
 *
 * @param payload: object
 */
const uploadFile = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/file-upload`,
        method: "POST",
        data: payload,
      };
    },
  });
};

/*
 * @private
 * @name getUploadImageData
 * @description API call to upload an image
 *
 * @param payload: object
 */
const uploadImageData = (builder) => {
  return builder.mutation({
    query(payload) {
      const formData = new FormData();
      formData.append(
        "file",
        payload.file,
        payload.filename || payload.file.name
      );

      console.log("FormData", payload.file);

      return {
        url: "/file-uploads/upload",
        method: "POST",
        body: formData,
        // redirect: "follow",
        formData: true,
      };
    },
  });
};

// Create upload API slice
const uploadApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.UPLOAD_API,
  baseQuery: fetchBaseQuery({
    baseUrl: "https://stage25.qr-gen.com",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      if (token) {
        headers.set("Authorization", `Bearer ${token.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadFile: uploadFile(builder),
    uploadImageData: uploadImageData(builder),
  }),
});

// Export hooks for using in components
const { useUploadFileMutation, useUploadImageDataMutation } = uploadApi;

export { uploadApi, useUploadFileMutation, useUploadImageDataMutation };
