import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function PlansAndPriceSkeleton({ baseStyle }) {
  return (
    <div className="w-full min-h-screen flex flex-col items-center py-10">
      {/* Title Section */}
      <div className="flex flex-col items-center mb-10">
        <Skeleton {...baseStyle} width={300} height={40} className="mb-4" />
        <Skeleton {...baseStyle} width={500} height={20} />
      </div>

      {/* Pricing Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl mb-12">
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={index}
            className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4"
          >
            {/* Plan Title */}
            <Skeleton {...baseStyle} width={100} height={25} />

            {/* Price */}
            <Skeleton {...baseStyle} width={120} height={40} />

            {/* Plan Description */}
            <Skeleton {...baseStyle} width="80%" height={20} />
            <Skeleton {...baseStyle} width="90%" height={20} />

            {/* Buttons */}
            <Skeleton {...baseStyle} width={140} height={40} borderRadius={8} />
            <Skeleton {...baseStyle} width={120} height={35} borderRadius={8} />

            {/* Features List */}
            {Array.from({ length: 4 }).map((_, idx) => (
              <Skeleton {...baseStyle} key={idx} width="90%" height={15} />
            ))}
          </div>
        ))}
      </div>

      {/* Feature Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index}
            className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4"
          >
            {/* Feature Icon */}
            <Skeleton {...baseStyle} width={40} height={40} borderRadius={8} />

            {/* Feature Title */}
            <Skeleton {...baseStyle} width={200} height={25} />

            {/* Feature Description */}
            <Skeleton {...baseStyle} width="100%" height={20} />
            <Skeleton {...baseStyle} width="90%" height={20} />
            <Skeleton {...baseStyle} width="80%" height={20} />
          </div>
        ))}
      </div>

      {/* FAQ Section */}
      <div className="w-full max-w-4xl mt-12">
        <Skeleton {...baseStyle} width={200} height={35} className="mb-4" />
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="mb-6">
            <Skeleton {...baseStyle} width="80%" height={20} className="mb-2" />
            <Skeleton {...baseStyle} width="90%" height={15} />
            <Skeleton {...baseStyle} width="85%" height={15} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlansAndPriceSkeleton;
