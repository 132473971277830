import { createApi } from "@reduxjs/toolkit/query/react";
// Config
import axiosBaseQuery from "../config/axiosBaseQuery";
// Constants
import STORE from "../constants/STORE";

/*
 * @private
 * @name getResourcesData
 * @description API call to fetch resources data
 */
const getResourcesData = (builder) => {
  return builder.query({
    query: (payload = {}) => ({
      url: "/qr-resources",
      method: "POST",
      data: payload,
    }),
  });
};

// Create Resources API slice
const resourcesApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.RESOURCES_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getResourcesData: getResourcesData(builder),
  }),
});

// Export hooks for using in components
const {
  useGetResourcesDataQuery,
} = resourcesApi;

export {
  resourcesApi,
  useGetResourcesDataQuery,
};
