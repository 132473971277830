import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const foldersSlice = createSlice({
  name: "folders",
  reducerPath: STORE.SLICES.FOLDER_SLICE,
  initialState: {
    foldersData: [],
    selectedFolder: {},
  },
  reducers: {
    setFolders: (state, action) => {
      state.foldersData = action.payload;
    },
    addFolders: (state, action) => {
      const data = action.payload;
      state.foldersData.push(data);
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
  },
});

export const { addFolders, setFolders, setSelectedFolder } =
  foldersSlice.actions;

export default foldersSlice.reducer;
