import React, { useEffect, useState, useTransition } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NavbarSkeleton from "./NavbarSkeleton";
import SidebarSkeleton from "./sidebarSkeleton";
import PlansCheckoutSkeleton from "./PlansCheckoutSkeleton";
import QrDetailsSkeleton from "./QrDetailsSkeleton";
import BulkqrgenerationUploadSkeleton from "./BulkqrgenerationUploadSkeleton";
import SettingSkeleton from "./SettingSkeleton";
import PlanSkeleton from "./PlanSkeleton";
import HelpCenterSkeleton from "./HelpCenterSkeleton";
import MyDomainSkeleton from "./MyDomainSkeleton";
import TemplateSkeleton from "./TemplateSkeleton";
import StatsSkeleton from "./StatsSkeleton";
import MyQrCodeSkeleton from "./MyQrCodeSkeleton";
import BulkQrGenerationSkeleton from "./BulkQrGenerationSkeleton";
import DashboardSkeleton from "./DashboardSkeleton";
import DynamicDashboardSkeleton from "./DynamicDashboardSkeleton";
import ContactUsSkeleton from "./ContactUsSkeleton";
import BlogSkeleton from "./BlogSkeleton";
import LoginSkeleton from "./LoginSkeleton";
import PlansAndPriceSkeleton from "./PlansAndPriceSkeleton";
import ApiSkeleton from "./ApiSkeleton";
import { useParams } from "react-router";

const SkeletonLoader = ({
  variant,
  count = 1,
  className = "",
  children,
}) => {
  const baseStyle = {
    baseColor: "#111b22",
    highlightColor: "#233a49",
    borderRadius: "0.375rem", // Tailwind rounded-md
    duration: 0.5,
  };
  const { id } = useParams();
  const [isVariant, setisVariant] = useState("default");
  useEffect(() => {
    setisVariant(variant == `/dashboard/${id}` ? "/dashboard/:id" : variant)
  }, [variant]);

  const variants = {
    default: (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full h-screen flex flex-col justify-center py-[5%] items-center">
          <div className="bg-[#0D1B2A] w-full flex flex-col justify-center items-center h-full p-10">
            {/* Left Section: QR Floating Icons */}
            <div {...baseStyle} className="w-full lg:w-1/2 h-full ">
              <Skeleton
                {...baseStyle}
                width={"100%"}
                height={"60%"}
                borderRadius={10}
              />
            </div>

            {/* Right Section: Text Placeholder */}
            <div className="w-full lg:w-1/2 p-6">
              {/* Title */}
              <Skeleton
                {...baseStyle}
                width={200}
                height={40}
                className="mb-6"
              />

              {/* Text Sections */}
              <div className="space-y-6">
                <Skeleton {...baseStyle} width="100%" height={20} />
                <Skeleton {...baseStyle} width="90%" height={20} />
                <Skeleton {...baseStyle} width="80%" height={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    //AuthenticatedRoutes
    index: (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full h-screen flex flex-col justify-center py-[5%] items-center">
          <div className="flex bg-transparent h-full flex-col items-center md:bg-[#0E1F30] md:border-[#182945] overflow-hidden  rounded-2xl md:border justify-center gap-y-4 w-[90%]  mb-12">
            <div className="grid grid-cols-3 gap-6 w-full h-full">
              {/* Sidebar (Destination Options) */}
              <div className="bg-[#1B2C37] grid gap-2 grid-cols-2 p-4 rounded-lg space-y-4 w-full">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton {...baseStyle} width="100%" height={60} />
                ))}
              </div>

              {/* QR Code Generator Center Section */}
              <div className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4">
                <Skeleton {...baseStyle} width="100%" height={40} />
                <Skeleton {...baseStyle} width="100%" height={40} />

                {/* QR Code Preview */}
                <div className="flex flex-col items-center">
                  <Skeleton {...baseStyle} width={150} height={150} />
                  <Skeleton {...baseStyle} width={120} height={20} />
                </div>

                {/* Customization Options */}
                <div className="grid grid-cols-2 gap-4">
                  <Skeleton {...baseStyle} width="100%" height={30} />
                  <Skeleton {...baseStyle} width="100%" height={30} />
                </div>

                {/* Color Inputs */}
                <div className="flex gap-4">
                  <Skeleton {...baseStyle} width="30%" height={30} />
                  <Skeleton {...baseStyle} width="30%" height={30} />
                  <Skeleton {...baseStyle} width="30%" height={30} />
                </div>

                {/* Download Button */}
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={40}
                  borderRadius={8}
                />
              </div>

              {/* Right Section (QR Code Styles) */}
              <div className="bg-[#1B2C37] p-4 rounded-lg grid grid-cols-3 gap-2">
                {Array.from({ length: 15 }).map((_, index) => (
                  <Skeleton
                    {...baseStyle}
                    key={index}
                    width="100%"
                    height={60}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    "/static-qr-codes": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full h-screen flex flex-col justify-center py-[5%] items-center">
          <Skeleton
            {...baseStyle}
            width={200}
            height={80}
            className="justify-center items-center mt-6"
          />
          <div className="bg-[#0D1B2A] w-full flex flex-col lg:flex-row justify-center items-center h-full p-10">
            {/* Left Section: QR Floating Icons */}
            <div {...baseStyle} className="w-full lg:w-1/2 h-full pt-24">
              <Skeleton
                {...baseStyle}
                width={"100%"}
                height={"80%"}
                borderRadius={10}
              />
            </div>

            {/* Text Skeleton Effect */}
            <div className="w-full lg:w-1/2 pt-6">
              <Skeleton
                {...baseStyle}
                width={400}
                height={90}
                className="ml-20 mb-6"
              />
              <Skeleton
                {...baseStyle}
                width={400}
                height={90}
                className="ml-20 mb-6"
              />
              <Skeleton
                {...baseStyle}
                width={400}
                height={90}
                className="ml-20 mb-6"
              />
            </div>
          </div>
        </div>
      </div>
    ),
    "/dynamic-qr-codes": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full h-screen flex flex-col justify-center py-[5%] items-center">
          <div className="bg-[#0D1B2A] w-[80%] flex flex-col lg:flex-row justify-center items-center h-full pt-40">
            <div {...baseStyle} className="w-full lg:w-1/2 h-full ">
              <Skeleton
                {...baseStyle}
                width={"100%"}
                height={"100%"}
                borderRadius={10}
              />
            </div>
            <div className="w-full lg:w-1/2 h-full ml-5">
              <Skeleton
                {...baseStyle}
                width={"100%"}
                height={"100%"}
                borderRadius={10}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    "/general-data-protection": (
      <div>
        <NavbarSkeleton />
        <div className="bg-[#0D1B2A] w-full flex flex-col lg:flex-row justify-center items-center h-full p-10">
          {/* Text Skeleton Effect */}
          <div className="w-full lg:w-1/2 pb-40 h-screen flex flex-col justify-center items-start lg:items-center ">
            <div className="w-full lg:w-1/2 pb-20 h-screen flex flex-col justify-center items-start lg:items-center">
              <Skeleton
                {...baseStyle}
                width={800}
                height={50}
                className="pb-20"
              />
              <Skeleton
                {...baseStyle}
                width={300}
                height={20}
                className="pb-20"
              />
            </div>
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
          </div>
        </div>
      </div>
    ),
    "/types-of-qr": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full min-h-screen flex flex-col items-center justify-center py-[5%]">
          {/* Title */}
          <Skeleton {...baseStyle} width={300} height={40} className="mb-4" />
          <Skeleton {...baseStyle} width={500} height={20} className="mb-10" />

          {/* QR Type Icons Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 w-full max-w-6xl">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="flex flex-col items-center space-y-2">
                <Skeleton
                  {...baseStyle}
                  width={100}
                  height={100}
                  borderRadius={10}
                />
                <Skeleton {...baseStyle} width={80} height={20} />
              </div>
            ))}
          </div>

          {/* Below Section (Simulating images of QR use cases) */}
          <div className="mt-12 flex flex-col md:flex-row justify-center items-center gap-6 w-full max-w-5xl">
            <Skeleton
              {...baseStyle}
              width="45%"
              height={200}
              borderRadius={10}
            />
            <Skeleton
              {...baseStyle}
              width="45%"
              height={200}
              borderRadius={10}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl mx-auto py-10">
            {/* Skeleton for each card */}
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4"
              >
                {/* Image Skeleton */}
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={180}
                  borderRadius={10}
                />

                {/* Title Skeleton */}
                <Skeleton {...baseStyle} width={120} height={25} />

                {/* Description Skeleton */}
                <Skeleton {...baseStyle} width="100%" height={15} />
                <Skeleton {...baseStyle} width="90%" height={15} />
                <Skeleton {...baseStyle} width="80%" height={15} />

                {/* Buttons Skeleton */}
                <div className="flex gap-4 mt-4">
                  <Skeleton
                    {...baseStyle}
                    width={120}
                    height={35}
                    borderRadius={8}
                  />
                  <Skeleton
                    {...baseStyle}
                    width={100}
                    height={35}
                    borderRadius={8}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    "/qr-codes-for": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full min-h-screen flex flex-col items-center py-10">
          {/* Title */}
          <Skeleton {...baseStyle} width={300} height={40} className="mb-4" />
          <Skeleton {...baseStyle} width={500} height={20} className="mb-10" />

          {/* Categories Grid (First Section) */}
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 w-full max-w-6xl">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="flex flex-col items-center space-y-2">
                <Skeleton
                  {...baseStyle}
                  width={100}
                  height={100}
                  borderRadius={10}
                />
                <Skeleton {...baseStyle} width={80} height={20} />
              </div>
            ))}
          </div>

          {/* Business Category Cards (Second Section) */}
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4"
              >
                {/* Image Skeleton */}
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={180}
                  borderRadius={10}
                />

                {/* Title Skeleton */}
                <Skeleton {...baseStyle} width={120} height={25} />

                {/* Description Skeleton */}
                <Skeleton {...baseStyle} width="100%" height={15} />
                <Skeleton {...baseStyle} width="90%" height={15} />
                <Skeleton {...baseStyle} width="80%" height={15} />

                {/* Button Skeleton */}
                <Skeleton
                  {...baseStyle}
                  width={120}
                  height={35}
                  borderRadius={8}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    "/qr-code-on": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full min-h-screen flex flex-col items-center py-10">
          {/* Title Section */}
          <div className="flex flex-col items-center mb-10">
            <Skeleton {...baseStyle} width={300} height={40} className="mb-4" />
            <Skeleton {...baseStyle} width={500} height={20} />
          </div>

          {/* Categories Grid (Top Section) */}
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 w-full max-w-6xl mb-12">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="flex flex-col items-center space-y-2">
                <Skeleton
                  {...baseStyle}
                  width={100}
                  height={100}
                  borderRadius={10}
                />
                <Skeleton {...baseStyle} width={80} height={20} />
              </div>
            ))}
          </div>

          {/* Business Category Cards (Bottom Section) */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="bg-[#1B2C37] p-6 rounded-lg flex flex-col space-y-4"
              >
                {/* Category Icon */}
                <Skeleton
                  {...baseStyle}
                  width={40}
                  height={40}
                  borderRadius={8}
                />

                {/* Title */}
                <Skeleton {...baseStyle} width={160} height={25} />

                {/* Image Placeholder */}
                <Skeleton
                  {...baseStyle}
                  width="100%"
                  height={180}
                  borderRadius={10}
                />

                {/* Button Placeholder */}
                <Skeleton
                  {...baseStyle}
                  width={120}
                  height={35}
                  borderRadius={8}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    "/plans-and-prices": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <PlansAndPriceSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/api": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <ApiSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/faq": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center bg-[#0D1B2A] p-10">
          {/* FAQ Heading */}
          <Skeleton {...baseStyle} width={120} height={40} className="mb-4" />
          <Skeleton {...baseStyle} width={500} height={20} className="mb-6" />

          {/* FAQ List Skeleton */}
          <div className="w-full max-w-2xl">
            {Array(6)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  className="w-full bg-gray-800 p-4 rounded-lg mb-3 flex justify-between"
                >
                  <Skeleton {...baseStyle} width="80%" height={20} />
                  <Skeleton {...baseStyle} width={20} height={20} />
                </div>
              ))}
          </div>
        </div>
      </div>
    ),
    "/signup": <LoginSkeleton baseStyle={baseStyle} />,
    "/login": <LoginSkeleton baseStyle={baseStyle} />,
    "/benefits": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Title */}
          <Skeleton {...baseStyle} width={300} height={40} className="mb-6" />

          {/* Benefits Grid */}
          <div className="w-4/5 grid grid-cols-3 gap-4">
            {/* Top Large Cards */}
            <Skeleton {...baseStyle} height={150} className="col-span-1" />
            <Skeleton {...baseStyle} height={150} className="col-span-1" />
            <Skeleton {...baseStyle} height={150} className="col-span-1" />

            {/* Middle Cards */}
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <Skeleton {...baseStyle} height={100} />
              <Skeleton {...baseStyle} height={100} />
            </div>

            {/* Small Cards */}
            <div className="col-span-1 grid grid-cols-2 gap-4">
              <Skeleton {...baseStyle} height={100} />
              <Skeleton {...baseStyle} height={100} />
            </div>

            {/* Bottom Row */}
            <Skeleton {...baseStyle} height={50} className="col-span-3" />
          </div>

          {/* Button */}
          <Skeleton {...baseStyle} width={150} height={40} className="mt-6" />
        </div>
      </div>
    ),
    "/where-to-use": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Title */}
          <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

          {/* Subtitle */}
          <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

          {/* Grid Section */}
          <div className="w-4/5 grid grid-cols-4 gap-8">
            {/* Repeating Placeholder Blocks */}
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index} className="flex flex-col items-center space-y-3">
                <Skeleton
                  {...baseStyle}
                  width={80}
                  height={80}
                  borderRadius={10}
                />
                <Skeleton {...baseStyle} width={120} height={20} />
                <Skeleton {...baseStyle} width={150} height={15} />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    "/affiliate": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Top Section */}
          <div className="flex flex-col md:flex-row items-center justify-between w-4/5">
            <div className="flex-1 space-y-4">
              <Skeleton {...baseStyle} width={300} height={40} />
              <Skeleton {...baseStyle} width={400} height={20} />
              <Skeleton {...baseStyle} width={350} height={20} />
              <Skeleton {...baseStyle} width={200} height={40} />
            </div>
            <div className="flex-1 flex justify-end">
              <Skeleton {...baseStyle} width={250} height={200} />
            </div>
          </div>

          {/* How it Works Section */}
          <div className="w-full flex flex-col items-center py-10">
            <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />
            <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

            {/* Steps Section */}
            <div className="w-4/5 grid grid-cols-3 gap-8">
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center space-y-3"
                >
                  <Skeleton
                    {...baseStyle}
                    width={100}
                    height={100}
                    borderRadius={10}
                  />
                  <Skeleton {...baseStyle} width={120} height={20} />
                  <Skeleton {...baseStyle} width={150} height={15} />
                </div>
              ))}
            </div>
          </div>

          {/* Program Conditions Section */}
          <div className="w-full flex flex-col items-center py-10">
            <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />
            <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

            {/* Conditions Grid */}
            <div className="w-4/5 grid grid-cols-3 gap-8">
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center space-y-3"
                >
                  <Skeleton
                    {...baseStyle}
                    width={80}
                    height={80}
                    borderRadius={10}
                  />
                  <Skeleton {...baseStyle} width={120} height={20} />
                  <Skeleton {...baseStyle} width={150} height={15} />
                </div>
              ))}
            </div>

            {/* Sign Up Button */}
            <Skeleton {...baseStyle} width={150} height={40} className="mt-6" />
          </div>
        </div>
      </div>
    ),
    "/reviews": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Title Section */}
          <Skeleton {...baseStyle} width={300} height={40} className="mb-6" />
          <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

          {/* Grid Section */}
          <div className="w-4/5 grid grid-cols-2  gap-8">
            {/* Repeating Placeholder Blocks */}
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="flex flex-col items-center space-y-3">
                <Skeleton {...baseStyle} circle width={80} height={80} />
                <Skeleton
                  {...baseStyle}
                  width={150}
                  height={20}
                  className="mt-4"
                />
                <Skeleton {...baseStyle} width={180} height={15} />
                <Skeleton {...baseStyle} width={160} height={15} />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    "/Aboutus": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Title Section */}
          <Skeleton {...baseStyle} width={300} height={40} className="mb-6" />
          <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

          {/* Content Section */}
          {/* Left Image Placeholder */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <Skeleton
              {...baseStyle}
              width={300}
              height={400}
              className="rounded-lg"
            />
          </div>

          {/* Right Text Section */}
          <div className="w-full lg:w-1/2 text-center space-y-4">
            {/* Paragraph Placeholder */}
            <Skeleton {...baseStyle} width="80%" height={20} />
            <Skeleton {...baseStyle} width="90%" height={20} />
            <Skeleton {...baseStyle} width="85%" height={20} />
          </div>

          {/* Footer Section */}
          <div className="w-full text-center mt-10">
            <Skeleton {...baseStyle} width={200} height={40} />
          </div>
        </div>
      </div>
    ),
    "/account-payment": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <div className="w-full flex flex-col items-center py-10">
          {/* Title Section */}
          <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

          {/* Subtitle Section */}
          <Skeleton {...baseStyle} width={500} height={20} className="mb-8" />

          {/* Content Section */}
          <div className="w-full lg:w-4/5 text-left space-y-6">
            {/* Paragraph Placeholders */}
            <Skeleton {...baseStyle} width="90%" height={20} />
            <Skeleton {...baseStyle} width="80%" height={20} />
            <Skeleton {...baseStyle} width="85%" height={20} />
            <Skeleton {...baseStyle} width="90%" height={20} />
            <Skeleton {...baseStyle} width="80%" height={20} />

            {/* Section with List (Placing) */}
            <div className="mt-8 space-y-4">
              <Skeleton {...baseStyle} width={80} height={20} />
              <Skeleton {...baseStyle} width="70%" height={20} />
              <Skeleton {...baseStyle} width="70%" height={20} />
            </div>

            {/* Cancellation Section */}
            <div className="mt-8 space-y-4">
              <Skeleton {...baseStyle} width={100} height={20} />
              <Skeleton {...baseStyle} width="80%" height={20} />
              <Skeleton {...baseStyle} width="75%" height={20} />
              <Skeleton {...baseStyle} width="85%" height={20} />
            </div>
          </div>
        </div>
      </div>
    ),
    "/privacy-policy": (
      <div>
        <NavbarSkeleton />
        <div className="bg-[#0D1B2A] w-full flex flex-col lg:flex-row justify-center items-center h-full p-10">
          {/* Text Skeleton Effect */}
          <div className="w-full lg:w-1/2 pb-40 h-screen flex flex-col justify-center items-start lg:items-center ">
            <div className="w-full lg:w-1/2 pb-20 h-screen flex flex-col justify-center items-start lg:items-center">
              <Skeleton
                {...baseStyle}
                width={800}
                height={50}
                className="pb-20"
              />
              <Skeleton
                {...baseStyle}
                width={300}
                height={20}
                className="pb-20"
              />
            </div>
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
          </div>
        </div>
      </div>
    ),
    "/terms-use": (
      <div>
        <NavbarSkeleton />
        <div className="bg-[#0D1B2A] w-full flex flex-col lg:flex-row justify-center items-center h-full p-10">
          {/* Text Skeleton Effect */}
          <div className="w-full lg:w-1/2 pb-40 h-screen flex flex-col justify-center items-start lg:items-center ">
            <div className="w-full lg:w-1/2 pb-20 h-screen flex flex-col justify-center items-start lg:items-center">
              <Skeleton
                {...baseStyle}
                width={800}
                height={50}
                className="pb-20"
              />
              <Skeleton
                {...baseStyle}
                width={300}
                height={20}
                className="pb-20"
              />
              <Skeleton
                {...baseStyle}
                width={90}
                height={20}
                className="pb-20"
              />
            </div>
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
            <Skeleton {...baseStyle} width={1000} height={100} className="" />
          </div>
        </div>
      </div>
    ),
    "/blog": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <BlogSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/help-center": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <HelpCenterSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/contact-us": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <ContactUsSkeleton baseStyle={baseStyle} />
      </div>
    ),
    //unAuthenticatedRoutes
    "/dashboard": (
      <div className="flex w-full">
        <SidebarSkeleton />
        <DashboardSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/dashboard/:id": (
      <div className="flex w-full">
        <SidebarSkeleton />
        <DynamicDashboardSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/bulkqrgeneration": <BulkQrGenerationSkeleton baseStyle={baseStyle} />,
    "/my-qr-codes": (
      <div>
        <MyQrCodeSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/stats": <StatsSkeleton baseStyle={baseStyle} />,
    "/template": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <TemplateSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/my-domain": (
      <div>
        <MyDomainSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/dashboard/help-center": (
      <div>
        <HelpCenterSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/plans": (
      <div className="pl-7 pt-6 ">
        <PlanSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/settings": (
      <div className="pl-72 pt-16">
        <SettingSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/bulkqrgeneration/upload-csv": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <BulkqrgenerationUploadSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/qrdetails": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <QrDetailsSkeleton baseStyle={baseStyle} />
      </div>
    ),
    "/plans/checkout": (
      <div className="bg-[#0D1B2A] rounded-lg w-full flex flex-col justify-between  items-center">
        <NavbarSkeleton />
        <PlansCheckoutSkeleton baseStyle={baseStyle} />
      </div>
    ),
  };

  return (
    <>
      <div className="skeleton-container">
        <div className={className}>{variants[isVariant] || variants.default}</div>
      </div>
    </>
  );
};

export default SkeletonLoader;
