import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TemplateSkeleton({ baseStyle }) {
  return (
    <div className="w-full flex flex-col items-center py-10">
      {/* Title */}
      <Skeleton {...baseStyle} width={400} height={40} className="mb-6" />

      {/* QR Code Template Section */}
      <div className="w-[80%] grid grid-cols-3 gap-8">
        {/* Template Card */}
        {Array.from({ length: 3 }).map((_, index) => (
          <div className="flex flex-col items-center space-y-4 p-6 bg-[var(--dark-bg)] rounded-lg">
            {/* QR Code Image */}
            <Skeleton
              {...baseStyle}
              width={150}
              height={150}
              className="rounded-lg mb-4"
            />

            {/* QR Code Name */}
            <Skeleton {...baseStyle} width={200} height={20} className="mb-4" />

            {/* Actions - Edit, Delete, and Download */}
            <div className="flex justify-between w-full">
              <Skeleton
                {...baseStyle}
                width={80}
                height={20}
                className="mb-2"
              />
              <Skeleton
                {...baseStyle}
                width={80}
                height={20}
                className="mb-2"
              />
            </div>

            {/* Download Button */}
            <Skeleton {...baseStyle} width={100} height={30} className="mt-4" />
          </div>
        ))}
      </div>

      {/* Create Templates Button */}
      <Skeleton {...baseStyle} width={150} height={40} className="mt-8" />
    </div>
  );
}

export default TemplateSkeleton;
